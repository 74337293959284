<template>
  <div v-if="isVisible" class="modal open" data-modal="true" id="modal_welcome_message" role="dialog" aria-modal="true"
    tabindex="-1" style="z-index: 90; display: block;">
    <div class="modal-content modal-center max-w-[700px] w-full">

      <div class="modal-header justify-end border-0 pt-5">
        <button class="btn btn-sm btn-icon btn-light" @click="close">
          <i class="ki-filled ki-cross">
          </i>
        </button>
      </div>
      <div class="modal-header">
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-1 text-left">
            <slot name="header"></slot>
          </div>
        </div>
      </div>
      <div class="modal-body flex flex-col items-center pt-0 pb-10">
        <slot name="body">
          <!-- Default body if not provided -->
          <p>This is the body content of the modal.</p>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      this.$emit('update:isVisible', false);
    }
  }
};
</script>
<style>
.modal-content {
  max-width: 1070px !important;
}
</style>
