<template>

	<div class="flex items-center justify-center grow bg-center bg-no-repeat page-bg">
		<div class="card max-w-[370px] w-full mt-10">
			
			<form @submit.prevent="updateLogin" class="card-body flex flex-col gap-5 p-10" id="sign_in_form">
				<div class="text-center mb-2.5">
					<h3 class="text-lg font-semibold text-gray-900 leading-none mb-2.5">
						Update Password
					</h3>
				</div>
				<div class="flex flex-col gap-1">
					<div class="flex items-center justify-between gap-1">
						<label class="form-label text-gray-900">
							New Password
						</label>
					</div>
					<label class="input" data-toggle-password="true">
						
						<input name="user_password" v-model="password" id="password" placeholder="Enter Password" type="password" value="" required/>
						<button class="btn btn-icon" data-toggle-password-trigger="true">
							<i class="ki-filled ki-eye text-gray-500 toggle-password-active:hidden">
							</i>
							<i class="ki-filled ki-eye-slash text-gray-500 hidden toggle-password-active:block">
							</i>
						</button>
					</label>

					<div class="flex items-center justify-between gap-1">
						<label class="form-label text-gray-900">
							Confirm Password
						</label>
					</div>
					<label class="input" data-toggle-password="true">
						<input name="user_cpassword" v-model="copassword" id="user_cpassword" placeholder="Enter Confirm" type="password" value="" required/>
						<button class="btn btn-icon" data-toggle-password-trigger="true">
							<i class="ki-filled ki-eye text-gray-500 toggle-password-active:hidden">
							</i>
							<i class="ki-filled ki-eye-slash text-gray-500 hidden toggle-password-active:block">
							</i>
						</button>
					</label>
				</div>
				<button class="btn btn-primary flex justify-center grow">
					Update Password
				</button>
			</form>
			<p v-if="error">{{ error }}</p>
		</div>
	</div>

</template>
<script>
import api from '@/services/api';
import Loader from '@/components/utils/loader.vue';
import Swal from 'sweetalert2';
import { onUnmounted } from 'vue';
export default {
	components: {
    	Loader
  	},
	data() {
		return {
			password: '',
			copassword:'',
			error: '',
			hash:'',
		};
	},	
	methods: {
		async updateLogin() {
			const gethash = this.$route.params.serial; // 			
			let loader = this.$loading.show();
			const response = await api.updatepassword_forgot({
				password: this.password,
				copassword:this.copassword,
				hash:gethash
			});				
			const data = response.data;

			loader.hide();
			if (data.status == true) { // or check for other success criteria
				Swal.fire({
					title: 'Success!',
					text: 'Passwrod Successfully Updated, Back to Sign In page',
					icon: 'success',
					confirmButtonText: 'OK'
				}).then((result) => {
					if (result.isConfirmed) {
						window.location="/";
					}
				})
			} else {
				Swal.fire({
					title: 'Warning!',
					text: response.data.message,
					icon: 'warning',
					confirmButtonText: 'OK'
				});
			}
			
		},
  	},
};
</script>