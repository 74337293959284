<template>
  <AccessControl :menuId="7" :menuSectionId="25" nameOfPage='Company Info' @accessGranted="handleAccess">
  </AccessControl>
  <MainLayout>
    <template v-slot:default>
      <!-- begin: container -->
      <div class="container-fixed">
        <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
          <div class="flex flex-col justify-center gap-2">
            <h1 class="text-xl font-semibold leading-none text-gray-900">
              Company Info
            </h1>
          </div>
        </div>
        <div class="grid grid-cols-1 xl:grid-cols-2 gap-5 lg:gap-7.5">
          <div class="col-span-1">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                  Information
                </h3>
              </div>
              <div class="card-body pt-4 pb-3">

                <!-- Company Profile Header -->
                <div class="flex items-center space-x-4 mb-8">
                  <div class="w-20 h-20 rounded-full bg-gray-300">
                    <!-- Company Logo Placeholder -->
                    <img id="companyLogoDisplay" alt="Company Logo" class="w-full h-full object-cover rounded-full">
                  </div>
                  <div>
                    <h2 class="text-2xl font-bold text-gray-900">{{ currentItem.company_name }}</h2>
                    <p class="text-gray-600">{{ currentItem.company_label }}</p>
                  </div>
                </div>

                <!-- Company Profile Details in Two Columns -->
                <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <!-- Address -->

                  <div>
                    <h3 class="text-sm font-semibold text-gray-700 mb-1">Address Number</h3>
                    <p class="text-sm text-gray-900">{{ currentItem.company_address_number }}</p>
                  </div>

                  <div>
                    <h3 class="text-sm font-semibold text-gray-700 mb-1">Street 1</h3>
                    <p class="text-sm text-gray-900">{{ currentItem.company_street_1 }}</p>
                  </div>

                  <div>
                    <h3 class="text-sm font-semibold text-gray-700 mb-1">Street 2</h3>
                    <p class="text-sm text-gray-900">{{ currentItem.company_street_2 }}</p>
                  </div>

                  <!-- City -->

                  <div>
                    <h3 class="text-sm font-semibold text-gray-700 mb-1">Country</h3>
                    <p class="text-sm text-gray-900">{{ currentItem.country_name }}</p>
                  </div>


                  <div>
                    <h3 class="text-sm font-semibold text-gray-700 mb-1">Province</h3>
                    <p class="text-sm text-gray-900">{{ currentItem.province_name }}</p>
                  </div>

                  <div>
                    <h3 class="text-sm font-semibold text-gray-700 mb-1">City</h3>
                    <p class="text-sm text-gray-900">{{ currentItem.city_name }}</p>
                  </div>

                  <!-- Mobile -->
                  <div>
                    <h3 class="text-sm font-semibold text-gray-700 mb-1">Phone Number</h3>
                    <p class="text-sm text-gray-900">{{ currentItem.company_phone_number }}</p>
                  </div>

                  <!-- Email -->
                  <div>
                    <h3 class="text-sm font-semibold text-gray-700 mb-1">Email</h3>
                    <p class="text-sm text-gray-900">{{ currentItem.company_email_address }}</p>
                  </div>

                  <!-- Website -->
                  <div>
                    <h3 class="text-sm font-semibold text-gray-700 mb-1">Website</h3>
                    <p class="text-sm text-blue-500"><a href="{{currentItem.company_website}}" target="_blank">{{
                      currentItem.company_website }}</a></p>
                  </div>
                </div>
                <div class="mt-8 text-right">
                  <button v-if="canEdit" @click="openModal"
                    class="btn-success hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    Edit Profile
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>

        <Modal :isVisible="showModal2" @update:isVisible="showModal2 = $event" @click.stop>
          <template v-slot:header>
            <h2>Edit Information</h2>
          </template>

          <template v-slot:body>
            <div style="height:500px;overflow-y:scroll;overflow-x:hidden">
              <form @submit.prevent="updateForm" ref="myform">

                <div class="w-full px-3 mb-6 md:mb-0 text-left">
                  <div class="mb-4">
                    <label class="block text-sm font-medium text-gray-700">Company Logo</label>
                    <div class="flex items-center space-x-4 mt-1">
                      <img alt="Current Logo" id="editLogo" ref="editLogo"
                        class="w-16 h-16 object-cover rounded-full bg-gray-300">
                      <input type="file" id="editLogoInput" ref="editLogoInput"
                        class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                        accept="image/jpeg, image/png, image/webp">
                    </div>
                  </div>

                  <!-- Company Name -->
                  <div class="mb-4">
                    <label for="" class="block text-sm font-medium text-gray-700">Company Name</label>
                    <input type="text" name="company_name" :name="company_name" id="companyName"
                      v-model="currentItem.company_name"
                      class="mt-1 block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                  </div>


                  <div class="mb-4">
                    <label for="" class="block text-sm font-medium text-gray-700">Company Label</label>
                    <input type="text" id="" v-model="currentItem.company_label"
                      class="mt-1 block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                  </div>

                  <div class="mb-4">
                    <label for="" class="block text-sm font-medium text-gray-700">Address Number</label>
                    <input type="text" id="" v-model="currentItem.company_address_number"
                      class="mt-1 block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                  </div>

                  <div class="mb-4">
                    <label for="" class="block text-sm font-medium text-gray-700">Street 1</label>
                    <input type="text" id="" v-model="currentItem.company_street_1"
                      class="mt-1 block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                  </div>

                  <div class="mb-4">
                    <label for="" class="block text-sm font-medium text-gray-700">Street 2</label>
                    <input type="text" id="" v-model="currentItem.company_street_2"
                      class="mt-1 block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                  </div>

                  <div class="mb-4">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                      Country
                    </label>
                    <select @change="handleCountryChange" id="country_id" required v-model="currentItem.country_id"
                      class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option value="">-Select-</option>
                      <option v-for="country in countty_list" :key="country.country_id" :value="country.country_id">
                        {{ country.country_name }}
                      </option>
                    </select>
                  </div>


                  <div class="mb-4">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                      Province
                    </label>
                    <select @change="handleProvinceChange" required v-model="currentItem.province_id"
                      class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option value="" disabled>- Select -</option> <!-- Placeholder option -->
                      <option v-for="province in province_list" :key="province.province_id"
                        :value="province.province_id">
                        {{ province.province_name }}
                      </option>
                    </select>
                  </div>

                  <div class="mb-4">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                      City
                    </label>
                    <select id="position_id" required v-model="currentItem.city_id"
                      class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option value="" disabled>- Select -</option>
                      <option v-for="city in city_list" :key="city.city_id" :value="city.city_id">
                        {{ city.city_name }}
                      </option>
                    </select>
                  </div>
                  <div class="mb-4">
                    <label for="" class="block text-sm font-medium text-gray-700">Company Phone</label>
                    <input type="text" id="" v-model="currentItem.company_phone_number"
                      class="mt-1 block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                  </div>

                  <div class="mb-4">
                    <label for="" class="block text-sm font-medium text-gray-700">Company Email</label>
                    <input type="text" id="" v-model="currentItem.company_email_address"
                      class="mt-1 block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                  </div>

                  <div class="mb-4">
                    <label for="" class="block text-sm font-medium text-gray-700">Company Website</label>
                    <input type="text" id="" v-model="currentItem.company_website"
                      class="mt-1 block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                  </div>
                </div>

                <div class="flex justify-end mt-4">
                  <button type="button" @click="closeModal"
                    class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mr-1">Close</button>
                  <button type="submit"  v-if="canEdit"
                    class="btn-success text-white px-4 py-2 rounded hover:bg-green-600">Update</button>
                </div>
              </form>
            </div>
          </template>
        </Modal>
      </div>
    </template>
  </MainLayout>
</template>
<script>

import MainLayout from '@/components/MainLayout.vue'
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
import apiClient_multi from '@/services/apigen_multipart'; // Import your configured Axios instance  
import { ref, watch, onMounted } from 'vue';
import { VueGoodTable } from 'vue-good-table-next';
import Modal from '@/components/utils/Modal.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css';
import Loader from '@/components/utils/loader.vue';
import Swal from 'sweetalert2';
import AccessControl from '@/components/utils/UserAccess.vue';

export default {
  name: 'ProfilePage',
  components: {
    MainLayout,
    Modal,
    AccessControl
  },
  mounted() {
    this.info();
  },
  methods: {


    async handleCountryChange() {
      this.currentItem.province_id = ""; // Clear selected province
      this.currentItem.city_id = "";
      this.province_list = []; // Clear province list
      this.city_list = []; // Clear province list
      await this.fetchProvinces(); // Fetch new provinces based on the selected country
    },

    async handleProvinceChange() {
      this.currentItem.city_id = ""; // Clear selected province
      this.city_list = []; // Clear province list
      await this.fetchCity(); // Fetch new provinces based on the selected country
    },

    async fetchCity() {
      if (!this.currentItem.province_id) return; // Skip if no country is selected

      let loader = this.$loading.show();
      try {
        const response = await apiClient.get(`/city/all/${this.currentItem.province_id}`);
        if (response.data.status === true) { // or check for other success condition
          this.city_list = response.data.data;
        }
      } catch (error) {
        console.error('Error fetching provinces:', error.response);
      } finally {
        loader.hide();
      }
    },

    async fetchProvinces() {
      if (!this.currentItem.country_id) {
        this.currentItem.province_id = '0'; // Ensure this matches your placeholder value
        return; // Skip if no country is selected
      }

      let loader = this.$loading.show();
      try {
        const response = await apiClient.get(`/province/all/${this.currentItem.country_id}`);
        if (response.data.status === true) { // or check for other success condition
          this.province_list = response.data.data;
          await this.fetchCity();
        }
      } catch (error) {
        console.error('Error fetching provinces:', error.response);
      } finally {
        loader.hide();
      }
    },
    async CountryList() {
      let loader = this.$loading.show();
      try {
        const response = await apiClient.get('/country/list/all');
        if (response.data.status == true) { // or check for other success c
          loader.hide();
          this.countty_list = response.data.data;
          await this.fetchProvinces();
        }
      } catch (error) {
        console.error('Error submitting form:', error.response);
        loader.hide();
      }
    },
    async info() {
      let loader = this.$loading.show();
      try {
        const response = await apiClient.get('/company/info');
        if (response.data.status == true) { // or check for other success c
          loader.hide();
          this.currentItem = response.data.data;
          const apiUrl = process.env.VUE_APP_API_URL;
          const newLogo = apiUrl + "/public/uploads/" + response.data.data.company_logo;
          // Set the company logo or use a default if it's empty
          const logoUrl = response.data.data.company_logo ? newLogo : require('@/assets/companylogo.png');  // Replace with your default logo URL
          document.getElementById('companyLogoDisplay').src = logoUrl;

        }
      } catch (error) {
        console.error('Error submitting form:', error.response);
        loader.hide();
      }
    },
    openModal() {

      this.showModal2 = true; // Show the modal
      this.$nextTick(() => {

        const apiUrl = process.env.VUE_APP_API_URL;
        const newLogo = apiUrl + "/public/uploads/" + this.currentItem.company_logo;
        const logoUrl = this.currentItem.company_logo ? newLogo : require('@/assets/companylogo.png');  // Replace with your default logo URL


        const logoPreview = this.$refs.editLogo;
        const editLogoInput = this.$refs.editLogoInput;
        document.getElementById('editLogo').src = logoUrl;

        if (editLogoInput) {
          editLogoInput.addEventListener('change', (e) => {
            const file = e.target.files[0];
            if (file) {
              const reader = new FileReader();
              reader.onload = (event) => {
                if (logoPreview) {
                  logoPreview.src = event.target.result;
                }
              };
              reader.readAsDataURL(file);
            }
          });
        }
        document.body.style.overflow = ''; // Allow page scroll
        this.CountryList();
      })

    },
    closeModal() {
      this.showModal2 = false;

    },
    async updateForm() {
      let loader = this.$loading.show();
      const response = await apiClient.put('/company', this.currentItem);

      if (response.data.status == true) { // or check for other success c

        const fileInput = this.$refs.editLogoInput;

        if (!fileInput || fileInput.files.length === 0) {
          loader.hide();
          Swal.fire({
            title: 'Success!',
            text: 'Profile Successfully Updated',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          })
        } else {

          const file = fileInput.files[0]; // Get the first file selected
          const formData = new FormData();
          formData.append('logo', file);
          formData.append('company_id', this.currentItem.company_id);
          const response2 = await apiClient_multi.post('/company/upload_image', formData);
          loader.hide();
          if (response2.data.status == true) { // or check for other success 

            Swal.fire({
              title: 'Success!',
              text: 'Profile Successfully Updated',
              icon: 'success',
              confirmButtonText: 'OK'
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            })

          } else {

            Swal.fire({
              title: 'Warning!',
              text: response.data.message,
              icon: 'warning',
              confirmButtonText: 'OK'
            });
          }
        }
      } else {
        loader.hide();
        Swal.fire({
          title: 'Warning!',
          text: response.data.message,
          icon: 'warning',
          confirmButtonText: 'OK'
        });
      }

    },
    handleAccess({ canEdit, canDelete, canWrite }) {
      this.canEdit = canEdit;
      this.canDelete = canDelete;
      this.canWrite = canWrite;
    },

  },
  data() {
    return {
      showModal: false,
      form: {},
      showModal2: false,
      currentItem: [
        "company_id",
        "company_name",
        "company_address_number",
        "company_street_1",
        "company_street_2",
        "company_city_id",
        "company_province_id",
        "company_country_id",
        "company_phone_number",
        "company_email_address",
        "company_website",
        "company_logo",
        "country_name",
        "country_id",
        "province_id",
        "city_name",
        "city_id"
      ],
      user_info: [],
      countty_list: [],
      province_list: [],
      city_list: [],
      canWrite: 0,
      canEdit: 0,
      canDelete: 0,

    };
  },
}
</script>
<style>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Modal overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modal-body {
  flex: 1;
  overflow-y: auto;
}

.modal-footer {
  padding: 16px;
  text-align: right;
}
</style>