<template>
    <div v-if="isVisible" class="modal-overlay">
      <div class="modal-content">
        <h2>Edit Item</h2>
        <label for="retail" class="block text-gray-700 text-left text-sm font-bold mb-2">Retail Price:</label>
        <input type="number" min="0" v-model="localItem.retail" class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
  
        <label for="quantity" class="block text-gray-700 text-left text-sm font-bold mb-2">Quantity:</label>
        <input type="number" min="1" v-model="localItem.quantity" class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
  
        <div class="modal-buttons">
          <button @click="save" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-1">Save</button>
          <button @click="cancel"   class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">Cancel</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isVisible: {
        type: Boolean,
        required: true,
      },
      item: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        localItem: { ...this.item }, // Create a local copy of the item for editing
      };
    },
    methods: {
      save() {
        this.$emit('save', this.localItem); // Emit the edited item back to the parent
      },
      cancel() {
        this.$emit('cancel'); // Emit cancel event
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
  }
  .modal-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  </style>