<template>
    <AccessControl :menuId="3" :menuSectionId="8" nameOfPage='Repackage' @accessGranted="handleAccess"></AccessControl>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            Repackage Product Add
                        </h1>
                    </div>
                    <div class="flex items-center gap-2.5">
                        <a class="btn btn-sm btn-success" @click="backToList">
                            Back Repackage Product List
                        </a>
                    </div>
                </div>
                <Notification ref="notificationRef" />
                <div class="card">
                    <div class="card-header   card-header-bg flex-wrap gap-2">
                        <div class="flex flex-wrap gap-2 lg:gap-5"></div>
                    </div>
                    <div class="card-body">

                        <form @submit.prevent="submitForm" style="width: 100%;">
                            <div class="grid grid-cols-2 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Product Code
                                        </label>
                                        <input required v-model="form.product_code"
                                            class="shadow appearance-none border border-gray-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                        <!--<SearchableSelect v-model="selectedOption" :options="options"
                                                        :labelKey="'product_info'" placeholder="Search and select"
                                                        @option-selected="handleOption" />-->
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Product Name
                                        </label>
                                        <input v-model="form.product_name"
                                            class="shadow appearance-none border border-gray-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-2 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Short Description
                                        </label>
                                        <textarea v-model="form.product_short_description"
                                            class="shadow appearance-none border border-gray-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder=""></textarea>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Full Description
                                        </label>
                                        <textarea v-model="form.product_full_description"
                                            class="shadow appearance-none border border-gray-500   rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder=""></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-4 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Classification
                                        </label>
                                        <select id="classification_id" ref="classification_id"
                                            v-model="form.classification_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>-Select-</option>
                                            <option v-for="classification in classification_list"
                                                :key="classification.classification_id"
                                                :value="classification.classification_id">
                                                {{ classification.classification_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Category
                                        </label>
                                        <select @change="handle_change_category" v-model="form.category_id"
                                            ref="category_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <!-- Placeholder option -->
                                            <option v-for="category in category_list" :key="category.category_id"
                                                :value="category.category_id">
                                                {{ category.category_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Sub Category
                                        </label>
                                        <select ref="sub_category_id" v-model="form.sub_category_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <option v-for="sub_category in sub_category_list"
                                                :key="sub_category.sub_category_id"
                                                :value="sub_category.sub_category_id">
                                                {{ sub_category.sub_category_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Ware House
                                        </label>
                                        <select ref="ware_house_id" v-model="form.warehouse_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <option v-for="warehouse in ware_house_list" :key="warehouse.warehouse_id"
                                                :value="warehouse.warehouse_id">
                                                {{ warehouse.warehouse_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-4 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Rack
                                        </label>
                                        <select ref="rack_id" v-model="form.rack_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <option v-for="rack in rack_list" :key="rack.rack_id" :value="rack.rack_id">
                                                {{ rack.rack_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Season
                                        </label>
                                        <select ref="season_id" v-model="form.season_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <option v-for="season in season_list" :key="season.season_id"
                                                :value="season.season_id">
                                                {{ season.season_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Color
                                        </label>
                                        <select ref="color_id" v-model="form.color_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <option v-for="color in color_list" :key="color.color_id"
                                                :value="color.color_id">
                                                {{ color.color_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Unit of Measure
                                        </label>
                                        <select ref="uom_id" v-model="form.uom_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <option v-for="uom in uom_list" :key="uom.uom_id" :value="uom.uom_id">
                                                {{ uom.uom_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-2 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            High Treshold
                                        </label>
                                        <input v-model="form.high_qty" min="0" value="0"
                                            class="shadow appearance-none border border-gray-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="number" placeholder="">
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Low Treashold
                                        </label>
                                        <input v-model="form.low_qty" min="0" value="0"
                                            class="shadow appearance-none border border-gray-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="number" placeholder="">
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Cost / Original Price
                                        </label>
                                        <input v-model="form.original_price"
                                            class="shadow appearance-none border border-gray-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Retail / Selling Price
                                        </label>
                                        <input v-model="form.selling_price"
                                            class="shadow appearance-none border border-gray-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>

                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Customer Discount Price
                                        </label>
                                        <input v-model="form.discount_price"
                                            class="shadow appearance-none border border-gray-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                            </div>
                            <div class="flex justify-end mt-4">
                                <button v-if="canWrite" type="submit"
                                    class="btn-success text-white px-4 py-2 rounded hover:bg-green-600">Save</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            <!-- end: container -->
        </template>
    </MainLayout>
</template>
<script>
import MainLayout from '@/components/MainLayout.vue'
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
import apiClient_multi from '@/services/apigen_multipart'; // Import your configured Axios instance  
import { ref, watch, onMounted } from 'vue';
import { VueGoodTable } from 'vue-good-table-next';
import Modal from '@/components/utils/Modal2.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css';
import Swal from 'sweetalert2';
import Notification from '@/components/utils/Notification.vue';
import AccessControl from '@/components/utils/UserAccess.vue';


export default {
    name: 'SupplierListPage',
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
        AccessControl
    }, async mounted() {
        this.first_load = 1;
        await this.ClassificationList();
        await this.CategoryList();
        await this.WarehouseList();
        await this.RackList();
        await this.SeasonList();
        await this.ColorList();
        await this.UOMList();
        this.first_load = 0;
    },
    computed: {
        formattedValue: {
            get() {
                // Format the number to 2 decimal places
                return this.amount.toFixed(2);
            },
            set(value) {
                // Remove non-numeric characters except for the decimal point
                const sanitizedValue = value.replace(/[^0-9.]/g, '');

                // Ensure the input is properly parsed as a float, or default to 0
                this.amount = parseFloat(sanitizedValue) || 0;
            },
        },
    },

    methods: {

        backToList() {
            window.location = `/product/repackage`;
        },


        previewImage(image) {
            this.currentImage = image;
            this.showPreview = true;
            this.showModal3 = false;
        },
        // Close the modal
        closePreview() {
            this.showPreview = false;
            this.currentImage = {};
            this.showModal3 = true;
        },
        showProduct(data) {
            window.location = '/supplier/get_products/' + data.supplier_id;
        },

        async handle_change_category() {
            await this.fetch_subcategory(); // 
        },

        async info_subcategory(cat_id) {

            let id = cat_id;
            try {
                const response = await apiClient.get(`/category/${id}/sub/all`);
                if (response.data.status === true) { // or check for other success condition
                    this.sub_category_list = response.data.data;
                    this.form.sub_category_id = 0;
                }
            } catch (error) {
                console.error('Error fetching subcategories:', error.response);
            } finally {

            }
        },
        async fetch_subcategory() {
            this.$nextTick(() => {

                let id = this.$refs.category_id.value;
                if (id == "") {
                    return false;
                }
                this.info_subcategory(id);


            });
        },
        async ClassificationList() {

            try {
                const response = await apiClient.get('/classification/list/all');
                if (response.data.status == true) {

                    this.classification_list = response.data.data;
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        },

        async CategoryList() {

            if (this.first_load == 0) { let loader = this.$loading.show(); }
            try {
                const response = await apiClient.get('/category/list/all');
                if (response.data.status == true) {
                    if (this.first_load == 0) { loader.hide(); }
                    this.category_list = response.data.data;

                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
                if (this.first_load == 0) { loader.hide(); }
            }
        },

        async WarehouseList() {
            if (this.first_load == 0) { let loader = this.$loading.show(); }
            try {
                const response = await apiClient.get('/warehouse/list/all');
                if (response.data.status == true) {
                    if (this.first_load == 0) { loader.hide(); }
                    this.ware_house_list = response.data.data;
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
                if (this.first_load == 0) { loader.hide(); }
            }
        },

        async RackList() {
            if (this.first_load == 0) { let loader = this.$loading.show(); }
            try {
                const response = await apiClient.get('/rack/list/all');
                if (response.data.status == true) {
                    if (this.first_load == 0) { loader.hide(); }
                    this.rack_list = response.data.data;
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
                if (this.first_load == 0) { loader.hide(); }
            }
        },

        async SeasonList() {
            if (this.first_load == 0) { let loader = this.$loading.show(); }
            try {
                const response = await apiClient.get('/season/list/all');
                if (response.data.status == true) {
                    if (this.first_load == 0) { loader.hide(); }
                    this.season_list = response.data.data;
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
                if (this.first_load == 0) { loader.hide(); }
            }
        },

        async ColorList() {
            if (this.first_load == 0) { let loader = this.$loading.show(); }
            try {
                const response = await apiClient.get('/color/list/all');
                if (response.data.status == true) {
                    if (this.first_load == 0) { loader.hide(); }
                    this.color_list = response.data.data;
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
                if (this.first_load == 0) { loader.hide(); }
            }
        },

        async UOMList() {


            try {
                const response = await apiClient.get('/uom/list/all');
                if (response.data.status == true) {

                    this.uom_list = response.data.data;
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        },

        async submitForm() {
            try {
                const response = await apiClient.post('repackage_product/save', this.form);
                if (response.data.status == true) { // or check for other success c
                    this.showModal = false;
                    this.$refs.notificationRef.show('Successfully Saved', 'success', '/product/repackage');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        },
        handleAccess({ canEdit, canDelete, canWrite }) {
            this.canEdit = canEdit;
            this.canDelete = canDelete;
            this.canWrite = canWrite;
        },

    },
    data() {
        return {
            showModal: false,
            showModal3: false,
            form: {
                category_id: 0,
                classification_id: 0,
                sub_category_id: 0,
                warehouse_id: 0,
                rack_id: 0,
                season_id: 0,
                color_id: 0,
                uom_id: 0,
                high_qty: 0,
                low_qty: 0,
                original_price: 0,
                selling_price: 0,
                discount_price: 0
            },
            showModal2: false,
            currentItem: [],
            classification_list: [],
            category_list: [],
            sub_category_list: [],
            ware_house_list: [],
            rack_list: [],
            season_list: [],
            color_list: [],
            uom_list: [],
            edit_first: 0,
            product_images: [],
            image_directory: "",
            showPreview: false,
            canWrite: 0,
            canEdit: 0,
            canDelete: 0,
        };
    },
}
</script>


<style scoped>
.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* Flexible columns */
    gap: 10px;
    /* Spacing between tiles */
    overflow-x: auto;
    /* Allow horizontal scroll if needed */
}

.tile {
    position: relative;
    width: 100%;
    padding-top: 75%;
    /* Aspect ratio of the tile */
    overflow: hidden;
    background-color: #f0f0f0;
    /* Background color for tiles */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* Align the button at the bottom */
    border: 2px solid #333;
    /* Add border: width, style, and color */
    border-radius: 8px;
    /* Optional: Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    /* Optional: Add a shadow for a raised effect */
}

.tile-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Cover the tile area */
}

.remove-button {
    position: relative;
    background-color: #ff4d4d;
    /* Red button */
    color: white;
    border: none;
    padding: 8px;
    margin: 10px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
    text-align: center;
    opacity: 0.8;
    transition: opacity 0.3s;
}

.remove-button:hover {
    opacity: 1;
    /* Make button fully visible on hover */
}
</style>