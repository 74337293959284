<template>
    <AccessControl :menuId="5" :menuSectionId="13" nameOfPage='Item Adjustment List' @accessGranted="handleAccess">
    </AccessControl>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            Item Adjustment Add
                        </h1>
                    </div>
                    <div class="flex items-center gap-2.5">
                        <a class="btn btn-sm btn-success" @click="backToList"> Back Item Adjusment List </a>
                    </div>
                </div>
                <Notification ref="notificationRef" />
                <div class="card">
                    <div class="card-header card-header-bg flex-wrap gap-2"></div>
                    <div class="card-body">
                        <form @submit.prevent="submitForm" style="width: 100%" ref="form">


                            <div class="grid grid-cols-4 gap-4">

                                <div class="col-span-1">
                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                        Product Code / Name
                                    </label>
                                    <SearchableSelect v-model="selectedOption" :options="options"
                                        :labelKey="'product_info'" placeholder="Search and select" ref="select_product"
                                        @option-selected="handleOption" @enter-selected="trigger_add" />
                                </div>

                                <div class="col-span-1">
                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                        Quantity
                                    </label>
                                    <div class="block text-gray-700 text-left text-sm font-bold mb-2">
                                        <input ref="pquantity"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="number" placeholder="" min="1" value="1" />
                                    </div>
                                </div>


                            </div>

                            <div class="grid grid-cols-4 gap-4">

                                <div class="col-span-1">
                                    <div class="col-span-1">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Notes
                                        </label>
                                        <div class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            <textarea v-model="form.notes" ref="notes"
                                                class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                type="text" placeholder="">
                                                    </textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-span-1">
                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                        Adjustment
                                    </label>
                                    <select id="simpleSelect" v-model="correct_me" ref="adjustment"
                                        class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                        <option disabled value="">Select</option>
                                        <option v-for="option in correction" :key="option2" :value="option">
                                            {{ option.item_adjustment_option }}
                                        </option>
                                    </select>
                                </div>


                                <div class="col-span-1">
                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                        &nbsp; </label>
                                    <div class="block text-gray-700 text-left text-sm font-bold mb-2">
                                        <button type="button" @click="addRow" ref="addRow"
                                            class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mr-1">
                                            Add Product Item
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-5 gap-4">
                                <div class="col-span-5">
                                    <table style="font-size:13px"
                                        class="min-w-full border-collapse border border-gray-300">
                                        <thead>
                                            <tr class="bg-gray-100">
                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                    Code
                                                </th>
                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                    Name
                                                </th>
                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                    Notes
                                                </th>
                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                    Quantity
                                                </th>

                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                    Adjustment
                                                </th>

                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in items" :key="index"
                                                class="odd:bg-white even:bg-gray-50">
                                                <td class="border border-gray-300 px-4 py-2">
                                                    {{ item.code }}
                                                </td>
                                                <td class="border border-gray-300 px-4 py-2">
                                                    {{ item.name }}
                                                </td>

                                                <td class="border border-gray-300 px-4 py-2">
                                                    {{ item.notes }}
                                                </td>
                                                <td class="border border-gray-300 px-4 py-2">
                                                    {{ item.quantity }}
                                                </td>

                                                <td class="border border-gray-300 px-4 py-2">
                                                    {{ item.adjustment }}
                                                </td>

                                                <td class="border border-gray-300 px-4 py-2">

                                                    <i @click="openModal(item)"
                                                        class="text-blue-500 px-2 py-1 rounded hover:bg-black-600  ki-filled ki-notepad-edit fs-2tx cursor-pointer">
                                                    </i>

                                                    <i @click="removeRow(index)"
                                                        class="text-red-500 px-2 py-1 rounded hover:bg-black-600 ki-filled ki-trash fs-2tx">
                                                    </i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="flex justify-end mt-4">
                                <button v-if="canWrite" type="submit"
                                    class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-1">
                                    Save
                                </button>
                                <button v-if="canWrite" type="button" @click="handlePosted()"
                                    class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
                                    Posted
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- end: container -->

            <EditModal v-if="isModalVisible" :isVisible="isModalVisible" :item="currentItem" @save="updateItem"
                @cancel="closeModal" />
        </template>


    </MainLayout>
</template>
<script>
import MainLayout from "@/components/MainLayout.vue";
import apiClient from "@/services/apigen"; // Import your configured Axios instance
import { ref, watch, onMounted } from "vue";
import { VueGoodTable } from "vue-good-table-next";
import Modal from "@/components/utils/Modal2.vue";
import "vue-good-table-next/dist/vue-good-table-next.css";
import Loader from "@/components/utils/loader.vue";
import Swal from "sweetalert2";
import Notification from "@/components/utils/Notification.vue";
import SearchableSelect from "@/components/utils/Search.vue";
import AccessControl from '@/components/utils/UserAccess.vue';
import EditModal from '@/components/utils/ItemsAdjustmentModal.vue'; // Adjust the path as necessary

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

export default {
    name: "supplierListPage",
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
        SearchableSelect,
        AccessControl,
        EditModal
    },
    mounted() {
        this.fetchSuggestions2();
        this.form.quantity = 1;
        this.fecthItemOption();

    },
    created() {
        // Initialize the debounced function
        this.fetchSuggestionsDebounced = debounce(this.fetchSuggestions, 1000);
        this.fetchSuggestionsDebounced2 = debounce(this.fetchSuggestions2, 1000);
        this.fetchSuggestionsDebounced3 = debounce(this.fetchSuggestions3, 1000);
    },
    methods: {


        openModal(item) {
            this.currentItem = item; // Set the current item to edit
            this.isModalVisible = true; // Show the modal
        },
        closeModal() {
            this.isModalVisible = false; // Hide the modal
            this.currentItem = null; // Reset the current item
        },
        updateItem(updatedItem) {     
            console.log(updatedItem);  

           const index = this.items.findIndex(item => item.code === updatedItem.code);
            if (index !== -1) {
                this.items.splice(index, 1, updatedItem); // Replace the item
            }

            this.closeModal(); // Close the modal
        },

        trigger_add(option) {
            setTimeout(() => {
                this.$refs.addRow.click();
            }, 500);
        },

        addRow() {

            if (Object.keys(this.selectedOption).length == 0) {
                this.$refs.notificationRef.show("Product Not found", 'error', '0');
                return false;
            }

            if (parseInt(Object.keys(this.correct_me).length) == 0) {
                this.$refs.notificationRef.show("Please Select Adjustment", 'error', '0');
                return false;
            }

            if (parseInt(Object.keys(this.items).length) != 0) {
                const index = this.items.findIndex(item => item.product_id === this.selectedOption.product_id);
                if (index !== -1) {
                    // Get the quantity from the reference and parse it
                    let quantityToAdd = parseInt(this.$refs.pquantity.value) || 0; // default to 0 if not a valid number                      
                    let q = parseInt(this.items[index].quantity) + parseInt(quantityToAdd);
                    this.items[index].quantity = q;
                    this.items[index].notes = this.$refs.notes.value;
                    this.$refs.notificationRef.show("Product Added", 'success', '0');
                    return false;
                }
            }

            let code = this.selectedOption.product_code;
            let name = this.selectedOption.product_name;
            let cost = this.selectedOption.selling_price;
            let quantity = this.$refs.pquantity.value;
            let notes = this.$refs.notes.value;

            let adjustment = this.correct_me.item_adjustment_option;
            let adjustment_id = this.correct_me.item_adjustment_option_id;

            let product_id = this.selectedOption.product_id;
            this.items.push({ product_id: product_id, code: code, name: name, notes: notes, quantity: quantity, adjustment: adjustment, adjustment_id: adjustment_id });
            this.$refs.select_product.clearInput();
        },
        removeRow(index) {
            // Remove the row at the specified index
            this.items.splice(index, 1);
        },



        handleOption(option) {
            this.selectedOption = option; // Update selected option from event*/
        },
        handleKeyup2() {
            this.fetchSuggestionsDebounced2(); // Call the debounced function
        },
        handleKeyup() {
            this.fetchSuggestionsDebounced(); // Call the debounced function
        },

        handleKeyup3() {
            this.fetchSuggestionsDebounced3(); // Call the debounced function
        },
        async fetchSuggestions3() {
            if (this.searchQuery3.length > 2) {
                this.indication_purchase = "(Searching...)";
                try {
                    const response = await apiClient.post("/purchase_order_product/get/list", {
                        data: { search: this.searchQuery3 },
                    });

                    if (response.data.status == true) {
                        this.suggestions3 = response.data.data;
                        if (response.data.data.length == 0) {
                            this.indication_purchase = "(Purchase Order not Found...)";
                            this.form.supplier_id = 0;
                        } else {
                            this.indication_purchase = "";
                        }
                    }
                } catch (error) {
                    console.error("Error submitting form:", error.response);
                }
            } else {
                this.suggestions = []; // Clear suggestions if input is less than 3 characters
                this.clear_products_bottom();

            }
        },

        async fecthItemOption() {
            try {
                const response = await apiClient.get("/item_adjustment_option/list");

                if (response.data.status == true) {
                    this.correction = response.data.data
                }
            } catch (error) {
                console.error("Error submitting form:", error.response);
            }
        },

        handlePosted() {

            const form = this.$refs.form;
            if (form.checkValidity()) {


                // Show SweetAlert confirmation dialog
                Swal.fire({
                    title: 'Are you sure do you want to Post it?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Post it!',
                    cancelButtonText: 'No, cancel!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Proceed with form submission after confirmation
                        this.form.is_posted = 1;
                        this.submitForm();  // Call your form submission logic here
                    } else {
                        // If the user cancels, stop everything
                        return false;
                    }
                });

            } else {
                // If the form is invalid, trigger the native validation popups
                form.reportValidity();
                return false;  // Prevent submission if form is invalid
            }

        },
        async fetchSuggestions() {

        },
        async fetchSuggestions2() {

            try {
                const response = await apiClient.post(`/product/name_code/keyword2`, {
                    data: { keyword: this.searchQuery2 },
                });

                if (response.data.status == true) {
                    // this.suggestions2 = response.data.data;
                    this.options = response.data.data;
                }
            } catch (error) {
                console.error("Error submitting form:", error.response);
            }

        },


        selectSuggestion(suggestion) {
            this.searchQuery = suggestion.supplier_name; // Populate the input with the selected suggestion
            this.productSearch = suggestion;
            this.form.supplier_id = suggestion.supplier_id;
            this.suggestions = []; // Clear suggestions list
        },

        selectSuggestion2(suggestion) {
            this.searchQuery2 = suggestion.product_name; // Populate the input with the selected suggestion
            this.productSearch2 = suggestion;
            //this.form.supplier_id = suggestion.supplier_id;
            this.suggestions2 = []; // Clear suggestions list
        },

        selectSuggestion3(suggestion) {
            this.searchQuery3 = suggestion.purchase_order_number; // Populate the input with the selected suggestion
            this.productSearch3 = suggestion;
            this.form.purchase_order_id = suggestion.purchase_order_id;
            this.suggestions3 = []; // Clear suggestions list
        },

        backToList() {
            window.location = `/inventory/item_adjustment_list`;
        },
        async getpayment() {
            try {
                const response = await apiClient.get("/payment/get/all");
                if (response.data.status == true) {
                    //
                    this.payment_list = response.data.data;
                }
            } catch (error) {
                console.error("Error submitting form:", error.response);
            }
        },
        async submitForm() {


            let data = {};
            data.items = this.items;
            this.form = { ...this.form, ...data };


            /*if (this.selectedOption.product_id == undefined) {
                this.$refs.notificationRef.show("Please Select Product", 'error', '0');
                return false;
            }

            let correction = this.$refs.correction.value;

            if (correction == "") {
                this.$refs.notificationRef.show("Please indicate adjustment", 'error', '0');
                return false;
            }

            this.form.product_id = this.selectedOption.product_id;
            this.form.correction = correction;*/

            if (parseInt(Object.keys(this.items).length) == 0) {
                this.$refs.notificationRef.show("Please Select Items", 'error', '0');
                return false
            }

            try {
                const response = await apiClient.post(`/item_adjustment/save`, this.form);
                if (response.data.status == true) {
                    this.$refs.notificationRef.show('Successfully Saved', 'success', '/inventory/item_adjustment_list');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        }, handleAccess({ canEdit, canDelete, canWrite }) {
            this.canEdit = canEdit;
            this.canDelete = canDelete;
            this.canWrite = canWrite;
        },

    },
    data() {
        return {
            form: {},
            currentItem: [],
            suggestions: [],
            searchQuery: "",
            productSearch: [],
            isLoading: false, // Loading state
            indication_supplier: "",
            payment_list: [],
            suggestions2: [],
            searchQuery2: "",
            indication_code: "",
            items: [],
            productSearch2: {},
            suggestions3: [],
            indication_purchase: "",
            searchQuery3: "",

            searchTerm: "",
            selectedOption: [], // Stores the selected option
            dropdownOpen: false, // Controls whether the dropdown is open

            options: [
                /*{ id: 1, product_name: "Option 1" },
                { id: 2, product_name: "Option 2" },
                { id: 3, product_name: "Option 3" },
                { id: 4, product_name: "Option 4" }*/
            ],
            // selectedOption: '',
            correction: [],
            correct_me: "",
            canWrite: 0,
            canEdit: 0,
            canDelete: 0,
            option2:[],
            isModalVisible: false


        };
    },
};
</script>

<style>
.search-select {
    position: relative;
}

.options-list {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
}

.option-item {
    padding: 10px;
    cursor: pointer;
}

.option-item:hover {
    background-color: #f0f0f0;
}

.selected-option {
    margin-top: 10px;
}
</style>
