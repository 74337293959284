import LoginPage from '@/components/Login.vue';
import ForgotPasswordPage from '@/components/ForgotPassword.vue';
import UpdatePasword from './components/UpdatePassword.vue';
import DashboardPage from '@/components/Dashboard.vue';
import ProfilePage from '@/components/account/Profile.vue';
//accounts
import deparmentPage from '@/components/account/Department.vue';
import listPage from '@/components/account/List.vue';
import positionPage from '@/components/account/Position.vue';
import rightPage from '@/components/account/Right.vue';
import rightDetailPage from '@/components/account/RightDetail.vue';

import store from '@/store'; // Make sure to import your Vuex store
import { createRouter,createWebHistory } from 'vue-router';
import Menu from './components/settings/Menu.vue';
import Category from './components/settings/Category.vue';
import Sesson from './components/settings/Season.vue';
import Uom from './components/settings/Uom.vue';
import payment from './components/settings/Payment.vue';
import Rack from './components/settings/Rack.vue';
import Warehouse from './components/settings/Warehouse.vue';
import Color from './components/settings/Color.vue';
import Classification from './components/settings/Classification.vue';
import SubCategory from './components/settings/Subcategory.vue';
import Country from './components/settings/Country.vue';
import Province from './components/settings/Province.vue';
import City from './components/settings/City.vue';
import Company from './components/settings/Company.vue';

import Supplier_list from './components/supplier/Supplier_list.vue';
import Supplier_products from './components/supplier/Supplier_products.vue';
import Supplier_Add from './components/supplier/Supplier_add.vue';
import Supplier_Edit from './components/supplier/Supplier_edit.vue';

import Product_list  from './components/product/Product_list.vue';
import Product_add from './components/product/Product_add.vue';
import Product_edit from './components/product/Product_edit.vue';

import Repackage_list from './components/product/Repackage_list.vue';
import Repackage_add from './components/product/Repackage_add.vue';
import Repackage_edit from './components/product/Repackage_edit.vue';
import Repackage_product from './components/product/Repackage_products.vue';

import Purchase_list from './components/purchase/Purchase_list.vue';
import Purchase_add  from './components/purchase/Purchase_add.vue';
import Purchase_edit  from './components/purchase/Purchase_edit.vue';

import Customer_list from './components/customer/Customer_list.vue';
import Customer_add from './components/customer/Customer_add.vue';
import Customer_edit from './components/customer/Customer_edit.vue';

import ItemRecieve_List  from './components/Inventory/ItemReceive_list.vue';
import ItemReceive_add from './components/Inventory/ItemReceive_add.vue';
import ItemReceive_edit from './components/Inventory/ItemReceive_edit.vue';
import ItemReceive_info from './components/Inventory/ItemReceive_info.vue';

import InventoryCount_List  from './components/Inventory/InventoryCountList.vue';
import InventoryCount_Add from './components/Inventory/InventoryCountAdd.vue';
import InventoryCount_edit from './components/Inventory/InventoryCountEdit.vue';
import InventoryCount_info from './components/Inventory/InventoryCountInfo.vue';

import Order_list from './components/customer/Order_list.vue';
import Order_add from './components/customer/Order_add.vue';
import Order_edit from './components/customer/Order_edit.vue';
import Order_info from './components/customer/Order_info.vue';

import ItemAdjustment_list  from './components/Inventory/ItemAdjustment_list.vue';
import ItemAdjustment_Add from './components/Inventory/ItemAdjustment_add.vue';
import ItemAdjustment_edit from './components/Inventory/ItemAdjustment_edit.vue';
import ItemAdjustment_info from './components/Inventory/ItemAdjustment_info.vue';

import ItemLedger_list from './components/Inventory/ItemLedger_list.vue';

const newLocal = 'rack';
const routes=[

  {   path:'/inventory/item_ledger_list',component:ItemLedger_list, name:'ItemLedger_list',meta: { requiresAuth: true }},
  
  {   path:'/inventory/item_adjustment_list',component:ItemAdjustment_list, name:'item_adjustment_list',meta: { requiresAuth: true }},
  {   path:'/inventory/item_adjustment_add',component:ItemAdjustment_Add, name:'item_adjustment_add',meta: { requiresAuth: true }},
  {   path:'/inventory/item_adjustment/:id',component:ItemAdjustment_edit, name:'item_adjustment_',meta: { requiresAuth: true }},
  {   path:'/inventory/itemAdjustment_info/:id',component:ItemAdjustment_info, name:'ItemAdjustment_info',meta: { requiresAuth: true }},

  {   path:'/inventory/item_received_list',component:ItemRecieve_List, name:'item_received_list',meta: { requiresAuth: true }},
  {   path:'/inventory/item_received_add',component:ItemReceive_add, name:'item_received_add',meta: { requiresAuth: true }},
  {   path:'/inventory/item_received/:id',component:ItemReceive_edit, name:'item_received',meta: { requiresAuth: true }},
  {   path:'/inventory/item_info/:id',component:ItemReceive_info, name:'item_info',meta: { requiresAuth: true }}, 


  {   path:'/inventory/inventory_count_list',component:InventoryCount_List, name:'InventoryCount_List',meta: { requiresAuth: true }},
  {   path:'/inventory/inventory_count_add',component:InventoryCount_Add, name:'InventoryCount_Add',meta: { requiresAuth: true }},
  {   path:'/inventory/inventory_count/:id',component:InventoryCount_edit, name:'InventoryCount_edit',meta: { requiresAuth: true }},
  {   path:'/inventory/inventory_count_info/:id',component:InventoryCount_info, name:'InventoryCount_info',meta: { requiresAuth: true }},  

  {   path:'/customer/list',component:Customer_list, name:'Customer_list',meta: { requiresAuth: true }},
  {   path:'/customer/add',component:Customer_add, name:'Customer_add',meta: { requiresAuth: true }},
  {   path:'/customer/edit/:id',component:Customer_edit, name:'Customer_edit',meta: { requiresAuth: true }},

  {   path:'/customer/order_list',component:Order_list, name:'Order_list',meta: { requiresAuth: true }},
  {   path:'/customer/order_add',component:Order_add, name:'Order_add',meta: { requiresAuth: true }},
  {   path:'/customer/order_edit/:id',component:Order_edit, name:'Order_edit',meta: { requiresAuth: true }},
  {   path:'/customer/order_info/:id',component:Order_info, name:'Order_info',meta: { requiresAuth: true }},
  

  {   path:'/',component:LoginPage, name:'Login'},
  {   path:'/forgot_password',component:ForgotPasswordPage, name:'ForgotPassword'},
  {   path:'/password_reset/:serial',component:UpdatePasword, name:'UpdatePasword'},
  // {   path:'/login',component:LoginPage, name:'Login'},
  {   path:'/dashboard',component:DashboardPage, name:'Dashboard',meta: { requiresAuth: true }},
  {   path:'/profile',component:ProfilePage, name:'Profile',meta: { requiresAuth: true }},
  // accounts
  {   path:'/account/department',component:deparmentPage, name:'Department',meta: { requiresAuth: true }},
  {   path:'/account/list',component:listPage, name:'List',meta: { requiresAuth: true }},
  {   path:'/account/position',component:positionPage, name:'Position',meta: { requiresAuth: true }},
  {   path:'/account/right',component:rightPage, name:'Right',meta: { requiresAuth: true }},
  {   path:'/account/profile',component:ProfilePage, name:'Profile',meta: { requiresAuth: true }},
  {   path:'/account/right_detail/:id',component:rightDetailPage, name:'RightDetail',meta: { requiresAuth: true }},


 

  {   path:'/supplier/list',component:Supplier_list, name:'SupplierListPage',meta: { requiresAuth: true }},
  {   path:'/supplier/get_products/:id',component:Supplier_products, name:'SupplieProductsPage',meta: { requiresAuth: true }},
  {   path:'/supplier/add',component:Supplier_Add, name:'SupplierAdd',meta: { requiresAuth: true }},
  {   path:'/supplier/edit/:id',component:Supplier_Edit, name:'Supplier_Edit',meta: { requiresAuth: true }},

  
  {   path:'/product/list',component:Product_list, name:'ProductList',meta: { requiresAuth: true }},
  {   path:'/product/add',component:Product_add, name:'Product_add',meta: { requiresAuth: true }},
  {   path:'/product/edit/:id',component:Product_edit, name:'Product_edit',meta: { requiresAuth: true }},

  {   path:'/product/repackage',component:Repackage_list, name:'Repackage_list',meta: { requiresAuth: true }},
  {   path:'/product/repackage_add',component:Repackage_add, name:'Repackage_add',meta: { requiresAuth: true }},
  {   path:'/product/repackage_edit/:id',component:Repackage_edit, name:'Repackage_edit',meta: { requiresAuth: true }},
  {   path:'/product/repackage/product/:id',component:Repackage_product, name:'Repackage_product',meta: { requiresAuth: true }},
  
  {   path:'/purchase/list',component:Purchase_list, name:'Purchase_list',meta: { requiresAuth: true }},
  {   path:'/purchase/add',component:Purchase_add, name:'Purchase_add',meta: { requiresAuth: true }},
  {   path:'/purchase/edit/:id',component:Purchase_edit, name:'Purchase_edit',meta: { requiresAuth: true }},

  // accounts
  {   path:'/settings/menu',component:Menu, name:'Menu',meta: { requiresAuth: true }},
  {   path:'/settings/category',component:Category, name:'Category',meta: { requiresAuth: true }},
  {   path:'/settings/Sesson',component:Sesson, name:'Sesson',meta: { requiresAuth: true }},
  {   path:'/settings/Uom',component:Uom, name:'Uom',meta: { requiresAuth: true }},
  {   path:'/settings/payment',component:payment, name:'payment',meta: { requiresAuth: true }},
  {   path:'/settings/rack',component:Rack, name:'rack',meta: { requiresAuth: true }},
  {   path:'/settings/warehouse',component:Warehouse, name:'warehouse',meta: { requiresAuth: true }},
  {   path:'/settings/color',component:Color, name:'color',meta: { requiresAuth: true }},
  {   path:'/settings/classification',component:Classification, name:'classification',meta: { requiresAuth: true }},
  {   path:'/settings/subcategory/:id',component:SubCategory, name:'subcategory',meta: { requiresAuth: true }},
  {   path:'/settings/country',component:Country, name:'Country',meta: { requiresAuth: true }},
  {   path:'/settings/province/:id',component:Province, name:'Province',meta: { requiresAuth: true }},
  {   path:'/settings/city/:country_id/:province_id',component:City, name:'City',meta: { requiresAuth: true }},
  {   path:'/settings/company',component:Company, name:'Company',meta: { requiresAuth: true }},
];


const router = createRouter({
    history: createWebHistory(),
    routes
  });
  
  router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters.isAuthenticated; // Adjust according to your store setup
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!isAuthenticated) {
        next({ name: 'Login' }); // Redirect to login if not authenticated
      } else {
        next(); // Proceed to the route
      }
    } else {
      next(); // Allow access to non-protected routes
    }
  });
  
  export default router;