<template>

	<div class="flex items-center justify-center grow bg-center bg-no-repeat page-bg">
		<div class="card max-w-[370px] w-full mt-10">
			
			<form @submit.prevent="Reset" class="card-body flex flex-col gap-5 p-10" id="sign_in_form">
				<div class="text-center mb-2.5">
					<h3 class="text-lg font-semibold text-gray-900 leading-none mb-2.5">
						Forgot Password
					</h3>
				</div>

				<div class="flex items-center gap-2">
					<p>Enter your email address and we'll send you an email with instructions to reset your password</p>
				</div>


				<div class="flex flex-col gap-1">
					<label class="form-label text-gray-900">
						Email
					</label>
					<input class="input" v-model="email" id="email" placeholder="email@email.com" type="text" required/>
				</div>				
				<button class="btn btn-primary flex justify-center grow">
					Reset Password
				</button>
			</form>
			<p v-if="error">{{ error }}</p>
		</div>
	</div>

</template>
<script>
import api from '@/services/api';
import Loader from '@/components/utils/loader.vue';
import Swal from 'sweetalert2';

import { onUnmounted } from 'vue';
export default {
	components: {
    	Loader
  	},
	data() {
		return {
			email: '',
		};
	},
	methods: {
		async Reset() {
				let loader = this.$loading.show();
				const response = await api.forgotpassword({
					email: this.email
				});				
				const data = response.data;		
				loader.hide();
				if (data.status == true) { // or check for other success criteria
					Swal.fire({
						title: 'Success!',
						text: 'Email Successfully sent to your email, Back to Sign In page',
						icon: 'success',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed) {
						  window.location="/";
						}
					})
				} else {
					Swal.fire({
                        title: 'Warning!',
                        text: response.data.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    });
				}
			
		},
  	},
};
</script>