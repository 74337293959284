<template>
  <div class="searchable-select">
    <div class="select-box">
      <input ref="selectIt" class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        type="text"
        v-model="searchTerm"
        placeholder="Select Supplier"
        @input="handleInput"
        @keydown="handleKeyDown"
        @focus="isOpen = true"  
        @blur="handleBlur"
        
      />
    </div>
    <div class="dropdown-list" v-if="isOpen">
      <div v-if="filteredOptions.length > 0">
        <div
          v-for="(option, index) in filteredOptions"
          :key="option.id"
          :value="defaultOption.name"
          class="dropdown-item"
          @click="selectOption(option)"
          :class="{ selected: index === highlightedIndex }"
          @mouseenter="highlightedIndex = index"
        >
          {{ option.name }}
        </div>
      </div>
      <div v-else class="no-results">
        No results found
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/services/apigen"; // Import your configured Axios instance

export default {

  props: {
    defaultOption: {
      type: Object,
      default: () => ({ name: '' }) // Default to an object with an empty name
    },
    emitEventName: {
      type: String,
      default: 'option-selected', // Default event name
    },
  },


  data() {
    return {
      searchTerm: this.defaultOption.name, // Initialize based on defaultOption
      isOpen: false, // Keep dropdown open for immediate feedback
      highlightedIndex: -1,
      options: [], // Initialize with an empty array
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter(option => {
        // Ensure option is defined and has a name
        return option && option.name && typeof option.name === 'string' &&
          option.name.toLowerCase().includes(this.searchTerm.toLowerCase());
      });
    },
  },
  methods: {
    async fetchOptions() {
      try {
        const response = await apiClient.get("/supplier_all");
        this.options = Array.isArray(response.data.x) ? response.data.x : [];
        if (this.defaultOption) {
          this.searchTerm = this.defaultOption.name || ''; // Default option name, if available
        }
        this.$emit('loaded');
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    },
 
    selectOption(option) {
      this.searchTerm = option.name; // Set selected option name
      this.isOpen = false; // Close the dropdown
      this.$emit(this.emitEventName, option); // Emit selected option
    },
    handleKeyDown(event) {
      if (event.key === 'ArrowDown') {
        this.highlightedIndex = Math.min(this.highlightedIndex + 1, this.filteredOptions.length - 1);
      } else if (event.key === 'ArrowUp') {
        this.highlightedIndex = Math.max(this.highlightedIndex - 1, 0);
      } else if (event.key === 'Enter') {
        if (this.highlightedIndex >= 0 && this.highlightedIndex < this.filteredOptions.length) {
          this.selectOption(this.filteredOptions[this.highlightedIndex]);
        }else{
          this.$emit(this.emitEventName, []);
        }
      }
    },
    handleInput() {
      this.isOpen = true; // Show dropdown on input

      // Emit the selected option or an empty array if no options are found
      if (this.filteredOptions.length === 0) {
        this.$emit(this.emitEventName, []); // Emit an empty array
      }
    },
    handleBlur() {
      setTimeout(() => {
        this.isOpen = false; // Close the dropdown after a slight delay
      }, 200);
    },
  },
  mounted() {
    this.fetchOptions();   
  },
  watch: {    
    defaultOption: {
      handler(newValue) {
        this.searchTerm = newValue.name || ''; // Update searchTerm when defaultOption changes
      },
      immediate: true // Run on initial load
    }
  },

};
</script>

<style scoped>
.searchable-select {
  position: relative;
  width: 100%;
}
.select-box input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}
.dropdown-list {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
  width: 100% !important;
  text-align: left;
}
.dropdown-item {
  padding: 8px;
  cursor: pointer;
  
}
.dropdown-item:hover,
.dropdown-item.selected {
  background: #f0f0f0;
}
.no-results {
  padding: 8px;
  color: gray;
  text-align: center; /* Center the no-results text */
}
</style>
