<template>
    <AccessControl :menuId="2" :menuSectionId="5" nameOfPage='Customer List' @accessGranted="handleAccess">
    </AccessControl>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">

                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            Customer Edit
                        </h1>
                    </div>
                    <div class="flex items-center gap-2.5">
                        <a class="btn btn-sm btn-success" @click="backToList">
                            Back to Customer List
                        </a>
                    </div>
                </div>
                <Notification ref="notificationRef" />

                <div class="card">
                    <div class="card-header  card-header-bg flex-wrap gap-2"></div>
                    <div class="card-body">

                        <form @submit.prevent="updateForm" style="width: 100%;">

                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Customer Name
                                        </label>
                                        <input required v-model="currentItem.customer_name"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Address 1
                                        </label>
                                        <input v-model="currentItem.customer_address1"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Address 2
                                        </label>
                                        <input v-model="currentItem.customer_address2"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-4 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Country
                                        </label>
                                        <select @change="handleCountryChange" id="country_id" ref="country_id2"
                                            v-model="currentItem.country_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>-Select-</option>
                                            <option v-for="country in countty_list" :key="country.country_id"
                                                :value="country.country_id">
                                                {{ country.country_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Province
                                        </label>
                                        <select @change="handleProvinceChange" v-model="currentItem.province_id"
                                            ref="province_id2"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <!-- Placeholder option -->
                                            <option v-for="province in province_list" :key="province.province_id"
                                                :value="province.province_id">
                                                {{ province.province_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            City
                                        </label>
                                        <select ref="city_id2" v-model="currentItem.city_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <option v-for="city in city_list" :key="city.city_id" :value="city.city_id">
                                                {{ city.city_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Zip Code
                                        </label>
                                        <input v-model="currentItem.zipcode"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Email Address
                                        </label>
                                        <input v-model="currentItem.email_address"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="email" placeholder="">
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Phone Number
                                        </label>
                                        <input v-model="currentItem.phone_number"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Mobile Number
                                        </label>
                                        <input v-model="currentItem.mobile_number"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Contact Person
                                        </label>
                                        <input v-model="currentItem.contact_person"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Contact Email Address
                                        </label>
                                        <input v-model="currentItem.contact_email_address"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Contact Mobile Number
                                        </label>
                                        <input v-model="currentItem.contact_mobile_number"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-end mt-4">

                                <button v-if="canEdit" type="submit"
                                    class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Update</button>
                            </div>
                        </form>


                    </div>







                </div>
            </div>
            <!-- end: container -->
        </template>
    </MainLayout>
</template>
<script>
import MainLayout from '@/components/MainLayout.vue'
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
import { ref, watch, onMounted } from 'vue';
import { VueGoodTable } from 'vue-good-table-next';
import Modal from '@/components/utils/Modal2.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css';
import Loader from '@/components/utils/loader.vue';
import Swal from 'sweetalert2';
import { useRoute } from 'vue-router';
import Notification from '@/components/utils/Notification.vue';
import AccessControl from '@/components/utils/UserAccess.vue';

export default {
    name: 'SupplierListPage',
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
        AccessControl
    },
    mounted() {
        let customer_id = this.$route.params.id; // Accessing route params via 'this'
        this.showInfo(customer_id);
    },
    methods: {

        backToList() {
            window.location = `/customer/list`;
        },
        async handleCountryChange() {
            //this.currentItem.province_id = ""; // Clear selected province
            // this.currentItem.city_id = "";

            if (this.showModal2 == true) {
                if (this.edit_first == 1) {
                    this.edit_first = 0;
                } else {
                    this.currentItem.city_id = "";
                    this.currentItem.province_id = "";
                }
            }
            this.province_list = []; // Clear province list
            this.city_list = []; // Clear province list
            await this.fetchProvinces(); // Fetch new provinces based on the selected country
        },

        async handleProvinceChange() {
            // this.currentItem.city_id = ""; // Clear selected province
            this.city_list = []; // Clear province list
            await this.fetchCity(); // Fetch new provinces based on the selected country
        },

        async fetchCity() {
            //if (!this.currentItem.province_id) return; // Skip if no country is selected


            let province_id = "";
            if (this.showModal2 == true) {
                province_id = this.$refs.province_id2.value;
                if (province_id == "") {
                    province_id = this.currentItem.province_id;
                }

            } else {
                province_id = this.$refs.province_id.value;
            }

            if (province_id == "") {

                return false;
            }

            try {
                const response = await apiClient.get(`/city/all/${province_id}`);
                if (response.data.status === true) { // or check for other success condition
                    this.city_list = response.data.data;
                }
            } catch (error) {
                console.error('Error fetching provinces:', error.response);
            } finally {

            }
        },

        async fetchProvinces() {


            let countryId = "";
            if (this.showModal2 == true) {
                countryId = this.$refs.country_id2.value;
                if (countryId == "") {
                    countryId = this.currentItem.country_id;
                }
            } else {
                countryId = this.$refs.country_id.value;
            }

            if (countryId == "") {

                return false;
            }

            try {
                const response = await apiClient.get(`/province/all/${countryId}`);
                if (response.data.status === true) { // or check for other success condition
                    this.province_list = response.data.data;
                    this.handleProvinceChange();
                }
            } catch (error) {
                console.error('Error fetching provinces:', error.response);
            } finally {

            }
        },
        async CountryList() {

            try {
                const response = await apiClient.get('/country/list/all');
                if (response.data.status == true) { // or check for other success c

                    this.countty_list = response.data.data;
                    this.handleCountryChange();


                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        },
        handleEdit(data) {
            this.showInfo(data.customer_id);
        },
        handleDelete(data) {

            let customer_id = data.customer_id;

            Swal.fire({
                title: 'Are you sure do you want to delete?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteData(customer_id);
                }
            });
        },
        openModal() {
            this.showModal = true;
            this.CountryList();
        },
        closeModal() {
            this.showModal = false;
            this.showModal2 = false;
        },
        closeModal2() {
            this.showModal2 = false;
        },
        async showInfo(customer_id) {


            let id = customer_id;
            const response = await apiClient.get(`/customer/${id}`);

            this.currentItem = response.data.data; // Store the fetched data
            this.showModal2 = true; // Show the modal

            this.CountryList();
            this.edit_first = 1;
            // Optionally, you might want to update the state or show the result in the UI
        },

        async updateForm() {

            try {
                const response = await apiClient.put('/customer/update', this.currentItem);
                if (response.data.status == true) {
                    this.$refs.notificationRef.show('Successfully Updated', 'success', '/customer/list');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error updatting form:', error.response);

            }
        },
        handleAccess({ canEdit, canDelete, canWrite }) {
            this.canEdit = canEdit;
            this.canDelete = canDelete;
            this.canWrite = canWrite;
        },
    },
    data() {
        return {
            showModal: false,
            currentItem: {
                category_id: 0,
                classification_id: 0,
                sub_category_id: 0,
                warehouse_id: 0,
                rack_id: 0,
                season_id: 0,
                color_id: 0,
                uom_id: 0,
                high_qty: 0,
                low_qty: 0,
                original_price: 0,
                selling_price: 0,
                discount_price: 0

            },
            showModal2: false,
            currentItem: [

            ],
            countty_list: [],
            province_list: [],
            city_list: [],
            edit_first: 0,
            canWrite: 0,
            canEdit: 0,
            canDelete: 0,
        };
    },


}
</script>


<style></style>