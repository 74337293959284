<template>
    <AccessControl :menuId="3" :menuSectionId="7" nameOfPage='Product List' @accessGranted="handleAccess">
    </AccessControl>
    <MainLayout>

        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            Edit Product
                        </h1>
                    </div>
                    <div class="flex items-center gap-2.5">
                        <a class="btn btn-sm btn-success" @click="backToList">
                            Back Product List
                        </a>
                    </div>
                </div>
                <Notification ref="notificationRef" />
                <div class="card">
                    <div class="card-header   card-header-bg flex-wrap gap-2">
                        <div class="flex flex-wrap gap-2 lg:gap-5"></div>
                    </div>

                    <div class="card-body">
                        <form @submit.prevent="updateForm" style="width: 100%;">


                            <div class="grid grid-cols-2 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Product Code
                                        </label>
                                        <input required v-model="currentItem.product_code"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Product Name
                                        </label>
                                        <input v-model="currentItem.product_name"
                                            class="shadow appearance-none border border-gray-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-2 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Short Description
                                        </label>
                                        <textarea v-model="currentItem.product_short_description"
                                            class="shadow appearance-none border border-gray-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder=""></textarea>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Full Description
                                        </label>
                                        <textarea v-model="currentItem.product_full_description"
                                            class="shadow appearance-none border border-gray-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder=""></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-4 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Classification
                                        </label>
                                        <select id="classification_id" ref="classification_id"
                                            v-model="currentItem.classification_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>-Select-</option>
                                            <option v-for="classification in classification_list"
                                                :key="classification.classification_id"
                                                :value="classification.classification_id">
                                                {{ classification.classification_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Category
                                        </label>
                                        <select @change="handle_change_category" v-model="currentItem.category_id"
                                            ref="category_edit"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <!-- Placeholder option -->
                                            <option v-for="category in category_list" :key="category.category_id"
                                                :value="category.category_id">
                                                {{ category.category_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Sub Category
                                        </label>
                                        <select ref="sub_category_id" v-model="currentItem.sub_category_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <option v-for="sub_category in sub_category_list"
                                                :key="sub_category.sub_category_id"
                                                :value="sub_category.sub_category_id">
                                                {{ sub_category.sub_category_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Ware House
                                        </label>
                                        <select ref="ware_house_id" v-model="currentItem.warehouse_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <option v-for="warehouse in ware_house_list" :key="warehouse.warehouse_id"
                                                :value="warehouse.warehouse_id">
                                                {{ warehouse.warehouse_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-4 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Rack
                                        </label>
                                        <select ref="rack_id" v-model="currentItem.rack_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <option v-for="rack in rack_list" :key="rack.rack_id" :value="rack.rack_id">
                                                {{ rack.rack_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Season
                                        </label>
                                        <select ref="season_id" v-model="currentItem.season_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <option v-for="season in season_list" :key="season.season_id"
                                                :value="season.season_id">
                                                {{ season.season_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Color
                                        </label>
                                        <select ref="color_id" v-model="currentItem.color_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <option v-for="color in color_list" :key="color.color_id"
                                                :value="color.color_id">
                                                {{ color.color_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Unit of Measure
                                        </label>
                                        <select ref="uom_id" v-model="currentItem.uom_id"
                                            class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                            <option value="0" selected>- Select -</option>
                                            <option v-for="uom in uom_list" :key="uom.uom_id" :value="uom.uom_id">
                                                {{ uom.uom_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-2 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            High Treshold
                                        </label>
                                        <input required v-model="currentItem.high_qty"
                                            class="shadow appearance-none border border-gray-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="number" placeholder="">
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Low Treashold
                                        </label>
                                        <input required v-model="currentItem.low_qty"
                                            class="shadow appearance-none border border-gray-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="number" placeholder="">
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Cost / Original Price
                                        </label>
                                        <input required v-model="currentItem.original_price"
                                            class="shadow appearance-none border border-gray-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Retail / Selling Price
                                        </label>
                                        <input required v-model="currentItem.selling_price"
                                            class="shadow appearance-none border border-gray-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>

                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Customer Discount Price
                                        </label>
                                        <input required v-model="currentItem.discount_price"
                                            class="shadow appearance-none border border-gray-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-end mt-4">

                                <button v-if="canEdit" type="submit"
                                    class="btn-success text-white px-4 py-2 rounded hover:bg-green-600">Update</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
            <!-- end: container -->
        </template>
    </MainLayout>
</template>
<script>
import MainLayout from '@/components/MainLayout.vue'
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
import apiClient_multi from '@/services/apigen_multipart'; // Import your configured Axios instance  
import { ref, watch, onMounted } from 'vue';
import { VueGoodTable } from 'vue-good-table-next';
import Modal from '@/components/utils/Modal2.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css';
import Swal from 'sweetalert2';
import Notification from '@/components/utils/Notification.vue';
import AccessControl from '@/components/utils/UserAccess.vue';

export default {
    name: 'editProductPage',
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
        AccessControl
    }, async mounted() {
        let product_id = this.$route.params.id; // Accessing route params via 'this'
        this.showInfo(product_id);

        this.first_load = 1;
        await this.ClassificationList();
        await this.CategoryList();
        await this.WarehouseList();
        await this.RackList();
        await this.SeasonList();
        await this.ColorList();
        await this.UOMList();
        this.first_load = 0;
        await this.handle_change_category();
    },
    setup() {
        const columns = ref([
            { label: '#', field: 'product_id', type: 'number', sortable: true },
            { label: 'Product Code', field: 'product_code', sortable: true },
            { label: 'Product Name', field: 'product_name', sortable: true },
            { label: 'Short Description', field: 'product_short_description', sortable: true },
            { label: 'High Threshold', field: 'high_qty', sortable: true },
            { label: 'Low Threshold', field: 'low_qty', sortable: true },
            { label: 'Running Balance', field: 'running_balance', sortable: true },
            { label: 'Original Price', field: 'original_price', sortable: true },
            { label: 'Selling Price', field: 'selling_price', sortable: true },
            { label: 'Discount Price', field: 'discount_price', sortable: true },

            {
                sortable: false,
                label: 'Actions',
                field: 'actions',
                type: 'actions',
                actions: [
                    {
                        label: 'Edit',
                        icon: 'edit',
                        onClick: ""
                    },
                    {
                        label: 'Delete',
                        icon: 'delete',
                        onClick: ""
                    }
                ]
            }
        ]);

        const rows = ref([]);
        const totalRows = ref(0);
        const searchTerm = ref('');
        const currentPage = ref(1);
        const perPage = ref(10);
        const sortField = ref('product_name');
        const sortType = ref('asc');

        const paginationOptions = ref({
            enabled: true,
            mode: 'pages',
            perPageDropdown: [5, 10, 20],
            perPage: perPage.value,
            totalRecords: totalRows.value,
            currentPage: currentPage.value
        });

        const searchOptions = {
            enabled: true
        };

        // Function to fetch data from the server
        const fetchData = async (page) => {
            try {
                const params = {
                    page: page,
                    per_page: perPage.value,
                    sort_field: sortField.value,
                    sort_type: sortType.value,
                    search: searchTerm.value,
                };
                const { data } = await apiClient.get('/product', { params });
                rows.value = data.data || [];
                totalRows.value = data.total || 0;

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const onPageChange = ({ currentPage }) => {
            paginationOptions.currentPage = currentPage;
            fetchData(currentPage);
        };

        const onPerPageChange = ({ currentPerPage }) => {
            console.log('Per page changed to:', currentPerPage);
            perPage.value = currentPerPage;
            paginationOptions.value.perPage = currentPerPage;
            fetchData();
        };

        const onSortChange = ([sort]) => {
            console.log('Sort changed to:', sort);
            sortField.value = sort.field;
            sortType.value = sort.type;
            fetchData();
        };
        // Watch for changes to trigger fetchData
        watch([currentPage, perPage, sortField, sortType, searchTerm], fetchData, { immediate: true });
        return {
            columns,
            rows,
            totalRows,
            paginationOptions,
            searchTerm,
            onPageChange,
            onPerPageChange,
            onSortChange,
            isLoading: false
        };
    },
    methods: {

        backToList() {
            window.location = `/product/list`;
        },

        previewImage(image) {
            this.currentImage = image;
            this.showPreview = true;
            this.showModal3 = false;
        },
        // Close the modal
        closePreview() {
            this.showPreview = false;
            this.currentImage = {};
            this.showModal3 = true;
        },
        // Remove the image
        async removeImage(image) {
            let image_id = image.id;
            let loader = this.$loading.show();
            try {
                const response = await apiClient.get(`/product/image_delete/${image_id}`);
                if (response.data.status === true) { // or check for other success condition
                    const imageDiv = this.$refs.imageContainer.querySelector(`[data-id="${image_id}"]`);
                    if (imageDiv) {
                        imageDiv.remove(); // Remove the <div> from the DOM
                        const product_id = this.$refs.product_id_image.value;
                        //this.getImage(product_id); 

                    }
                }
            } catch (error) {
                console.error('Error fetching images:', error.response);
            } finally {
                loader.hide();
            }
        },

        async getImage(product_id) {
            let loader = this.$loading.show();
            let id = product_id;
            try {
                const response = await apiClient.get(`/product/image_get/${id}/all`);
                if (response.data.status == true) { // or check for other success condition                    
                    this.product_images = response.data.data;
                    this.image_directory = response.data.path;
                } else {
                    this.product_images = [];
                }
            } catch (error) {
                console.error('Error fetching images:', error.response);
            } finally {
                loader.hide();
            }
        },

        async UploadImageForm() {
            let loader = this.$loading.show();
            const fileInput = this.$refs.product_logo_file;
            const product_id = this.$refs.product_id_image.value;
            const file = fileInput.files[0]; // Get the first file selected
            const formData = new FormData();
            formData.append('image', file);
            formData.append('product_id', product_id);
            const response2 = await apiClient_multi.post('/product/upload_image', formData);
            loader.hide();
            if (response2.data.status == true) { // or check for other success 

                Swal.fire({
                    title: 'Success!',
                    text: 'Image Successfully Uploaded',
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.getImage(product_id);
                        this.$refs.product_logo_file.value = '';
                        this.$refs.product_logo.src = require("@/assets/upload.png");
                    }
                })

            } else {

                Swal.fire({
                    title: 'Warning!',
                    text: response.data.message,
                    icon: 'warning',
                    confirmButtonText: 'OK'
                });
            }

        },

        onFileChange(e) {
            const logoPreview = this.$refs.product_logo;
            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    if (logoPreview) {
                        logoPreview.src = event.target.result;
                    }
                };
                reader.readAsDataURL(file);
            }

        },
        showshowImage(data) {
            this.showModal3 = true; // Show the modal 
            this.$nextTick(() => {
                this.$refs.product_id_image.value = data.product_id;
                this.getImage(data.product_id);
            });
        },
        showProduct(data) {
            window.location = '/supplier/get_products/' + data.supplier_id;
        },

        async handle_change_category() {
            await this.fetch_subcategory(); // 
        },

        async info_subcategory(cat_id) {

            let id = cat_id;
            try {
                const response = await apiClient.get(`/category/${id}/sub/all`);
                if (response.data.status === true) { // or check for other success condition
                    this.sub_category_list = response.data.data;
                }
            } catch (error) {
                console.error('Error fetching subcategories:', error.response);
            } finally {

            }
        },
        async fetch_subcategory() {
            this.$nextTick(() => {
                if (this.edit_first == 1) {
                    let set_cat = this.$refs.category_edit.value

                    if (set_cat == "") {
                        this.$refs.category_edit.value = this.currentItem.category_id;
                        this.edit_first = 0;
                    }
                }

                let id = this.$refs.category_edit.value;

                if (id == "") {
                    return false;
                }
                this.info_subcategory(id);


            });
        },
        async ClassificationList() {

            try {
                const response = await apiClient.get('/classification/list/all');
                if (response.data.status == true) {
                    this.classification_list = response.data.data;
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        },

        async CategoryList() {


            try {
                const response = await apiClient.get('/category/list/all');
                if (response.data.status == true) {
                    this.category_list = response.data.data;

                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
            }
        },

        async WarehouseList() {
            try {
                const response = await apiClient.get('/warehouse/list/all');
                if (response.data.status == true) {
                    this.ware_house_list = response.data.data;
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        },

        async RackList() {

            try {
                const response = await apiClient.get('/rack/list/all');
                if (response.data.status == true) {
                    this.rack_list = response.data.data;
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        },

        async SeasonList() {
            try {
                const response = await apiClient.get('/season/list/all');
                if (response.data.status == true) {
                    this.season_list = response.data.data;
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        },

        async ColorList() {

            try {
                const response = await apiClient.get('/color/list/all');
                if (response.data.status == true) {
                    this.color_list = response.data.data;
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        },

        async UOMList() {

            try {
                const response = await apiClient.get('/uom/list/all');
                if (response.data.status == true) {

                    this.uom_list = response.data.data;
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        },


        async showInfo(product_id) {


            let id = product_id;
            const response = await apiClient.get(`/product/${id}`);
            console.log(response.data.data)
            this.currentItem = response.data.data; // Store the fetched data             
            this.edit_first = 1;
            // Optionally, you might want to update the state or show the result in the UI
        },
        async updateForm() {


            try {
                const response = await apiClient.put('/product/update', this.currentItem);
                if (response.data.status == true) { // or check for other success c

                    if (response.data.status == true) {
                        this.$refs.notificationRef.show('Successfully Updated', 'success', '/product/list');
                    } else {
                        this.$refs.notificationRef.show(response.data.message, 'error', '0');
                    }
                }
            } catch (error) {
                console.error('Error updatting form:', error.response);

            }
        },
         handleAccess({ canEdit, canDelete, canWrite }) {
            this.canEdit = canEdit;
            this.canDelete = canDelete;
            this.canWrite = canWrite;
        },

    },
    data() {
        return {
            showModal: false,
            showModal3: false,
            form: {},
            showModal2: false,
            currentItem: [],
            classification_list: [],
            category_list: [],
            sub_category_list: [],
            ware_house_list: [],
            rack_list: [],
            season_list: [],
            color_list: [],
            uom_list: [],
            edit_first: 0,
            product_images: [],
            image_directory: "",
            showPreview: false,
            canWrite: 0,
            canEdit: 0,
            canDelete: 0,

        };
    },
}
</script>


<style scoped>
.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* Flexible columns */
    gap: 10px;
    /* Spacing between tiles */
    overflow-x: auto;
    /* Allow horizontal scroll if needed */
}

.tile {
    position: relative;
    width: 100%;
    padding-top: 75%;
    /* Aspect ratio of the tile */
    overflow: hidden;
    background-color: #f0f0f0;
    /* Background color for tiles */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    /* Align the button at the bottom */
    border: 2px solid #333;
    /* Add border: width, style, and color */
    border-radius: 8px;
    /* Optional: Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    /* Optional: Add a shadow for a raised effect */
}

.tile-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Cover the tile area */
}

.remove-button {
    position: relative;
    background-color: #ff4d4d;
    /* Red button */
    color: white;
    border: none;
    padding: 8px;
    margin: 10px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
    text-align: center;
    opacity: 0.8;
    transition: opacity 0.3s;
}

.remove-button:hover {
    opacity: 1;
    /* Make button fully visible on hover */
}
</style>