<template>
    <AccessControl :menuId="2" :menuSectionId="6" nameOfPage='Order List' @accessGranted="handleAccess"></AccessControl>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            Order List
                        </h1>
                    </div>
                    <div class="flex items-center gap-2.5">
                        <a v-if="canWrite" class="btn btn-sm btn-success" @click="openAdd">
                            Add Order
                        </a>
                    </div>
                </div>
                <Notification ref="notificationRef" />
                <div class="card">
                    <div class="card-header card-header-bg flex-wrap gap-2">
                        <div class="flex flex-wrap gap-2 lg:gap-5">
                            <div class="flex"><label class="input input-sm"><i class="ki-filled ki-magnifier"></i><input
                                        value="" v-model="searchTerm" type="text" placeholder="Search table"
                                        @input="fetchData"></label>
                                <label class="input input-sm" style="margin-left:10px;">Date From:<input value=""
                                        v-model="dateFrom" type="date" @input="fetchData"></label>


                                <label class="input input-sm" style="margin-left:10px;">Date From:<input value=""
                                        v-model="dateTo" type="date" @input="fetchData"></label>
                                <div>

                                    <button :disabled="export_loading" type="button" style="margin-left:10px;"
                                        @click="generate_report" ref="addRow"
                                        class="w-32 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mr-1">
                                        <span v-if="export_loading" class="spinner"></span>
                                        <span v-else>Generate Report</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <vue-good-table mode="remote" :server-side="true" :columns="columns" :rows="rows"
                        :total-rows="totalRows" :pagination-options="paginationOptions" :isLoading.sync="isLoading"
                        :search-options="{ enabled: false }" @page-change="onPageChange" @on-page-change="onPageChange"
                        @per-page-change="onPerPageChange" @sort-change="onSortChange">
                        <template #table-row="props">
                            <span v-if="props.column.field === 'out_from_warehouse'">
                                {{ props.row.out_from_warehouse === 1 ? 'Yes' : 'No' }}
                            </span>
                            <span v-else-if="props.column.field === 'is_delivered'">
                                {{ props.row.is_delivered === 1 ? 'Yes' : 'No' }}
                            </span>
                            <span v-else-if="props.column.field === 'actions'">
                                <a @click="showDetails(props.row)" class="btn btn-sm btn-icon btn-clear btn-light"
                                    href="#">
                                    <i class="ki-filled ki-search-list"></i>
                                </a>

                                <a @click="generate_report_detail(props.row)"
                                    class="btn btn-sm btn-icon btn-clear btn-light" href="#">
                                    <i class="ki-filled ki-exit-down"></i>
                                </a>


                                <template v-if="props.row.out_from_warehouse !== 1">
                                    <a v-if="canEdit" @click="handleEdit(props.row)"
                                        class="btn btn-sm btn-icon btn-clear btn-light" href="#">
                                        <i class="ki-filled ki-notepad-edit"></i>
                                    </a>
                                    <a v-if="canDelete" @click="handleDelete(props.row)"
                                        class="btn btn-sm btn-icon btn-clear btn-light" href="#">
                                        <i class="ki-filled ki-trash"></i>
                                    </a>
                                </template>

                                <template v-if="props.row.out_from_warehouse == 1 && props.row.is_delivered == 0">

                                    <a v-if="canDelete" @click="handleDelivered(props.row)"
                                        class="btn btn-sm btn-icon btn-clear btn-light" href="#">
                                        <i class="ki-duotone ki-delivery"></i>
                                    </a>


                                </template>



                            </span>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>

                    </vue-good-table>

                </div>
            </div>

            <Modal :isVisible="showModalDetail" @update:isVisible="showModalDetail = $event">
                <template v-slot:header>
                </template>
                <template v-slot:body>

                    <div style="padding:10px">
                        <h1 class="text-2xl font-bold mb-6" style="font-size:12px">Order Details</h1>
                        <div class="grid grid-cols-3 gap-3">
                            <div class="bg-blue-100 p-2 rounded-lg shadow-md">
                                <div class="col-span-2">
                                    <div class="justify-start mb-2">
                                        <p style="font-size:12px" class="font-semibold">Name:</p>
                                        <p style="font-size:12px">{{ order_info.customer_name }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-blue-100 p-2 rounded-lg shadow-md">
                                <div class="col-span-2">
                                    <div class="justify-start mb-2">
                                        <p style="font-size:12px" class="font-semibold">Order Number:</p>
                                        <p style="font-size:12px">{{ order_info.order_number }}</p>
                                    </div>
                                </div>
                            </div>



                            <div class="bg-blue-100 p-2 rounded-lg shadow-md">
                                <div class="col-span-2">
                                    <div class=" justify-start mb-2">
                                        <p style="font-size:12px" class="font-semibold">Order Date:</p>
                                        <p style="font-size:12px">{{ order_info.order_date }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid grid-cols-3 gap-4 pt-10 pb-10">

                            <div class="bg-blue-100 p-2 rounded-lg shadow-md">
                                <div class="col-span-2">
                                    <div class=" justify-start mb-2">
                                        <p style="font-size:12px" class="font-semibold">Payment:</p>
                                        <p style="font-size:12px">{{ order_info.payment_name }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="bg-blue-100 p-2 rounded-lg shadow-md">
                                <div class="col-span-2">
                                    <div class=" justify-start mb-2">
                                        <p style="font-size:12px" class="font-semibold">Payment Reference:</p>
                                        <p style="font-size:12px">{{ order_info.payment_reference }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="bg-blue-100 p-2 rounded-lg shadow-md">
                                <div class="col-span-2">
                                    <div class=" justify-start mb-2">
                                        <p style="font-size:12px" class="font-semibold">Amount Paid:</p>
                                        <p style="font-size:12px">{{ order_info.amount_paid }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="grid grid-cols-3 gap-4 pt-10 pb-10">

                            <div class="bg-blue-100 p-2 rounded-lg shadow-md">
                                <div class="col-span-2">
                                    <div class=" justify-start mb-2">
                                        <p style="font-size:12px" class="font-semibold">Created By:</p>
                                        <p style="font-size:12px">{{ order_info.created_by}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="bg-blue-100 p-2 rounded-lg shadow-md">
                                <div class="col-span-2">
                                    <div class=" justify-start mb-2">
                                        <p style="font-size:12px" class="font-semibold">Updated By:</p>
                                        <p style="font-size:12px">{{ order_info.updated_by}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="grid grid-cols-1 md:grid-cols-1 gap-6">
                            <!-- Item Details Column -->
                            <div class="p-4 rounded-lg shadow-md">
                                <table style="font-size:13px" class="min-w-full border-collapse border border-gray-300">
                                    <thead>
                                        <tr class="bg-gray-100">
                                            <th class="border border-gray-300 px-4 py-2 text-left">
                                                Code
                                            </th>
                                            <th class="border border-gray-300 px-4 py-2 text-left">
                                                Name
                                            </th>


                                            <th class="border border-gray-300 px-4 py-2 text-left">
                                                Retail
                                            </th>
                                            <th class="border border-gray-300 px-4 py-2 text-left">
                                                Quantity
                                            </th>

                                            <th class="border border-gray-300 px-4 py-2 text-left">
                                                Total Price
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in items_list" :key="index"
                                            class="odd:bg-white even:bg-gray-50">
                                            <td class="border border-gray-300 px-4 py-2">
                                                {{ item.code }}
                                            </td>
                                            <td class="border border-gray-300 px-4 py-2">
                                                {{ item.name }}
                                            </td>

                                            <td class="border border-gray-300 px-4 py-2">
                                                {{ item.retail }}
                                            </td>

                                            <td class="border border-gray-300 px-4 py-2">
                                                {{ item.quantity }}
                                            </td>

                                            <td class="border border-gray-300 px-4 py-2">
                                                {{ item.total }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </template>
            </Modal>


            <!-- end: container -->
        </template>
    </MainLayout>
</template>
<script>
import MainLayout from '@/components/MainLayout.vue'
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
import { ref, watch, onMounted } from 'vue';
import { VueGoodTable } from 'vue-good-table-next';
import Modal from '@/components/utils/Modal3.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css';
import Loader from '@/components/utils/loader.vue';
import Swal from 'sweetalert2';
import Notification from '@/components/utils/Notification.vue';
import AccessControl from '@/components/utils/UserAccess.vue';

export default {
    name: 'SupplierListPage',
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
        AccessControl
    },
    async mounted() {
        let number = this.$route.query.number; // Accessing route params via 'this'

        if (number != "") {
            this.searchTerm = number;
        }

    },
    setup() {
        const columns = ref([
            { label: '#', field: 'order_id', type: 'number', sortable: true },
            { label: 'Order Number', field: 'order_number', sortable: true },
            { label: 'Reference Number', field: 'payment_reference', sortable: true },
            { label: 'Order Date', field: 'order_date', sortable: true },
            { label: 'Grand Total', field: 'grand_total', sortable: true },
            { label: 'Payment', field: 'payment_name', sortable: true },
            { label: 'Customer', field: 'customer_name', sortable: true },
            { label: 'Amount Paid', field: 'amount_paid', sortable: true },
            { label: 'Confirmed', field: 'out_from_warehouse', sortable: true },
            { label: 'Delivered', field: 'is_delivered', sortable: true },

            {
                sortable: false,
                label: 'Actions',
                field: 'actions',
                type: 'actions',
                actions: [
                    {
                        label: 'Edit',
                        icon: 'edit',
                        onClick: ""
                    },
                    {
                        label: 'Delete',
                        icon: 'delete',
                        onClick: ""
                    }
                ],


            }
        ]);

        const rows = ref([]);
        const totalRows = ref(0);
        const searchTerm = ref('');
        const currentPage = ref(1);
        const dateFrom = ref('');
        const dateTo = ref('');
        const perPage = ref(20);
        const sortField = ref('created_at');
        const sortType = ref('desc');

        const paginationOptions = ref({
            enabled: true,
            mode: 'pages',
            perPageDropdown: [5, 10, 20],
            perPage: perPage.value,
            totalRecords: totalRows.value,
            currentPage: currentPage.value

        });

        const searchOptions = {
            enabled: true
        };

        // Function to fetch data from the server
        const fetchData = async (page) => {
            try {


                const params = {
                    page: page,
                    per_page: perPage.value,
                    sort_field: sortField.value,
                    sort_type: sortType.value,
                    search: searchTerm.value,
                    date_from: dateFrom.value,
                    date_to: dateTo.value
                };

                const { data } = await apiClient.get('/order', { params });
                rows.value = data.data || [];
                totalRows.value = data.total || 0;

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const onPageChange = ({ currentPage }) => {
            paginationOptions.currentPage = currentPage;
            fetchData(currentPage);
        };

        const onPerPageChange = ({ currentPerPage }) => {
            console.log('Per page changed to:', currentPerPage);
            perPage.value = currentPerPage;
            paginationOptions.value.perPage = currentPerPage;
            fetchData();
        };

        const onSortChange = ([sort]) => {
            console.log('Sort changed to:', sort);
            sortField.value = sort.field;
            sortType.value = sort.type;
            fetchData();
        };
        // Watch for changes to trigger fetchData
        watch([currentPage, perPage, sortField, sortType, searchTerm, dateFrom, dateTo], fetchData, { immediate: true });
        return {
            columns,
            rows,
            totalRows,
            paginationOptions,
            searchTerm,
            onPageChange,
            onPerPageChange,
            onSortChange,
            isLoading: false,
            dateFrom,
            dateTo,
        };
    },
    methods: {

        async generate_report() {

            let fromDate = this.dateFrom;
            let toDate = this.dateTo;
            let searchTerm = this.searchTerm;

            if (fromDate == "" || toDate == "") {
                this.$refs.notificationRef.show("Please select Date Range", 'error', '0');
                return false;
            }
            this.export_loading = true; // Set loading state to true
            const params = {
                search: searchTerm,
                date_from: fromDate,
                date_to: toDate
            };

            try {
                const response = await apiClient.get(`/report/orders`, {
                    params,
                    responseType: 'blob', // Important for binary data
                });

                // Create a blob from the response data
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Orders.xlsx'); // Set the desired file name
                document.body.appendChild(link);
                link.click();
                // Clean up the link
                link.parentNode.removeChild(link);
                this.export_loading = false;
            } catch (error) {
                console.error('Error downloading report:', error);
            }
        },


        async generate_report_detail(data) {

            let id = data.order_id;

            const params = {
                order_id: id,

            };

            try {
                const response = await apiClient.get(`/report/order_detail`, {
                    params,
                    responseType: 'blob', // Important for binary data
                });



                // Create a blob from the response data
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Order_Detail.xlsx'); // Set the desired file name
                document.body.appendChild(link);
                link.click();
                // Clean up the link
                link.parentNode.removeChild(link);
                this.export_loading = false;
            } catch (error) {
                console.error('Error downloading customer report:', error);
            }
        },



        async showDetails(data) {
            let id = data.order_id;
            this.showModalDetail = true;
            try {
                const response = await apiClient.get(`/order/detail/${id}`);
                if (response.data.status == true) { // or check for other success c
                    this.items_list = response.data.data.items; // Store the fetched data
                    this.order_info = response.data.data.info;

                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
            }
        },

        openAdd() {
            window.location = '/customer/order_add';
        },
        handleEdit(data) {
            window.location = '/customer/order_edit/' + data.order_id;
            //this.showInfo(data.supplier_id);
        },

        handleDelivered(data) {
            let order_id = data.order_id;
            Swal.fire({
                title: 'Are you sure do you want to deliver?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!',
                cancelButtonText: 'No, cancel!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deliveredData(order_id);
                }
            });
        },
        async deliveredData(id) {
            // try {
            const response = await apiClient.put('/order/delivered', { id: id });
            if (response.data.status == true) { // or check for other success c
                this.$refs.notificationRef.show('Successfully delivered', 'success', '1');
            } else {
                this.$refs.notificationRef.show(response.data.message, 'error', '0');
            }
            //} catch (error) {
            //  console.error('Error submitting form:', error.response);
            // }
        },




        handleDelete(data) {
            let order_id = data.order_id;
            Swal.fire({
                title: 'Are you sure do you want to delete?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes!',
                cancelButtonText: 'No, cancel!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteData(order_id);
                }
            });
        },
        async deleteData(id) {

            try {
                const response = await apiClient.delete('/order/delete', { data: { id: id } });
                if (response.data.status == true) { // or check for other success c
                    this.$refs.notificationRef.show('Successfully Deleted', 'success', '1');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
            }
        },
        handleAccess({ canEdit, canDelete, canWrite }) {
            this.canEdit = canEdit;
            this.canDelete = canDelete;
            this.canWrite = canWrite;
        },


    },
    data() {
        return {
            showModal: false,
            form: {},
            showModal2: false,
            currentItem: [

            ],
            countty_list: [],
            province_list: [],
            city_list: [],
            edit_first: 0,
            showModalDetail: false,
            items_list: [],
            order_info: {
                customer_name: "test",

            },
            canWrite: 0,
            canEdit: 0,
            canDelete: 0,
            export_loading: false
        };
    },


}
</script>

<style>
modal-header {
    display: none;
}
</style>