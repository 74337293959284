<template>
  <div v-if="isVisible" class="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
    <div class="bg-white rounded-lg shadow-lg w-full max-w-md mx-4 p-6">
      <!-- Header Slot -->
      <div class="flex justify-between items-center border-b border-gray-200 pb-4">
        <slot name="header">
          <!-- Default header if not provided -->
          <h2 class="text-lg font-semibold">Modal Header</h2>
        </slot>
        <button @click="close" class="text-gray-600 hover:text-gray-900">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <!-- Body Slot -->
      <div class="mt-4">
        <slot name="body" style="height: 30px">
          <!-- Default body if not provided -->
          <p>This is the body content of the modal.</p>
        </slot>
      </div>
      <!-- Footer Slot (optional) -->
      <!-- <slot name="footer">
      <div class="flex justify-end mt-4">
        <button @click="close" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Close</button>
      </div>
      </slot> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      this.$emit('update:isVisible', false);
    }
  }
};
</script>
