<template>
    <div class="select-container" style="width: 100% !important;" ref="selectBox">
        <div class="select-box left-align-select">
            <input type="text" v-model="searchTerm" @input="handleInput" ref="searchTerm"
                :placeholder="selectedOption ? selectedOption[labelKey] : placeholder" @focus="openDropdown"
                class="select-input" @keydown="handleKeyDown" />
        </div>

        <ul v-if="dropdownOpen && searchTerm" class="options-list">
            <li v-for="(option,index) in filteredOptions" :key="option.id" @click="selectOption(option)"
                :value="defaultOption.name" class="option-item"
                 :class="{ selected: index === highlightedIndex }"
                  @mouseenter="highlightedIndex = index"
                
                >
                {{ option[labelKey] }} <!-- Access the property using labelKey -->
            </li>
            <li v-if="filteredOptions.length === 0" class="no-results">
                No results found
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    emits: ['select', 'option-selected', 'enter-selected', 'clear-input'], // Declare the events
    props: {
        options: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            default: 'Search and select'
        },
        value: {
            type: Object,
            default: null
        },
        labelKey: { // Ensure this is correctly defined
            type: String,
            required: true
        },
        defaultOption: {
            type: Object,
            default: () => ({ name: '' }) // Default to an object with an empty name
        },
    },
    data() {
        return {
            searchTerm: this.defaultOption.name, // Initialize based on defaultOption
            selectedOption: this.value || null,
            dropdownOpen: false,
            highlightedIndex: -1,
        };
    },

    computed: {
        filteredOptions() {
            const trimmedSearchTerm = this.searchTerm.trim();


            if (this.defaultOption) {

                //this.searchTerm = this.defaultOption.name || ''; // Default option name, if available
            }

            if (!trimmedSearchTerm) {
                return this.options;
            }
            /*return this.options.filter(option => {
                const nameMatches = option[this.labelKey] && option[this.labelKey].toLowerCase().includes(trimmedSearchTerm.toLowerCase());
                const codeMatches = option.product_code && option.product_code.toLowerCase().includes(trimmedSearchTerm.toLowerCase());

                return nameMatches || codeMatches; // Return true if either name or code matches
            });*/

            return this.options.filter(option =>
                option[this.labelKey] && option[this.labelKey].toLowerCase().includes(trimmedSearchTerm.toLowerCase())
            );
        }
    },
    watch: {
        selectedOption(newVal) {
            this.$emit('option-selected', newVal); // Emit a custom event
            //this.$emit('input', newVal);
        },
        searchTerm(newVal) {
            if (!newVal) {
                this.dropdownOpen = true;
            }
        }
    },
    created() {
        // Listen for the 'clear-input' event
        this.$emit('clear-input', this.clearInput);
    },
    methods: {

        clearInput() {
            this.searchTerm = ''; // Clear the input
            this.dropdownOpen = false;
            this.selectedOption="";
           // this.selectedOption = null; // Clear the selected option
            //this.$refs.searchTerm.blur(); // Lose focus from the input
           // this.$refs.searchTerm.blur(); // Lose focus from the select
        },

        handleInput() {
            this.isOpen = true; // Show dropdown on input

            // Emit the selected option or an empty array if no options are found
            if (this.filteredOptions.length === 0) {
                this.$emit('option-selected', []); // Emit an empty array
            }
        },

        handleClickOutside(event) {

            if (this.$refs.selectBox && !this.$refs.selectBox.contains(event.target)) {
                this.dropdownOpen = false;
                //this.searchTerm="";
            }
        },

        selectOption(option) {
            this.selectedOption = option;
            this.searchTerm = option[this.labelKey]; // Use labelKey to set the selected option
            this.dropdownOpen = false;
            this.$emit('select', option); // Emit the select even
        },
        openDropdown() {
                
            this.dropdownOpen = true;
            
        },
        handleKeyDown(event) {
            if (event.key === 'ArrowDown') {
                this.highlightedIndex = Math.min(this.highlightedIndex + 1, this.filteredOptions.length - 1);
             
            } else if (event.key === 'ArrowUp') {
                this.highlightedIndex = Math.max(this.highlightedIndex - 1, 0);
               
            } else if (event.key === 'Enter') {
                /*event.preventDefault(); // Prevent default form submission
                if (this.filteredOptions.length > 0) {
                    
                    if(this.highlightedIndex==-1 &&  this.filteredOptions.length>0){
                        this.selectOption(this.filteredOptions[0]);
                        this.$emit('enter-selected', this.filteredOptions[0]);
                    }else{
                        console.log("ssss");
                        this.selectOption(this.filteredOptions[this.highlightedIndex]);
                        this.$emit('enter-selected', this.filteredOptions[this.highlightedIndex]);                    
                    }4005808837472

                    
                }*/

                if (this.highlightedIndex >= 0 && this.highlightedIndex < this.filteredOptions.length) {
                     event.preventDefault(); // Prevent default form submission
                    if (this.filteredOptions.length > 0) {
                        const selectedOption = this.highlightedIndex === -1 
                            ? this.filteredOptions[0] 
                            : this.filteredOptions[this.highlightedIndex];

                        this.selectOption(selectedOption);
                        console.log(selectedOption);
                        this.$emit('enter-selected', selectedOption);                    
                    }
                }else{
                    event.preventDefault();
                    if(this.filteredOptions.length>0){
                        this.selectOption(this.filteredOptions[0]);
                        this.$emit('enter-selected', this.filteredOptions[0]);
                    }
                }
            }  else if (event.key === 'Escape') {
                 this.dropdownOpen=false // Example for handling other keys
            }

            

            /*else if (event.key === 'Enter') {
                if (this.highlightedIndex >= 0 && this.highlightedIndex < this.filteredOptions.length) {
                    this.selectOption(this.filteredOptions[this.highlightedIndex]);
                } else {
                    this.$emit('option-selected', []);
                }
            }*/
        },
    },
    mounted() {
       
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
};
</script>

<style scoped>
/* Basic styles */
.select-container {
    position: relative;
    width: 200px;
}

.select-box {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
}

.select-input {
    width: 100%;
    border: none;
    outline: none;
}

.options-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    z-index: 10;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.option-item {
    padding: 10px;
    cursor: pointer;
    text-align: left;
}

.option-item:hover {
    background-color: #f0f0f0;
}

.selected {
  background: #f0f0f0;
}


.no-results {
    padding: 10px;
    text-align: center;
    color: #999;
}

.left-align-select {
    text-align: left;
    /* Aligns text to the left */
    padding: 5px;
    /* Optional padding for aesthetics */
}
</style>