<template>
    <AccessControl :menuId="5" :menuSectionId="11" nameOfPage='Item Received List' @accessGranted="handleAccess">
    </AccessControl>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            Item Received Add
                        </h1>
                    </div>
                    <div class="flex items-center gap-2.5">
                        <a class="btn btn-sm btn-success" @click="backToList"> Back Item Received List </a>
                    </div>
                </div>
                <Notification ref="notificationRef" />
                <div class="card">
                    <div class="card-header card-header-bg flex-wrap gap-2"></div>
                    <div class="card-body">
                        <form @submit.prevent="submitForm" style="width: 100%" ref="form">
                            <div class="grid grid-cols-3 gap-4">

                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Receive Date
                                        </label>
                                        <input required v-model="form.received_date"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="date" placeholder="" />
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Supplier <span class="text-red-500"> {{ indication_supplier }}</span>
                                        </label>

                                        <SearchableDropdown :defaultOption="defaultOption"
                                            placeholder="Choose an option" emitEventName="custom-option-selected"
                                            @custom-option-selected="handleOptionSelected" />


                                        <!--<div style="position: relative"
                                            class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            <input required type="text" v-model="searchQuery" ref="purchase_order_id"
                                                class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                @keyup="handleKeyup" placeholder="Type to search..." />
                                            <div class="suggestion-list-container">
                                                <ul v-if="suggestions.length" class="suggestion-list">
                                                    <li v-for="(suggestion, index) in suggestions" :key="index"
                                                        @click="selectSuggestion(suggestion)">
                                                        {{ suggestion.supplier_name }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>-->
                                    </div>
                                </div>

                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="" style="float:left;">
                                            Purchase Order Number           
                                           </label><i  @click="showDetails()" class="ki-filled ki-information-4 text-lg" style="line-height:16px;color:green;cursor:pointer;font-weight: bold;float:left;margin-left:10px"></i>
                                           <input required ref="purchase_order" @click="showModalPurchase"
                                            @keydown.prevent

                                            style="cursor: pointer;"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder="Select Purchase Order number" />
                                        <!--<div style="position: relative"
                                            class="block text-gray-700 text-left text-sm font-bold mb-2">                                            
                                            <input type="text" v-model="searchQuery3" ref="purchaseOrder_id"
                                                class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                @keyup="handleKeyup3" placeholder="Type to search..." />
                                            <div class="suggestion-list-container">
                                                <ul v-if="suggestions3.length" class="suggestion-list">
                                                    <li v-for="(suggestions3, index) in suggestions3" :key="index"
                                                        @click="selectSuggestion3(suggestions3)">
                                                        {{ suggestions3.purchase_order_number }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>-->
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-2">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Comments
                                        </label>
                                        <textarea v-model="form.comments"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder=""></textarea>
                                    </div>
                                </div>
                            </div>


                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-2">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                            Notes
                                        </label>
                                        <textarea v-model="form.notes"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder=""></textarea>
                                    </div>
                                </div>
                            </div>


                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-4">
                                    <div class="card">
                                        <div class="card-header bg-black flex-wrap gap-2">
                                            <div class="flex flex-wrap gap-2 lg:gap-5">
                                                <div class="flex">
                                                    <label class="text-white"> Product Item(s) </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card-body">
                                            <div class="grid grid-cols-4 gap-4">

                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        Product Code / Name
                                                    </label>

                                                    <!--<SearchableSelect 
                                                        v-model="selectedOption" 
                                                        :options="options" 
                                                        :labelKey="'product_info'"
                                                        placeholder="Search and select" 
                                                        @option-selected="handleOption"
                                                        />-->
                                                        <SearchableSelect v-model="selectedOption" :options="options"
                                                        :labelKey="'product_info'" placeholder="Search and select"
                                                        ref="select_product" @option-selected="handleOption"
                                                        @enter-selected="trigger_add" />
                                                </div>



                                                <!--    
                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        Product Code / Name
                                                    </label>


                                                    <div style="position: relative"
                                                        class="block text-gray-700 text-left text-sm font-bold mb-2">
                                                        <input type="text" v-model="searchQuery2"
                                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                            @keyup="handleKeyup2" placeholder="Type to search..." />
                                                        <div class="suggestion-list-container">
                                                            <ul v-if="suggestions2.length" class="suggestion-list">
                                                                <li v-for="(suggestion2, index) in suggestions2"
                                                                    :key="index"
                                                                    @click="selectSuggestion2(suggestion2)">
                                                                    {{ suggestion2.product_name }}
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <label class="text-red-500">
                                                            {{ indication_code }}
                                                        </label>

                                                    </div>
                                                </div>-->
                                                

                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        Quantity
                                                    </label>
                                                    <div class="block text-gray-700 text-left text-sm font-bold mb-2">
                                                        <input ref="pquantity"
                                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                            type="number" placeholder="" min="1" value="1" />
                                                    </div>
                                                </div>

                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        &nbsp; </label>
                                                    <div class="block text-gray-700 text-left text-sm font-bold mb-2">
                                                        <button type="button" @click="addRow" ref="addRow"
                                                            class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mr-1">
                                                            Add Product Item
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="grid grid-cols-5 gap-4">
                                                <div class="col-span-5">
                                                    <table style="font-size:13px"
                                                        class="min-w-full border-collapse border border-gray-300">
                                                        <thead>
                                                            <tr class="bg-gray-100">
                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Code
                                                                </th>
                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Name
                                                                </th>
                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Cost
                                                                </th>
                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Quantity
                                                                </th>

                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Total
                                                                </th>

                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(item, index) in items" :key="index"
                                                                class="odd:bg-white even:bg-gray-50">
                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.code }}
                                                                </td>
                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.name }}
                                                                </td>

                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.cost }}
                                                                </td>
                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.quantity }}
                                                                </td>

                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.total }}
                                                                </td>

                                                                <td class="border border-gray-300 px-4 py-2">

                                                                    <i @click="openModal(item)"
                                                                        class="text-blue-500 px-2 py-1 rounded hover:bg-black-600  ki-filled ki-notepad-edit fs-2tx cursor-pointer">                                                                        
                                                                    </i>

                                                                    <i @click="removeRow(index)"
                                                                        class="text-red-500 px-2 py-1 rounded hover:bg-black-600 ki-filled ki-trash fs-2tx">
                                                                    </i>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-end mt-4">
                                <button v-if="canWrite" type="submit"
                                    class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-1">
                                    Save
                                </button>
                                <button v-if="canWrite" type="button" @click="handlePosted()"
                                    class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
                                    Posted
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


            <Modal :isVisible="showPurchase" @update:isVisible="showPurchase = $event" @click.stop>
                <template v-slot:header>
                 
                </template>
                <template v-slot:body>
                    <PurchaseTable @row-clicked="handleRowClicked"></PurchaseTable>
                </template>
            </Modal>

            <Modal3 :isVisible="showModalDetail" @update:isVisible="showModalDetail = $event">
                <template v-slot:header>
                </template>
                <template v-slot:body>

                    <div style="padding:10px">
                        <h1 class="text-2xl font-bold mb-6" style="font-size:12px">Details</h1>                        
                        <div class="grid grid-cols-1 md:grid-cols-1 gap-6">
                            <!-- Item Details Column -->
                            <div class="p-4 rounded-lg shadow-md">
                                <table style="font-size:13px" class="min-w-full border-collapse border border-gray-300">
                                    <thead>
                                        <tr class="bg-gray-100">
                                            <th class="border border-gray-300 px-4 py-2 text-left">
                                               Supplier Product Code
                                            </th>
                                            <th class="border border-gray-300 px-4 py-2 text-left">
                                                Supplier Product Name
                                            </th>

                                            <th class="border border-gray-300 px-4 py-2 text-left">
                                                Product Code
                                            </th>

                                            <th class="border border-gray-300 px-4 py-2 text-left">
                                                Product Name
                                            </th>

                                            <th class="border border-gray-300 px-4 py-2 text-left">
                                                Cost Price
                                            </th>
                                            <th class="border border-gray-300 px-4 py-2 text-left">
                                                Quantity
                                            </th>

                                            <th class="border border-gray-300 px-4 py-2 text-left">
                                                Total Price
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in items_list" :key="index"
                                            class="odd:bg-white even:bg-gray-50">
                                            <td class="border border-gray-300 px-4 py-2">
                                                {{ item.code }}
                                            </td>
                                            <td class="border border-gray-300 px-4 py-2">
                                                {{ item.name }}
                                            </td>

                                            <td class="border border-gray-300 px-4 py-2">
                                                {{ item.pproduct_code }}
                                            </td>

                                           
                                            <td class="border border-gray-300 px-4 py-2">
                                                {{ item.pproduct_name }}
                                            </td>

                                            <td class="border border-gray-300 px-4 py-2">
                                                {{ item.cost_price }}
                                            </td>

                                            <td class="border border-gray-300 px-4 py-2">
                                                {{ item.quantity }}
                                            </td>

                                            <td class="border border-gray-300 px-4 py-2">
                                                {{ item.total }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </template>
            </Modal3>
            
            <EditModal v-if="isModalVisible" :isVisible="isModalVisible"
            :item="currentItem" @save="updateItem" @cancel="closeModal" />
            

            <!-- end: container -->
        </template>
    </MainLayout>
</template>
<script>
import MainLayout from "@/components/MainLayout.vue";
import apiClient from "@/services/apigen"; // Import your configured Axios instance
import { ref, watch, onMounted } from "vue";
import { VueGoodTable } from "vue-good-table-next";
import Modal from "@/components/utils/Modal4.vue";
import "vue-good-table-next/dist/vue-good-table-next.css";
import Loader from "@/components/utils/loader.vue";
import Swal from "sweetalert2";
import Notification from "@/components/utils/Notification.vue";
import SearchableDropdown from '@/components/utils/supplier_com.vue';
import SearchableSelect from "@/components/utils/Search.vue";
import PurchaseTable from "@/components/utils/Purchase_Modal.vue";
import Modal3 from '@/components/utils/Modal3.vue'
import EditModal from '@/components/utils/Items_quantityModal.vue'; // Adjust the path as necessary
import AccessControl from '@/components/utils/UserAccess.vue';

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

export default {
    name: "supplierListPage",
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
        SearchableSelect,
        SearchableDropdown,
        PurchaseTable,
        Modal3,
        EditModal,
        AccessControl
    },
    mounted() {
        this.getpayment(); // Call the function here
        this.form.purchase_order_id = 0;
        this.fetchSuggestions2();

    },
    created() {
        // Initialize the debounced function
        this.fetchSuggestionsDebounced = debounce(this.fetchSuggestions, 1000);
        this.fetchSuggestionsDebounced2 = debounce(this.fetchSuggestions2, 1000);
        this.fetchSuggestionsDebounced3 = debounce(this.fetchSuggestions3, 1000);
    },
    methods: {

        openModal(item) {          
            this.currentItem = item; // Set the current item to edit
            this.isModalVisible = true; // Show the modal
        },
        closeModal() {
            this.isModalVisible = false; // Hide the modal
            this.currentItem = null; // Reset the current item
        },
        updateItem(updatedItem) {
            // Update the item in the items array
            const index = this.items.findIndex(item => item.code === updatedItem.code);
            if (index !== -1) {      
                
                let total = (parseFloat(updatedItem.quantity) * parseFloat(updatedItem.cost)).toFixed(2);
                updatedItem.total =total; ///updatedItem.quantity;

                this.items.splice(index, 1, updatedItem); // Replace the item
              
            }
            this.closeModal(); // Close the modal
        },

        async showDetails(data) {
            let id = this.select_purchase.purchase_order_id;

            if(id==undefined){
                this.$refs.notificationRef.show("Plese seelct Purchase Order", 'error', '0');
                return false;
            }
          
            try {
                const response = await apiClient.get(`/purchase_order/detail/${id}`);
                if (response.data.status == true) { // or check for other success c
                    this.items_list = response.data.data.items; // Store the fetched data
                    this.base_info = response.data.data.info;
                    this.showModalDetail = true;
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
            }
        },

        handleRowClicked(rowData) {
            this.showPurchase = false;
            this.select_purchase = rowData
            this.$refs.purchase_order.value = rowData.purchase_order_number;
        },
        showModalPurchase(){
            this.showPurchase= true;
        },

        handleOptionSelected(option) {
            this.selected_supplier = option;
        },

        handleOption(option) {
            this.selectedOption = option; // Update selected option from event*/
        },

        trigger_add(option) {
            setTimeout(() => {
                this.$refs.addRow.click();
            }, 500);
        },

        addRow() {
            this.$refs.select_product.clearInput();
            if (Object.keys(this.selectedOption).length == 0) {
                this.$refs.notificationRef.show("Product Not found", 'error', '0');
                return false;
            }
        
            if (parseInt(Object.keys(this.items).length) != 0) {
                const index = this.items.findIndex(item => item.product_id === this.selectedOption.product_id);
                if (index !== -1) {
                    // Get the quantity from the reference and parse it
                    let quantityToAdd = parseInt(this.$refs.pquantity.value) || 0; // default to 0 if not a valid number                
                    
                    // Update the existing item's quantity by adding the new quantity
                    let q =  parseInt(this.items[index].quantity) + parseInt(quantityToAdd);
                    this.items[index].quantity = q;     
                    this.items[index].total = parseInt(q) * parseFloat(this.items[index].cost);              
                    this.$refs.notificationRef.show("Product Added", 'success', '0');                 
                    return false;
                }
            }    



            let code = this.selectedOption.product_code;
            let name = this.selectedOption.product_name;
            let cost = this.selectedOption.selling_price;
            let quantity = this.$refs.pquantity.value;
            let total = (parseFloat(quantity * cost)).toFixed(2);;
            let product_id = this.selectedOption.product_id;         
            this.items.push({ product_id: product_id, code: code, name: name, cost: cost, quantity: quantity, total: total });
    
        },
        removeRow(index) {
            // Remove the row at the specified index
            this.items.splice(index, 1);
        },

        handleKeyup2() {
            this.fetchSuggestionsDebounced2(); // Call the debounced function
        },
        handleKeyup() {
            this.fetchSuggestionsDebounced(); // Call the debounced function
        },

        handleKeyup3() {
            this.fetchSuggestionsDebounced3(); // Call the debounced function
        },

        clear_products_bottom() {
            this.productSearch2 = {};
            this.searchQuery2 = "";
            this.items = [];

        },
        async fetchSuggestions3() {
            if (this.searchQuery3.length > 2) {
                this.indication_purchase = "(Searching...)";
                try {
                    const response = await apiClient.post("/purchase_order_product/get/list", {
                        data: { search: this.searchQuery3 },
                    });

                    if (response.data.status == true) {
                        this.suggestions3 = response.data.data;
                        if (response.data.data.length == 0) {
                            this.indication_purchase = "(Purchase Order not Found...)";
                            this.form.supplier_id = 0;
                        } else {
                            this.indication_purchase = "";
                        }
                    }
                } catch (error) {
                    console.error("Error submitting form:", error.response);
                }
            } else {
                this.suggestions = []; // Clear suggestions if input is less than 3 characters
                this.clear_products_bottom();

            }
        },

        handlePosted() {

            const form = this.$refs.form;
            if (form.checkValidity()) {

                let data = {};
                data.items = this.items;

                // Check if items array is empty
                if (data.items.length == 0) {
                    // Trigger the notification if no items are selected
                    this.$refs.notificationRef.show("Please Select Product", 'error', '0');
                    return false;  // Prevent submission
                }

                // Show SweetAlert confirmation dialog
                Swal.fire({
                    title: 'Are you sure do you want to Post it?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Post it!',
                    cancelButtonText: 'No, cancel!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Proceed with form submission after confirmation
                        this.form.is_posted = 1;
                        this.submitForm();  // Call your form submission logic here
                    } else {
                        // If the user cancels, stop everything
                        return false;
                    }
                });

            } else {
                // If the form is invalid, trigger the native validation popups
                form.reportValidity();
                return false;  // Prevent submission if form is invalid
            }

        },
        async fetchSuggestions() {
            if (this.searchQuery.length > 2) {
                this.indication_supplier = "(Searching...)";
                try {
                    const response = await apiClient.post("/suppplier/get/list", {
                        data: { search: this.searchQuery },
                    });

                    if (response.data.status == true) {
                        this.suggestions = response.data.data;
                        if (response.data.data.length == 0) {
                            this.indication_supplier = "(Supplier not Found...)";
                            this.form.supplier_id = 0;
                        } else {
                            this.indication_supplier = "";
                        }
                    }
                } catch (error) {
                    console.error("Error submitting form:", error.response);
                }
            } else {
                this.suggestions = []; // Clear suggestions if input is less than 3 characters


            }
        },

        /*async fetchSuggestions2() {

            if (this.searchQuery2.length > 2) {
                this.indication_code = "(Searching...)";
                try {
                    const response = await apiClient.post(`/product/name_code/keyword2`, {
                        data: { keyword: this.searchQuery2 },
                    });

                    if (response.data.status == true) {

                        this.suggestions2 = response.data.data;
                        if (response.data.data.length == 0) {
                            this.indication_code = "(Product not Found...)";
                        } else {
                            this.indication_code = "";
                        }
                    }
                } catch (error) {
                    console.error("Error submitting form:", error.response);
                }
            } else {
                this.suggestions2 = []; // Clear suggestions if input is less than 3 characters
            }
        },*/
        
        async fetchSuggestions2() {

            try {
                const response = await apiClient.post(`/product/name_code/keyword2`, {
                    data: { keyword: this.searchQuery2 },
                });

                if (response.data.status == true) {
                    // this.suggestions2 = response.data.data;
                    this.options = response.data.data;
                }
            } catch (error) {
                console.error("Error submitting form:", error.response);
            }

        },
        

        selectSuggestion(suggestion) {
            this.searchQuery = suggestion.supplier_name; // Populate the input with the selected suggestion
            this.productSearch = suggestion;
            this.form.supplier_id = suggestion.supplier_id;
            this.suggestions = []; // Clear suggestions list
        },

        selectSuggestion2(suggestion) {
            this.searchQuery2 = suggestion.product_name; // Populate the input with the selected suggestion
            this.productSearch2 = suggestion;
            //this.form.supplier_id = suggestion.supplier_id;
            this.suggestions2 = []; // Clear suggestions list
        },

        selectSuggestion3(suggestion) {
            this.searchQuery3 = suggestion.purchase_order_number; // Populate the input with the selected suggestion
            this.productSearch3 = suggestion;
            this.form.purchase_order_id = suggestion.purchase_order_id;
            this.suggestions3 = []; // Clear suggestions list
        },

        backToList() {
            window.location = `/inventory/item_received_list`;
        },
        async getpayment() {
            try {
                const response = await apiClient.get("/payment/get/all");
                if (response.data.status == true) {
                    //
                    this.payment_list = response.data.data;
                }
            } catch (error) {
                console.error("Error submitting form:", error.response);
            }
        },
        async submitForm() {

            let data = {};
            data.items = this.items;
            this.form = { ...this.form, ...data };


            if(Object.keys(this.selected_supplier).length==0){
                this.$refs.notificationRef.show("Please Select Supplier", 'error', '0');
                return false;
            }

            if (data.items.length == 0) {
                this.$refs.notificationRef.show("Please Select Product", 'error', '0');
                return false;
            }   

            
            this.form.supplier_id = this.selected_supplier.id;
            this.form.purchase_order_id = this.select_purchase.purchase_order_id;
            try {
                const response = await apiClient.post(`/item_received/save`, this.form);

                if (response.data.status == true) {
                    this.showModal = false;
                    this.$refs.notificationRef.show('Successfully Saved', 'success', '/inventory/item_received_list');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        },
        handleAccess({ canEdit, canDelete, canWrite }) {
            this.canEdit = canEdit;
            this.canDelete = canDelete;
            this.canWrite = canWrite;
        },


    },
    data() {
        return {
            form: {},
            currentItem: [],
            suggestions: [],
            searchQuery: "",
            productSearch: [],
            isLoading: false, // Loading state
            indication_supplier: "",
            payment_list: [],
            suggestions2: [],
            searchQuery2: "",
            indication_code: "",
            items: [],
            productSearch2: {},
            suggestions3: [],
            indication_purchase: "",
            searchQuery3: "",

            searchTerm: "",
            selectedOption: [], // Stores the selected option
            dropdownOpen: false, // Controls whether the dropdown is open          
            options: [],
            showPurchase:false,
            select_purchase:{},
            showModalDetail:false,
            isModalVisible:false,
            canWrite: 0,
            canEdit: 0,
            canDelete: 0,



        };
    },
};
</script>

<style>
.search-select {
    position: relative;
}

.options-list {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
}

.option-item {
    padding: 10px;
    cursor: pointer;
}

.option-item:hover {
    background-color: #f0f0f0;
}

.selected-option {
    margin-top: 10px;
}
</style>
