<template>
  <MainLayout>
    <template v-slot:default>
      <!-- begin: container -->
      <div class="container-fixed">
        <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
          <div class="flex flex-col justify-center gap-2">
            <h1 class="text-xl font-semibold leading-none text-gray-900">
              My Profile
            </h1>
          </div>
        </div>
        <div class="grid grid-cols-1 xl:grid-cols-3 gap-5 lg:gap-7.5">
          <div class="col-span-1">
            <div class="card">
              <div class="card-header card-header-bg">
                <h3 class="card-title">
                  Information
                </h3>
              </div>
              <div class="card-body pt-4 pb-3">
                <div v-if="currentItem">
                  <table class="table-auto">
                    <tbody>
                      <tr>
                        <td class="text-sm font-medium text-gray-500 pb-3.5 pe-3  text-right">
                          First Name:
                        </td>
                        <td class="text-sm font-medium text-gray-800 pb-3.5 text-left">
                          {{currentItem.firstname}}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-sm font-medium text-gray-500 pb-3.5 pe-3 text-right">
                          Last Name:
                        </td>
                        <td class="text-sm font-medium text-gray-800 pb-3.5 text-left">
                          {{currentItem.lastname}}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-sm font-medium text-gray-500 pb-3.5 pe-3 text-right" >
                          Email:
                        </td>
                        <td class="text-sm font-medium text-gray-800 pb-3.5 text-left">
                          {{currentItem.email}}
                        </td>
                      </tr>   

                      <tr>
                        <td class="text-sm font-medium text-gray-500 pb-3.5 pe-3 text-right">
                          Department:
                        </td>
                        <td class="text-sm font-medium text-gray-800 pb-3.5 text-left">
                          {{currentItem.department_name}}
                        </td>
                      </tr>

                      <tr>
                        <td class="text-sm font-medium text-gray-500 pb-3.5 pe-3 text-right">
                          Position:
                        </td>
                        <td class="text-sm font-medium text-gray-800 pb-3.5 text-left">
                          {{currentItem.position_name}}
                        </td>
                      </tr>                
                    </tbody>
                  </table>

                  <div class="flex justify-end mt-4">
                      <button type="button" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"  @click="openModal">Edit</button>
                  </div>
                  
                </div>  
                <div v-else>
                    <p>No data to display yet.</p>
                    
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-2">
            <div class="card max-w-[370px]">
              <div class="card-header card-header-bg">
                <h3 class="card-title">
                  Change Password
                </h3>
              </div>
              <div class="card-body pt-4 pb-3">
                <form @submit.prevent="submitForm">
                  <div class=" px-3 mb-6 md:mb-0 text-left">
                    <label class="block uppercase text-gray-700 text-xs font-bold mb-2">
                    New Password
                    </label>
                    <input v-model="form.password"
                      class="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="c" type="password" required>
                    
                  </div>  

                  <div class=" px-3 mb-6 md:mb-0 text-left">
                    <label class="block uppercase text-gray-700 text-xs font-bold mb-2">
                    Confirm Password
                    </label>
                    <input v-model="form.confirm_password"
                      class="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="c3" type="password" required>
                  </div>

                  <div class="flex justify-end mt-4">
                      <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Update</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <Modal :isVisible="showModal2" @update:isVisible="showModal2 = $event" @click.stop>
                        <template v-slot:header>
                            <h2>Edit Information</h2>
                        </template>
                        <template v-slot:body>
                            <form @submit.prevent="updateForm">
                                <div class="flex flex-wrap -mx-3 mb-6">                                    
                                    <div class="w-full px-3 mb-6 md:mb-0 text-left">
                                        <label class="block uppercase text-gray-700 text-xs font-bold mb-2">
                                            First Name
                                        </label>
                                        <input v-model="currentItem.firstname"
                                            class="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                            id="" type="text" required>

                                            <input v-model="currentItem.user_id"
                                            class="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                            id="" type="hidden" required>   
                                    </div>

                                    <div class="w-full px-3 mb-6 md:mb-0 text-left">
                                        <label class="block uppercase text-gray-700 text-xs font-bold mb-2">
                                            Last Name
                                        </label>
                                        <input v-model="currentItem.lastname"
                                            class="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                            id="" type="text" required>
                                    </div>

                                    <div class="w-full px-3 mb-6 md:mb-0 text-left">
                                        <label class="block uppercase text-gray-700 text-xs font-bold mb-2">
                                            Email
                                        </label>
                                        <input v-model="currentItem.email"
                                            class="appearance-none block w-full text-gray-700 border bg-gray-100  border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                            id="" type="email" required readonly>
                                    </div>

                                    <div class="w-full px-3 mb-6 md:mb-0 text-left">
                                        <label class="block uppercase text-gray-700 text-xs font-bold mb-2">
                                            Department
                                        </label>
                                        <input v-model="currentItem.department_name"
                                            class="appearance-none block w-full text-gray-700 border border-gray-500 bg-gray-100  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                            id="" type="email" required readonly>
                                    </div>

                                    <div class="w-full px-3 mb-6 md:mb-0 text-left">
                                        <label class="block uppercase text-gray-700 text-xs font-bold mb-2">
                                            Position
                                        </label>
                                        <input v-model="currentItem.position_name"
                                            class="appearance-none block w-full text-gray-700 bg-gray-100 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none"
                                            id="" type="email" required readonly>
                                    </div>

                                  
                                </div>
                                <div class="flex justify-end mt-4">
                                    <button type="button" @click="closeModal2"
                                        class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mr-1">Close</button>
                                    <button type="submit"
                                        class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Update</button>
                                </div>
                            </form>
                        </template>
      </Modal>

      </div>


      
    </template>
  </MainLayout>
</template>

<script>



import MainLayout from '@/components/MainLayout.vue'
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
import { ref, watch, onMounted } from 'vue';
import { VueGoodTable } from 'vue-good-table-next';
import Modal from '@/components/utils/Modal.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css';
import Loader from '@/components/utils/loader.vue';
import Swal from 'sweetalert2';



export default {
  name: 'ProfilePage',
  components: {
    MainLayout,
    Modal
    },
    mounted(){
      this.info();
    },
    methods: {
      async info() {
            
            let loader = this.$loading.show();
            try {
                const response = await apiClient.get('/users/specific_info');
                if (response.data.status == true) { // or check for other success c
                    loader.hide();
                    this.currentItem = response.data.data;
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
                loader.hide();
            }
        },         
        openModal() {
          this.showModal2 = true; // Show the modal
        },
        closeModal() {
          this.showModal2 = false;
            
        },
        async updateForm() {
            let loader = this.$loading.show();

            try {
                const response = await apiClient.put('/users/myprofile', this.currentItem);
                loader.hide();
                if (response.data.status == true) { // or check for other success c

                    Swal.fire({
                        title: 'Success!',
                        text: 'Profile Successfully Updated',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                }else{
                    Swal.fire({
                        title: 'Warning!',
                        text: response.data.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    });
                }
            } catch (error) {
                console.error('Error updatting form:', error.response);
                loader.hide();
            }
        },
        async submitForm() {
            let loader = this.$loading.show();
            try {
                const response = await apiClient.put('/users/change_password', this.form);
                loader.hide();
                if (response.data.status == true) { // or check for other success c
                    Swal.fire({
                        title: 'Success!',
                        text: 'Password Successfully Change',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                }else{
                    Swal.fire({
                        title: 'Warning!',
                        text: response.data.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    });
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
                loader.hide();
            }
        }

    },
    data() {
        return {
            showModal: false,
            form: {},
            showModal2: false,
            currentItem: null, 
            user_info:[]
           

        };
    },
}
</script>