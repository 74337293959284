<template>
    <AccessControl :menuId="1" :menuSectionId="3" nameOfPage='User Right' @accessGranted="handleAccess"></AccessControl>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">

                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            Rights Detail
                        </h1>
                    </div>
                    <div class="flex items-center gap-2.5">
                        <a class="btn btn-sm btn-light" @click="backtoRight">
                            Back To Rights List
                        </a>
                        <a   v-if="canWrite" class="btn btn-sm btn-success" @click="openModal">
                            Add Rights
                        </a>
                    </div>
                </div>
                <Notification ref="notificationRef" />

                <div class="card">
                    <div class="card-header   card-header-bg flex-wrap gap-2">

                    </div>
                    <vue-good-table mode="remote" :server-side="true" :columns="columns" :rows="rows"
                        :total-rows="totalRows" :pagination-options="paginationOptions" :isLoading.sync="isLoading"
                        :search-options="{ enabled: false }" @page-change="onPageChange" @on-page-change="onPageChange"
                        @per-page-change="onPerPageChange" @sort-change="onSortChange">
                        <template #table-row="props">

                            <span v-if="props.column.field === 'can_read'">
                                {{ props.row.can_read === 1 ? 'Yes' : 'No' }}
                            </span>

                            <span v-else-if="props.column.field === 'can_write'">
                                {{ props.row.can_write === 1 ? 'Yes' : 'No' }}
                            </span>

                            <span v-else-if="props.column.field === 'can_edit'">
                                {{ props.row.can_edit === 1 ? 'Yes' : 'No' }}
                            </span>

                            <span v-else-if="props.column.field === 'can_delete'">
                                {{ props.row.can_delete === 1 ? 'Yes' : 'No' }}
                            </span>

                            <span v-else-if="props.column.field === 'actions' && props.row.user_right_id!=1">

                                <a  v-if="canEdit" @click="handleEdit(props.row)" class="btn btn-sm btn-icon btn-clear btn-light"
                                    href="#">
                                    <i class="ki-filled ki-notepad-edit"></i>
                                </a>

                                <a v-if="canDelete" @click="handleDelete(props.row)" class="btn btn-sm btn-icon btn-clear btn-light"
                                    href="#">
                                    <i class="ki-filled ki-trash">
                                    </i>
                                </a>
                            </span>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>

                    </vue-good-table>

                    <Modal :isVisible="showModal" @update:isVisible="showModal = $event">
                        <template v-slot:header>
                            <h2>Add Permission</h2>
                        </template>
                        <template v-slot:body>
                            <form @submit.prevent="submitForm">

                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <div class="w-full px-3 mb-6 md:mb-0">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                            Section
                                        </label>
                                        <select required id="roleSelect" v-model="selectedRole"
                                            class="border border-gray-300 rounded-md p-2 w-full">

                                            <optgroup label="Users">
                                                <option value="1-1">Department</option>
                                                <option value="1-2">Position</option>
                                                <option value="1-3">User Right</option>
                                                <option value="1-4">User List</option>
                                            </optgroup>

                                            <optgroup label="Orders">
                                                <option value="2-5">Customer List</option>
                                                <option value="2-6">Order List</option>
                                            </optgroup>

                                            <optgroup label="Product">
                                                <option value="3-7">Product List</option>
                                                <option value="3-8">Repackage</option>
                                            </optgroup>

                                            <optgroup label="Supplier">
                                                <option value="4-9">Supplier List</option>
                                            </optgroup>

                                            <optgroup label="Inventory">
                                                <option value="5-10">Item Ledger</option>
                                                <option value="5-11">Item Received List</option>
                                                <option value="5-12">Inventory Count List</option>
                                                <option value="5-13">Item Adjustment List</option>
                                            </optgroup>

                                            <optgroup label="Purchse Order">
                                                <option value="6-14">Purchase  Order List</option>
                                               
                                            </optgroup>

                                            <optgroup label="Settings">
                                                <option value="7-16">Category</option>
                                                <option value="7-17">Season</option>
                                                <option value="7-18">Unit Of Measure</option>
                                                <option value="7-19">Payment</option>
                                                <option value="7-20">Rack</option>
                                                <option value="7-21">Warehouse</option>
                                                <option value="7-22">Color</option>
                                                <option value="7-23">Classificaction</option>
                                                <option value="7-24">Country</option>
                                                <option value="7-25">Company Info</option>
                                            </optgroup>
                                        </select>
                                    </div>
                                </div>

                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>View</td>
                                                <td>Create</td>
                                                <td>Edit</td>
                                                <td>Delete</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><i class="ki-filled ki-check"></i></td>
                                                <td><input type="checkbox" value="1" v-model="this.form.can_create">
                                                </td>
                                                <td><input type="checkbox" value="1" v-model="this.form.can_edit"></td>
                                                <td><input type="checkbox" value="1" v-model="this.form.can_delete">
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="flex justify-end mt-4">
                                    <button type="button" @click="closeModal"
                                        class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mr-1">Close</button>
                                    <button type="submit"
                                        class="btn-success text-white px-4 py-2 rounded hover:bg-green-600">Save</button>
                                </div>
                            </form>
                        </template>
                    </Modal>


                    <Modal :isVisible="showModal2" @update:isVisible="showModal2 = $event">
                        <template v-slot:header>
                            <h2>Edit Permission</h2>
                        </template>
                        <template v-slot:body>
                            <form @submit.prevent="updateForm">

                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <div class="w-full px-3 mb-6 md:mb-0">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                            Section
                                        </label>
                                        <select required id="roleSelect" disabled v-model="currentItem.selectedRole"
                                            class="border border-gray-300 rounded-md p-2 w-full  bg-gray-400 appearance-none">

                                            <optgroup label="Users">
                                                <option value="1-1">Department</option>
                                                <option value="1-2">Position</option>
                                                <option value="1-3">User Right</option>
                                                <option value="1-4">User List</option>
                                            </optgroup>

                                            <optgroup label="Orders">
                                                <option value="2-5">Customer List</option>
                                                <option value="2-6">Order List</option>
                                            </optgroup>

                                            <optgroup label="Product">
                                                <option value="3-7">Product List</option>
                                                <option value="3-8">Repackage</option>
                                            </optgroup>

                                            <optgroup label="Supplier">
                                                <option value="4-9">Supplier List</option>
                                            </optgroup>

                                            <optgroup label="Inventory">
                                                <option value="5-10">Item Ledger</option>
                                                <option value="5-11">Item Received List</option>
                                                <option value="5-12">Inventory Count List</option>
                                                <option value="5-13">Item Adjustment List</option>
                                            </optgroup>                                    

                                            <optgroup label="Purchse Order">
                                                <option value="6-14">Purchase  Order List</option>
                                               
                                            </optgroup>

                                            <optgroup label="Settings">
                                                <option value="7-16">Category</option>
                                                <option value="7-17">Season</option>
                                                <option value="7-18">Unit Of Measure</option>
                                                <option value="7-19">Payment</option>
                                                <option value="7-20">Rack</option>
                                                <option value="7-21">Warehouse</option>
                                                <option value="7-22">Color</option>
                                                <option value="7-23">Classificaction</option>
                                                <option value="7-24">Country</option>
                                                <option value="7-25">Company Info</option>
                                            </optgroup>
                                        </select>
                                    </div>
                                </div>

                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>View</td>
                                                <td>Create</td>
                                                <td>Edit</td>
                                                <td>Delete</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><i class="ki-filled ki-check"></i></td>
                                                <td><input type="checkbox" ref="can_create" value="0"
                                                        v-model="this.currentItem.can_create"></td>
                                                <td><input type="checkbox" ref="can_edit" value="0"
                                                        v-model="this.currentItem.can_edit"></td>
                                                <td><input type="checkbox" ref="can_delete" value="0"
                                                        v-model="this.currentItem.can_delete"></td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="flex justify-end mt-4">
                                    <button type="button" @click="closeModal2"
                                        class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mr-1">Close</button>
                                    <button type="submit"
                                        class="btn-success text-white px-4 py-2 rounded hover:bg-green-600">update</button>
                                </div>
                            </form>
                        </template>
                    </Modal>
                </div>
            </div>
            <!-- end: container -->
        </template>
    </MainLayout>
</template>
<script>

import MainLayout from '@/components/MainLayout.vue'
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
import { ref, watch, onMounted } from 'vue';
import { VueGoodTable } from 'vue-good-table-next';
import Modal from '@/components/utils/Modal.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css';
import Loader from '@/components/utils/loader.vue';
import Swal from 'sweetalert2';
import { useRoute } from 'vue-router';
import Notification from '@/components/utils/Notification.vue';
import SearchableSelect from "@/components/utils/Search.vue";
import AccessControl from '@/components/utils/UserAccess.vue';

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

export default {
    name: 'SupplieProductsPage',
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
        SearchableSelect,
        AccessControl
    },
    created() {
        // Initialize the debounced function

    },
    mounted() {
        // alert(route.params.addNow); 

    },
    setup() {
        const route = useRoute();
        const SupplierName = ref(''); // Define the parent category name as a ref
        const Repackage_id = ref('');
        const columns = ref([
            { label: '#', field: 'user_right_detail_id', type: 'number', sortable: true },
            { label: 'Menu', field: 'menu_name', sortable: true },
            { label: 'Section', field: 'section_name', sortable: true },
            { label: 'Read.', field: 'can_read', sortable: true },
            { label: 'Create', field: 'can_write', sortable: true },
            { label: 'Edit', field: 'can_edit', sortable: true },
            { label: 'Delete', field: 'can_delete', sortable: true },
            {
                sortable: false,
                label: 'Actions',
                field: 'actions',
                type: 'actions',
                actions: [

                    {
                        label: 'Edit',
                        icon: 'edit',
                        onClick: ""
                    },
                    {
                        label: 'Delete',
                        icon: 'delete',
                        onClick: ""
                    }
                ]
            }
        ]);

        const rows = ref([]);
        const totalRows = ref(0);
        const searchTerm = ref('');
        const currentPage = ref(1);
        const perPage = ref(10);
        const sortField = ref('user_right_detail_id');
        const sortType = ref('asc');

        const paginationOptions = ref({
            enabled: true,
            mode: 'pages',
            perPageDropdown: [5, 10, 20],
            perPage: perPage.value,
            totalRecords: totalRows.value,
            currentPage: currentPage.value
        });

        const searchOptions = {
            enabled: true
        };

        // Function to fetch data from the server
        const fetchData = async (page) => {
            const parentCat = route.params.id;

            try {
                const params = {
                    page: page,
                    per_page: perPage.value,
                    sort_field: sortField.value,
                    sort_type: sortType.value,
                    search: searchTerm.value,
                };
                const { data } = await apiClient.get(`/user_right/listdetail/${parentCat}`, { params });
                rows.value = data.data || [];
                totalRows.value = data.total || 0;
              

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const onPageChange = ({ currentPage }) => {
            paginationOptions.currentPage = currentPage;
            fetchData(currentPage);
        };

        const onPerPageChange = ({ currentPerPage }) => {
            console.log('Per page changed to:', currentPerPage);
            perPage.value = currentPerPage;
            paginationOptions.value.perPage = currentPerPage;
            fetchData();
        };

        const onSortChange = ([sort]) => {
            console.log('Sort changed to:', sort);
            sortField.value = sort.field;
            sortType.value = sort.type;
            fetchData();
        };
        // Watch for changes to trigger fetchData
        watch([currentPage, perPage, sortField, sortType, searchTerm], fetchData, { immediate: true });
        return {
            columns,
            rows,
            totalRows,
            paginationOptions,
            searchTerm,
            onPageChange,
            onPerPageChange,
            onSortChange,
            route,
            isLoading: false,
            SupplierName,
            Repackage_id

        };
    },
    methods: {


        backtoRight() {
            this.$router.push({ name: 'Right' }); // Redirect on success
        },
        handleEdit(data) {
            this.showInfo(data);
        },
        handleDelete(data) {

            let id = data.user_right_detail_id;

            Swal.fire({
                title: 'Are you sure do you want to delete?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteData(id);
                }
            });

        },
        openModal() {
            this.showModal = true;

        },
        closeModal() {
            this.showModal = false;

        },
        closeModal2() {
            this.showModal2 = false;
        },
        showInfo(data) {
            this.showModal2 = true; // Show the modal
            let selectedRole = data.menu_id + "-" + data.menu_section_id;
            this.currentItem = data;
            this.currentItem.selectedRole = selectedRole;

            this.$nextTick(() => {
                if (this.currentItem.can_write == 1) {
                    this.$refs.can_create.checked = true;
                }

                if (this.currentItem.can_edit == 1) {
                    this.$refs.can_edit.checked = true;
                }

                if (this.currentItem.can_delete == 1) {
                    this.$refs.can_delete.checked = true;
                }
            });

        },
        async deleteData(id) {
            let loader = this.$loading.show();
            let repackage_id = this.$route.params.id;
            try {
                const response = await apiClient.delete(`/user_right/detail/delete`, { data: { id: id } });
                if (response.data.status == true) { // or check for other success c
                    this.updateAccess(); 
                    this.$refs.notificationRef.show('Successfully Deleted', 'success', '1');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
                loader.hide();
            }
        },
        async updateForm() {
            const parentCat = this.$route.params.id; // Accessing route params via 'this'  
            try {           
                const response = await apiClient.put(`/user_right/detail/update`, this.currentItem);      
                if (response.data.status == true) { // or check for other success 
                    this.showModal2 = false;     
                    this.updateAccess(); 
                    this.$refs.notificationRef.show('Successfully Updated', 'success', '1');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error updatting form:', error.response);

            }
        },
        async submitForm() {
            const parentCat = this.$route.params.id; // Accessing route params via 'this'          
            this.form.selected_role = this.selectedRole;
            try {
                const response = await apiClient.post(`/user_right/detail/save/${parentCat}`, this.form);

                if (response.data.status == true) {
                    this.showModal = false;
                    this.updateAccess(); 
                    this.$refs.notificationRef.show('Successfully Saved', 'success', '1');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        },
        handleAccess({ canEdit, canDelete, canWrite }) {
            this.canEdit = canEdit;
            this.canDelete = canDelete;
            this.canWrite = canWrite;
        },
        async updateAccess(){
            /*const response = await apiClient.get(`/user_right/get/access_menu`);

            if (response.data.status === true) {
                // Store the retrieved data in local storage
                this.menus = response.data.data.menu;
                this.section = response.data.data.section;

                localStorage.setItem('menus', JSON.stringify(this.menus));
                localStorage.setItem('section', JSON.stringify(this.section));
            } else {
                this.$router.push('/');
            }*/

        }

    },
    data() {
        return {
            showModal: false,
            form: {
                group_no: 0,
                repackage_id: 0,
                quantity: 1
            },
            showModal2: false,
            currentItem: '',
            suggestions: [],
            searchQuery: "",
            productSearch: [],
            options: [],
            selectedOption: {},
            newItem: [],
            canWrite: 0,
            canEdit: 0,
            canDelete: 0,

        };
    },


}
</script>


<style>
.suggestion-list {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    background-color: white;
    position: absolute;
    z-index: 1050;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    /* Match input width or adjust as needed */
    max-width: 524px;
    /* Optional: limit maximum width */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Optional: add shadow for better visibility */
}

.suggestion-list-container {
    position: absolute;
    /* Position relative to the input */
    top: 100%;
    /* Adjust to place list directly below input */
    left: 0;
    right: 0;
    /* Ensure it aligns with the input width */
}

.suggestion-list li {
    padding: 10px;
    cursor: pointer;
}

.suggestion-list li:hover {
    background-color: #f0f0f0;
}


table {
    width: 100%;
    border-collapse: collapse;
    /* Merges borders */
}

th,
td {
    border: 1px solid #dddddd;
    /* Light gray border */
    text-align: left;
    /* Aligns text to the left */
    padding: 8px;
    /* Adds space inside cells */
}

th {
    background-color: #f2f2f2;
    /* Light gray background for header */
    color: #333;
    /* Darker text color for readability */
}

tr:nth-child(even) {
    background-color: #f9f9f9;
    /* Alternating row color */
}

tr:hover {
    background-color: #e0e0e0;
    /* Highlight row on hover */
}
</style>