import { createApp } from 'vue'
import App from './App.vue'
import router from './routers'



//createApp(App).use(router).mount('#app');
import store from './store'; // Import the Vuex store
import VueGoodTablePlugin from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css';
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import './assets/css/style2.css';

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const options = {
  position: 'top-right', // Set the position to top-right
  timeout: 5000, // Duration of the toast
  closeOnClick: true,
  pauseOnHover: true,
};

const app = createApp(App);
app.use(LoadingPlugin,{
    // Optional parameters
  
    color:"white",
    opacity:0.6,
    backgroundColor:"#25251f"
});
app.use(router);
app.use(store);
app.use(VueGoodTablePlugin);


app.mount('#app');