<template></template>

<script>
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
export default {
    data() {
        return {
            canEdit: false,
            canDelete: false,
            canWrite: false,
            hasAccess: false,
            loading: true,
        };
    },
    props: {
        menuId: {
            type: Number,
            required: true,
        },
        menuSectionId: {
            type: Number,
            required: true,
        },
        nameOfPage: { // Ensure this is exactly as expected
            type: String,
            required: true,
        },
    },
    /*watch: {
        menuId: 'getAccess',
        menuSectionId: 'getAccess',
        nameOfPage: 'getAccess',
    },*/
    async created() {
        await this.getAccess();
    },
    methods: {
        async getAccess() {
            this.loading = true; // Set loading state

            try {
                const response = await apiClient.get(`/user_right/get/access?menu=${this.menuId}&menu_section_id=${this.menuSectionId}`);
              
                if (response.data.status) {
                   
                    let section = response.data.data.section;
                    const accessControl = response.data.data.control;
                    this.hasAccess =  section.includes(this.nameOfPage);

                    //localStorage.setItem('menus', JSON.stringify(response.data.data.menus));
                   // localStorage.setItem('section', JSON.stringify(response.data.data.section));

                    if (!this.hasAccess) {                                            
                        this.$router.push('/');
                    }

                    this.canEdit = accessControl.can_edit;
                    this.canDelete = accessControl.can_delete;
                    this.canWrite = accessControl.can_write;
                    console.log('Access Control:', { canEdit: this.canEdit, canDelete: this.canDelete, canWrite: this.canWrite });
                   
                    this.$emit('accessGranted', {
                        canEdit: this.canEdit,
                        canDelete: this.canDelete,
                        canWrite: this.canWrite,
                    });

                } else {
                    this.$router.push('/');
                }
            } catch (error) {
                console.error('Error fetching access control:', error);
            } finally {
                this.loading = false; // End loading state
            }
        },
    },
};
</script>