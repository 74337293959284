<template>
	<MainLayout>
		<template v-slot:default>
			<!-- begin: container -->
			<div class="container-fixed" id="content_container"></div>
			<!-- end: container -->
			<!-- begin: container -->
			<div class="container-fixed">
				<div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
					<div class="flex flex-col justify-center gap-2">
						<h1 class="text-xl font-semibold leading-none text-gray-900"> Dashboard </h1>

					</div>

				</div>
			</div>
			<!-- end: container -->
			<!-- begin: container -->
			<div class="container-fixed">
				<div class="grid gap-5 lg:gap-7.5 mb-10">
					<!-- begin: grid -->
					<div class="grid lg:grid-cols-3 gap-y-5 lg:gap-7.5 items-stretch">
						<div class="card card-grid  min-w-full">
							<div class="card-header text2" style="min-height: 10px !important;margin:-4px">
								<h2 class="card-title text-2sm">
									Total Products
								</h2>
								<div class=" max-w-48 text-2sm">
									{{totalProducts}}
								</div>
							</div>
							<div class="card-body">
								<div>
									<div class="scrollable-x-auto">
										<table style="font-size:12px" cellpadding="0" cellspacing="0"
											class="min-w-full border-collapse border border-gray-300">
											<thead>
												<tr class="bg-gray-100">
													<th  colspan="2" class="border border-gray-300 px-4 py-2 text-left">
														By Category
													</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(item, index) in byCategory" :key="index"
													class="odd:bg-white even:bg-gray-50">
													<td class="border border-gray-300 px-4 py-2" width="50%">
														<a :href="'product/list?cat_id=' + item.category_id">{{ item.category_name }}</a>
													</td>
													<td>
														{{ item.product_count}}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>

						<div class="card card-grid h-full min-w-full">
							<div class="card-header" style="min-height: 10px !important;margin:-4px">
								<h2 class="card-title text-2sm">
									Total Products
								</h2>
								<div class=" max-w-48 text-2sm">
									{{totalProducts}}
								</div>
							</div>
							<div class="card-body">
								<div>
									<div class="scrollable-x-auto">
										<table style="font-size:12px"
											class="min-w-full border-collapse border border-gray-300">
											<thead>
												<tr class="bg-gray-100">
													<th  colspan="2" class="border border-gray-300 px-4 py-2 text-left">
														By Warehouse
													</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(item, index) in byWareHouse" :key="index"
													class="odd:bg-white even:bg-gray-50">
													<td class="border border-gray-300 px-4 py-2" width="50%">
														<a :href="'product/list?ware_id=' + item.warehouse_id">{{ item.warehouse_name }}</a>
													</td>
													<td>
														{{ item.product_count}}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>

						<div class="card card-grid h-full min-w-full">
							
							<div class="card-body">
								<div>
									<div class="scrollable-x-auto">
										<table style="font-size:12px"
											class="min-w-full border-collapse border border-gray-300">
											<thead>
												<tr class="bg-gray-100">
													<th  class="border border-gray-300 px-4 py-2 text-left">
														Low Threshold
													</th>
													<th  class="border border-gray-300 px-4 py-2 text-left">
														High Threshold
													</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(item, index) in byThreshold" :key="index"
													class="odd:bg-white even:bg-gray-50">
													<td class="border border-gray-300 px-4 py-2" width="50%">
														<a :href="'product/list?code=' + item.low_product_code">{{ item.low_product }}</a>
													</td>
													<td>
														<a :href="'product/list?code=' + item.high_product_code">{{ item.high_product}}</a>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					

					<div class="grid lg:grid-cols-3 gap-y-5 lg:gap-7.5 items-stretch">
						<div class="card card-grid h-full min-w-full">	
							<div class="card-body">
								<div>
									<div class="scrollable-x-auto">
										<table style="font-size:12px"
											class="min-w-full border-collapse border border-gray-300">
											<thead>
												<tr class="bg-gray-100">
													<th  class="border border-gray-300 px-4 py-2 text-left">
														Recent Order
													</th>
													<th  class="border border-gray-300 px-4 py-2 text-left">
														Total Items
													</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(item, index) in byRecentOrder" :key="index"
													class="odd:bg-white even:bg-gray-50">
													<td class="border border-gray-300 px-4 py-2" width="50%">
														<a :href="'customer/order_list?number=' + item.order_number">{{ item.order_number }}</a>
													</td>
													<td>
														{{ item.item_count}}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>


						<div class="card card-grid h-full min-w-full">	
							<div class="card-body">
								<div>
									<div class="scrollable-x-auto">
										<table style="font-size:12px"
											class="min-w-full border-collapse border border-gray-300">
											<thead>
												<tr class="bg-gray-100">
													<th  class="border border-gray-300 px-4 py-2 text-left">
														For Delivery Orders
													</th>
													<th  class="border border-gray-300 px-4 py-2 text-left">
														Total Items
													</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(item, index) in byDelivery" :key="index"
													class="odd:bg-white even:bg-gray-50">
													<td class="border border-gray-300 px-4 py-2" width="50%">
														<a :href="'customer/order_list?number=' + item.order_number">{{ item.order_number }}</a>
													</td>
													<td>
														{{ item.item_count}}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>

						<div class="card card-grid h-full min-w-full">	
							<div class="card-body">
								<div>
									<div class="scrollable-x-auto">
										<table style="font-size:12px"
											class="min-w-full border-collapse border border-gray-300">
											<thead>
												<tr class="bg-gray-100">
													<th  class="border border-gray-300 px-4 py-2 text-left">
														Non Moving
													</th>
													<th  class="border border-gray-300 px-4 py-2 text-left">
														Fast Moving
													</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(item, index) in byMoving" :key="index"
													class="odd:bg-white even:bg-gray-50">
													<td class="border border-gray-300 px-4 py-2" width="50%">
														<a :href="'product/list?code=' + item.non_product_code">{{ item.non_product }}</a>
													</td>
													<td>
														<a :href="'product/list?code=' + item.moving_product_code">{{ item.moving_product}}</a>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>	

					<div class="grid lg:grid-cols-3 gap-y-5 lg:gap-7.5 items-stretch">
						<div class="card card-grid h-full min-w-full">	
							<div class="card-body">
								<div>
									<div class="scrollable-x-auto">
										<table style="font-size:12px"
											class="min-w-full border-collapse border border-gray-300">
											<thead>
												<tr class="bg-gray-100">
													<th  class="border border-gray-300 px-4 py-2 text-left">
														Pending Purchase Order
													</th>
													<th  class="border border-gray-300 px-4 py-2 text-left">
														Total Items
													</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(item, index) in byPendingPurchase" :key="index"
													class="odd:bg-white even:bg-gray-50">
													<td class="border border-gray-300 px-4 py-2" width="50%">
														<a :href="'purchase/list?number=' + item.purchase_order_number">{{ item.purchase_order_number }}</a>
													</td>
													<td>
														{{ item.total_items}}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>

						<div class="card card-grid h-full min-w-full">	
							<div class="card-body">
								<div>
									<div class="scrollable-x-auto">
										<table style="font-size:12px"
											class="min-w-full border-collapse border border-gray-300">
											<thead>
												<tr class="bg-gray-100">
													<th  class="border border-gray-300 px-4 py-2 text-left">
														Receiving Order
													</th>
													<th  class="border border-gray-300 px-4 py-2 text-left">
														Total Items
													</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(item, index) in byReceiving" :key="index"
													class="odd:bg-white even:bg-gray-50">
													<td class="border border-gray-300 px-4 py-2" width="50%">
														<a :href="'purchase/list?number=' + item.purchase_order_number">{{ item.purchase_order_number }}</a>
													</td>
													<td>
														{{ item.total_items}}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>

						<div class="card card-grid h-full min-w-full">	
							<div class="card-body">
								<div>
									<div class="scrollable-x-auto">
										<table style="font-size:12px"
											class="min-w-full border-collapse border border-gray-300">
											<thead>
												<tr class="bg-gray-100">
													<th  class="border border-gray-300 px-4 py-2 text-left">
														Item Adjustment
													</th>
													<th  class="border border-gray-300 px-4 py-2 text-left">
														Total Items
													</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(item, index) in byAdustment" :key="index"
													class="odd:bg-white even:bg-gray-50">
													<td class="border border-gray-300 px-4 py-2" width="50%">
														<a :href="'inventory/item_adjustment_list?code=' + item.link">{{ item.product_name }}</a>
													</td>
													<td>
														{{ item.quantity}}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- end: container -->
		</template>
	</MainLayout>
</template>

<script>
import MainLayout from './MainLayout.vue'
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
import { ref, watch, onMounted } from 'vue';
export default {
	name: 'DashboardPage',
	components: {
		MainLayout
	},
	methods: {
		async byCategoryFetch() {
			try {
				const response = await apiClient.get('/dashboard/bycategory');
				if (response.data.status == true) {

					this.byCategory = response.data.data.category; // Store the fetched data					
					this.totalProducts = this.byCategory.reduce((total, item) => total + item.product_count, 0)

				} else {
					this.$refs.notificationRef.show(response.data.message, 'error', '0');
				}
			} catch (error) {
				console.error('Error submitting form:', error.response);
			}
		},
		async byWareHouseFetch() {
			try {
				const response = await apiClient.get('/dashboard/byWareHouse');
				if (response.data.status == true) {

					this.byWareHouse = response.data.data.warehouse; // Store the fetched data					
					this.byWareHouse_totalProducts = this.byWareHouse.reduce((total, item) => total + item.product_count, 0)

				} else {
					this.$refs.notificationRef.show(response.data.message, 'error', '0');
				}
			} catch (error) {
				console.error('Error submitting form:', error.response);
			}
		},
		async bythresholdFetch() {
			try {
				const response = await apiClient.get('/dashboard/byThreshold');
				if (response.data.status == true) {

					this.byThreshold = response.data.data; // Store the fetched data					
					//this.byWareHouse_totalProducts = this.byWareHouse.reduce((total, item) => total + item.product_count, 0)

				} else {
					this.$refs.notificationRef.show(response.data.message, 'error', '0');
				}
			} catch (error) {
				console.error('Error submitting form:', error.response);
			}
		},

		async byrecentOrderFetch() {
			try {
				const response = await apiClient.get('/dashboard/recentOrder');
				if (response.data.status == true) {

					this.byRecentOrder = response.data.data; // Store the fetched data					
					//this.byWareHouse_totalProducts = this.byWareHouse.reduce((total, item) => total + item.product_count, 0)

				} else {
					this.$refs.notificationRef.show(response.data.message, 'error', '0');
				}
			} catch (error) {
				console.error('Error submitting form:', error.response);
			}
		},

		async bymovingFetch() {
			try {
				const response = await apiClient.get('/dashboard/moving');
				if (response.data.status == true) {

					this.byMoving = response.data.data; // Store the fetched data					
					//this.byWareHouse_totalProducts = this.byWareHouse.reduce((total, item) => total + item.product_count, 0)

				} else {
					this.$refs.notificationRef.show(response.data.message, 'error', '0');
				}
			} catch (error) {
				console.error('Error submitting form:', error.response);
			}
		},

		async byPendingPurchaseFetch() {
			try {
				const response = await apiClient.get('/dashboard/pending_purchase');
				if (response.data.status == true) {

					this.byPendingPurchase = response.data.data; // Store the fetched data	

				} else {
					this.$refs.notificationRef.show(response.data.message, 'error', '0');
				}
			} catch (error) {
				console.error('Error submitting form:', error.response);
			}
		},
		async byReceivingFetch() {
			try {
				const response = await apiClient.get('/dashboard/receive_order');
				if (response.data.status == true) {

					this.byReceiving = response.data.data; // Store the fetched data	

				} else {
					this.$refs.notificationRef.show(response.data.message, 'error', '0');
				}
			} catch (error) {
				console.error('Error submitting form:', error.response);
			}
		},

		async byAdjustmentFetch() {
			try {
				const response = await apiClient.get('/dashboard/item_adjustment');
				if (response.data.status == true) {

					this.byAdustment = response.data.data; // Store the fetched data	

				} else {
					this.$refs.notificationRef.show(response.data.message, 'error', '0');
				}
			} catch (error) {
				console.error('Error submitting form:', error.response);
			}
		},

		async byDeliveryFetch() {
			try {
				const response = await apiClient.get('/dashboard/delivery');
				if (response.data.status == true) {

					this.byDelivery = response.data.data; // Store the fetched data	

				} else {
					this.$refs.notificationRef.show(response.data.message, 'error', '0');
				}
			} catch (error) {
				console.error('Error submitting form:', error.response);
			}
		},
	},
	async mounted() {
		await Promise.all([
			this.byCategoryFetch(),
			this.byWareHouseFetch(),
			this.bythresholdFetch(),
			this.byrecentOrderFetch(),
			this.bymovingFetch(),
			this.byPendingPurchaseFetch(),
			this.byReceivingFetch(),
			this.byAdjustmentFetch(),
			this.byDeliveryFetch(),
		]);
	},
	data() {
		return {
			byCategory: [],
			byWareHouse:[],
			byThreshold:[],
			byRecentOrder:[],
			byMoving:[],
			byPendingPurchase:[],
			byReceiving:[],
			byAdustment:[],
			byDelivery:[],
			totalProducts:0,
			byWareHouse_totalProducts:0
		};
	},

}
</script>