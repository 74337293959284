<template>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">

                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            Item Ledger List
                        </h1>
                    </div>
                </div>
                <Notification ref="notificationRef" />
                <div class="card">
                    <div class="card-header card-header-bg flex-wrap gap-2">
                        <div class="flex flex-wrap gap-2 lg:gap-5">
                            <div class="flex"><label class="input input-sm"><i class="ki-filled ki-magnifier"></i><input
                                        value="" v-model="searchTerm" type="text" placeholder="Product Code / Name"
                                        @input="fetchData"></label>


                                <select style="height:34px;margin-left:10px;" ref="ware_house_id" v-model="warehouse"
                                    placeholder="Select Ware House" @change="fetchData"
                                    class="bg-gray-50 border border-gray-500 text-gray-700 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                    <option value="0" selected>- All Warehouse -</option>
                                    <option v-for="warehouse in ware_house_list" :key="warehouse.warehouse_id"
                                        :value="warehouse.warehouse_id">
                                        {{ warehouse.warehouse_name }}
                                    </option>
                                </select>

                                <label class="input input-sm" style="margin-left:10px;">Date From:<input value=""
                                        v-model="dateFrom" type="date" @input="fetchData"></label>


                                <label class="input input-sm" style="margin-left:10px;">Date To:<input value=""
                                        v-model="dateTo" type="date" @input="fetchData"></label>

                                <div>
                                    <button :disabled="export_loading" type="button" style="margin-left:10px;"
                                        @click="generate_report" ref="addRow"
                                        class="w-32 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mr-1">
                                        <span v-if="export_loading" class="spinner"></span>
                                        <span v-else>Generate Report</span>
                                    </button>
                                </div>


                            </div>
                        </div>
                    </div>
                    <vue-good-table mode="remote" :server-side="true" :columns="columns" :rows="rows"
                        :total-rows="totalRows" :pagination-options="paginationOptions" :isLoading.sync="isLoading"
                        :search-options="{ enabled: false }" @page-change="onPageChange" @on-page-change="onPageChange"
                        @per-page-change="onPerPageChange" @sort-change="onSortChange">
                        <template #table-row="props">

                            <span v-if="props.column.field === 'actions'">

                                <template v-if="props.row.application == 'item received'">
                                    <a class="btn btn-sm btn-icon btn-clear btn-light" target="_blank"
                                        :href="'/inventory/item_info/' + props.row.table_id">
                                        <i class="ki-filled ki-search-list"></i>
                                    </a>
                                </template>

                                <template v-else-if="props.row.application == 'order'">
                                    <a class="btn btn-sm btn-icon btn-clear btn-light" target="_blank"
                                        :href="'/customer/order_info/' + props.row.table_id">
                                        <i class="ki-filled ki-search-list"></i>
                                    </a>
                                </template>

                                <template v-else-if="props.row.application == 'item adjustment'">
                                    <a class="btn btn-sm btn-icon btn-clear btn-light" target="_blank"
                                        :href="'/inventory/itemAdjustment_info/' + props.row.table_id">
                                        <i class="ki-filled ki-search-list"></i>
                                    </a>
                                </template>

                                <template v-else-if="props.row.application == 'Item Adjustment'">
                                    <a class="btn btn-sm btn-icon btn-clear btn-light" target="_blank"
                                        :href="'/inventory/itemAdjustment_info/' + props.row.table_id">
                                        <i class="ki-filled ki-search-list"></i>
                                    </a>
                                </template>

                                <template v-else-if="props.row.application == 'inventory count'">
                                    <a class="btn btn-sm btn-icon btn-clear btn-light" target="_blank"
                                        :href="'/inventory/inventory_count_info/' + props.row.table_id">
                                        <i class="ki-filled ki-search-list"></i>
                                    </a>
                                </template>



                            </span>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>


                    </vue-good-table>

                </div>
            </div>
            <!-- end: container -->
        </template>
    </MainLayout>
</template>
<script>
import MainLayout from '@/components/MainLayout.vue'
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
import { ref, watch, onMounted } from 'vue';
import { VueGoodTable } from 'vue-good-table-next';
import Modal from '@/components/utils/Modal2.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css';
import Loader from '@/components/utils/loader.vue';
import Swal from 'sweetalert2';
import Notification from '@/components/utils/Notification.vue';

export default {
    name: 'SupplierListPage',
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
    },
    setup() {
        const columns = ref([
            { label: '#', field: 'item_ledger_id', type: 'number', sortable: true },
            { label: 'Date Posted', field: 'date_posted', sortable: true },
            { label: 'Application', field: 'application', sortable: true },

            { label: 'code', field: 'product_code', sortable: true },
            { label: 'Name', field: 'product_name', sortable: true },
            { label: 'In', field: 'debit', sortable: false },
            { label: 'Out', field: 'credit', sortable: false },
            { label: 'Balance', field: 'balance', sortable: false },
            { label: 'Reference Number', field: 'reference_number', sortable: false },
            { label: 'Invoice Number', field: 'invoice_number', sortable: false },
            //{ label: 'Is Posted', field: 'is_posted', sortable: true },

            {
                sortable: false,
                label: 'Actions',
                field: 'actions',
                type: 'actions',
            }
        ]);

        const rows = ref([]);
        const totalRows = ref(0);
        const searchTerm = ref('');
        const dateFrom = ref('');
        const dateTo = ref('');
        const currentPage = ref(1);
        const perPage = ref(20);
        const sortField = ref('date_posted');
        const sortType = ref('asc');
        const warehouse = ref(0);

        const paginationOptions = ref({
            enabled: true,
            mode: 'pages',
            perPageDropdown: [5, 10, 20],
            perPage: perPage.value,
            totalRecords: totalRows.value,
            currentPage: currentPage.value

        });

        const searchOptions = {
            enabled: true
        };

        // Function to fetch data from the server
        const fetchData = async (page) => {
            try {
                const params = {
                    page: page,
                    per_page: perPage.value,
                    sort_field: sortField.value,
                    sort_type: sortType.value,
                    search: searchTerm.value,
                    date_from: dateFrom.value,
                    date_to: dateTo.value,
                    warehouse: warehouse.value
                };
                const { data } = await apiClient.get('/inventory_ledger', { params });
                rows.value = data.data || [];
                totalRows.value = data.total || 0;

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const onPageChange = ({ currentPage }) => {
            paginationOptions.currentPage = currentPage;
            fetchData(currentPage);
        };

        const onPerPageChange = ({ currentPerPage }) => {
            console.log('Per page changed to:', currentPerPage);
            perPage.value = currentPerPage;
            paginationOptions.value.perPage = currentPerPage;
            fetchData();
        };

        const onSortChange = ([sort]) => {
            console.log('Sort changed to:', sort);
            sortField.value = sort.field;
            sortType.value = sort.type;
            fetchData();
        };
        // Watch for changes to trigger fetchData
        watch([currentPage, perPage, sortField, sortType, searchTerm, dateFrom, dateTo, warehouse], fetchData, { immediate: true });
        return {
            columns,
            rows,
            totalRows,
            paginationOptions,
            searchTerm,
            onPageChange,
            onPerPageChange,
            onSortChange,
            isLoading: false,
            dateFrom,
            dateTo,
            warehouse
        };
    },
    methods: {
        async WarehouseList() {
            if (this.first_load == 0) { let loader = this.$loading.show(); }
            try {
                const response = await apiClient.get('/warehouse/list/all');
                if (response.data.status == true) {
                    if (this.first_load == 0) { loader.hide(); }
                    this.ware_house_list = response.data.data;
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);
                if (this.first_load == 0) { loader.hide(); }
            }
        }, async generate_report() {

         

            let fromDate = this.dateFrom;
            let toDate = this.dateTo;
            let searchTerm = this.searchTerm;
            let warehouse_id = this.$refs.ware_house_id.value;

            if(warehouse_id==0){
                this.$refs.notificationRef.show("Please select Warehouse", 'error', '0');
                return false;
            }

            if(fromDate=="" || toDate=="" ){
                this.$refs.notificationRef.show("Please select Date Range", 'error', '0');
                return false;
            }

            const params = {
                search: searchTerm,
                date_from: fromDate,
                date_to: toDate,
                warehouse_id:warehouse_id
            };



            this.export_loading = true; // Set loading state to true
            try {
                const response = await apiClient.get(`/report/ledger`, {
                    params,
                    responseType: 'blob', // Important for binary data
                });

                // Create a blob from the response data
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Ledger.xlsx'); // Set the desired file name
                document.body.appendChild(link);
                link.click();
                // Clean up the link
                link.parentNode.removeChild(link);
                this.export_loading = false;
            } catch (error) {
                console.error('Error downloading item report:', error);
            }

        },
    },
    mounted() {
        this.WarehouseList();
    },
    data() {
        return {
            form: {},
            ware_house_list: [],
            export_loading: false,

        };
    },


}
</script>


<style></style>