<template>
    <div v-if="visible" class="notification-container">
        <div class="notification" :class="notificationClass">{{ message }}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            message: '',
            notificationClass: '',
            redirectUrl: '',
        };
    },
    methods: {
        show(message, type, url = '') {
            this.message = message;
            this.notificationClass = type === 'success' ? 'success' : 'error';
            this.redirectUrl = url;
            this.visible = true;

            // Redirect after 3 seconds
            setTimeout(() => {
              
                if (this.redirectUrl) {
                    if(this.redirectUrl=="1"){
                        window.location.reload();
                    }else if(this.redirectUrl=="0"){
                        //nothing here
                        this.visible = false;
                    }else{
                        window.location.href = this.redirectUrl; // Perform redirection
                    }
                }
            }, 1500); // Adjust duration as needed
        },
    },
};
</script>

<style>
.notification-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
    display: flex;
    justify-content: flex-end;
}

.notification {
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease;
}

.success {
    background-color: rgba(76, 175, 80, 0.8);
    /* Green */
    color: #fff;
}

.error {
    background-color: rgba(244, 67, 54, 0.8);
    /* Red */
    color: #fff;
}
</style>