<template>
    <AccessControl :menuId="5" :menuSectionId="12" nameOfPage='Inventory Count List' @accessGranted="handleAccess">
    </AccessControl>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">
                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                           Inventory Count Edit
                        </h1>
                    </div>
                    <div class="flex items-center gap-2.5">
                        <a class="btn btn-sm btn-success" @click="backToList"> Back Inventory Count List </a>
                    </div>
                </div>
                <Notification ref="notificationRef" />
                <div class="card">
                    <div class="card-header card-header-bg flex-wrap gap-2"></div>
                    <div class="card-body">
                        <form @submit.prevent="updateForm" style="width: 100%" ref="form">

                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-1">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2" for="">
                                            Inventory Date
                                        </label>
                                        <input required v-model="currentItem.inventory_date" :disabled="is_posted === 1"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="datetime-local" placeholder="" />
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-2">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                          Comments
                                        </label>
                                        <textarea v-model="currentItem.comments"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder=""></textarea>
                                    </div>
                                </div>
                            </div>


                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-2">
                                    <div class="mb-4">
                                        <label class="block text-gray-700 text-left text-sm font-bold mb-2">
                                          Notes
                                        </label>
                                        <textarea v-model="currentItem.notes" :disabled="is_posted === 1"
                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text" placeholder=""></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="grid grid-cols-3 gap-4">
                                <div class="col-span-4">
                                    <div class="card">
                                        <div class="card-header bg-black flex-wrap gap-2">
                                            <div class="flex flex-wrap gap-2 lg:gap-5">
                                                <div class="flex">
                                                    <label class="text-white"> Product Item(s) </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card-body">
                                            <div class="grid grid-cols-4 gap-4"  v-if="this.is_posted==0">                                                 
                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        Product Code / Name
                                                    </label>
                                                    <SearchableSelect v-model="selectedOption" :options="options"
                                                        :labelKey="'product_info'" placeholder="Search and select"
                                                        ref="select_product" @option-selected="handleOption"
                                                        @enter-selected="trigger_add" />
                                                </div>


                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        Quantity
                                                    </label>
                                                    <div class="block text-gray-700 text-left text-sm font-bold mb-2">
                                                        <input ref="pquantity"
                                                            class="shadow appearance-none border border-gray-500 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                            type="number" placeholder="" min="1" value="1" />
                                                    </div>
                                                </div>

                                                <div class="col-span-1">
                                                    <label class="block text-gray-700 text-left text-sm font-bold mb-2"
                                                        for="">
                                                        &nbsp; </label>
                                                    <div class="block text-gray-700 text-left text-sm font-bold mb-2">
                                                        <button type="button" @click="addRow"
                                                            ref="addRow"
                                                            class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mr-1">
                                                            Add Product Item
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="grid grid-cols-5 gap-4">
                                                <div class="col-span-5">
                                                    <table style="font-size:13px"
                                                        class="min-w-full border-collapse border border-gray-300">
                                                        <thead>
                                                            <tr class="bg-gray-100">
                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Code
                                                                </th>
                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Name
                                                                </th>                                                           
                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Quantity
                                                                </th>
                                                                <th class="border border-gray-300 px-4 py-2 text-left">
                                                                    Action
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(item, index) in items" :key="index"
                                                                class="odd:bg-white even:bg-gray-50">
                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.code }}
                                                                </td>
                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.name }}
                                                                </td>
                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    {{ item.quantity }}
                                                                </td>                                                           

                                                                <td class="border border-gray-300 px-4 py-2">
                                                                    <i @click="openModal(item)"  v-if="this.is_posted==0"
                                                                        class="text-blue-500 px-2 py-1 rounded hover:bg-black-600  ki-filled ki-notepad-edit fs-2tx cursor-pointer">                                                                        
                                                                    </i>                                                                    
                                                                    <i @click="removeRow(index)"  v-if="this.is_posted==0"
                                                                        class="text-red-500 px-2 py-1 rounded hover:bg-black-600 ki-filled ki-trash fs-2tx">
                                                                    </i>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="flex justify-end mt-4">
                                <button v-if="canEdit" type="submit"
                                    class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600  mr-1">
                                    Update
                                </button>

                                <button v-if="canEdit  && this.is_posted==0" type="button" @click="handlePosted()"
                                    class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">
                                    Posted
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <EditModal v-if="isModalVisible" :isVisible="isModalVisible"
            :item="currentItem2" @save="updateItem" @cancel="closeModal" />
            <!-- end: container -->
            <!-- end: container -->
        </template>
    </MainLayout>
</template>
<script>
import MainLayout from "@/components/MainLayout.vue";
import apiClient from "@/services/apigen"; // Import your configured Axios instance
import { ref, watch, onMounted } from "vue";
import { VueGoodTable } from "vue-good-table-next";
import Modal from "@/components/utils/Modal2.vue";
import "vue-good-table-next/dist/vue-good-table-next.css";
import Loader from "@/components/utils/loader.vue";
import Swal from "sweetalert2";
import Notification from "@/components/utils/Notification.vue";
import SearchableSelect from "@/components/utils/Search.vue";
import EditModal from '@/components/utils/Items_quantityModal.vue'; // Adjust the path as necessary
import AccessControl from '@/components/utils/UserAccess.vue';

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

export default {
    name: "supplierListPage",
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
        SearchableSelect,
        EditModal,
        AccessControl
    },
    mounted() {
        let item_received_id = this.$route.params.id; // Accessing route params via 'this'      
        this.showInfo(item_received_id);
        this.fetchSuggestions2();

    },
    created() {
        // Initialize the debounced function
        this.fetchSuggestionsDebounced = debounce(this.fetchSuggestions, 1000);
        this.fetchSuggestionsDebounced2 = debounce(this.fetchSuggestions2, 1000);
        this.fetchSuggestionsDebounced3 = debounce(this.fetchSuggestions3, 1000);
    },
    methods: {

        openModal(item) {    
            console.log(this.currentItem);      
            this.currentItem2 = item; // Set the current item to edit
            this.isModalVisible = true; // Show the modal
        },
        closeModal() {
            this.isModalVisible = false; // Hide the modal
            this.currentItem2 = null; // Reset the current item
        },
        updateItem(updatedItem) {
            // Update the item in the items array
            const index = this.items.findIndex(item => item.code === updatedItem.code);
            if (index !== -1) {              
                  
                this.items.splice(index, 1, updatedItem); // Replace the item
            }
            this.closeModal(); // Close the modal
            return false;
        },

        handleOption(option) {
            this.selectedOption = option; // Update selected option from event*/
        },

        trigger_add(option) {
            setTimeout(() => {
                this.$refs.addRow.click();
            }, 500);
        },


        addRow() {
            this.$refs.select_product.clearInput();
            if (Object.keys(this.selectedOption).length == 0) {
                this.$refs.notificationRef.show("Please select a product", 'error', '0');
                return false;
            }
            if (parseInt(Object.keys(this.items).length) != 0) {
                const index = this.items.findIndex(item => item.product_id === this.selectedOption.product_id);
                if (index !== -1) {
                    // Get the quantity from the reference and parse it
                    let quantityToAdd = parseInt(this.$refs.pquantity.value) || 0; // default to 0 if not a valid number                

                    // Update the existing item's quantity by adding the new quantity
                    this.items[index].quantity = parseInt(this.items[index].quantity) + parseInt(quantityToAdd);                 
                    this.$refs.notificationRef.show("Product Added", 'success', '0');                 
                    return false;
                }
            }

            let code = this.selectedOption.product_code;
            let name = this.selectedOption.product_name;          
            let quantity = this.$refs.pquantity.value;
          
            let product_id = this.selectedOption.product_id;           
            this.items.push({ product_id: product_id, code: code, name: name,  quantity: quantity });
           
        },


        removeRow(index) {
            // Remove the row at the specified index
            this.items.splice(index, 1);
        },
        handleKeyup3() {
            this.fetchSuggestionsDebounced3(); // Call the debounced function
        },
        handleKeyup2() {
            this.fetchSuggestionsDebounced2(); // Call the debounced function
        },
        handleKeyup() {
            this.fetchSuggestionsDebounced(); // Call the debounced function
        },
        clear_products_bottom() {
            this.productSearch2 = {};
            this.searchQuery2 = "";
            this.items = [];
        },

        async showInfo(purchase_order_id) {

            let id = purchase_order_id;
            const response = await apiClient.get(`/inventory_count/${id}`);
            this.currentItem = response.data.data; // Store the fetched data
            this.is_posted   = response.data.data.is_posted;
            this.searchQuery = response.data.data.supplier_name;
            this.currentItem.supplier_id = response.data.data.supplier_id;
            this.searchQuery3 = response.data.data.purchase_order_number;
            this.currentItem.purchase_order_id = response.data.data.purchase_order_id;

            const response2 = await apiClient.get(`/inventory_count_product/${id}`);
            //this.items.push({ supplier_product_id: supplier_product_id, code: code, name: name, cost: cost, quantity: quantity, total: total });
            this.items = response2.data.data; // Store the fetched data

        },
        async fetchSuggestions3() {
           
        },
        async fetchSuggestions() {           
        },

        async fetchSuggestions2() {

           
            try {
                const response = await apiClient.post(`/product/name_code/keyword2`, {
                    data: { keyword: this.searchQuery2 },
                });

                if (response.data.status == true) {
                    // this.suggestions2 = response.data.data;
                    this.options = response.data.data;
                }
            } catch (error) {
                console.error("Error submitting form:", error.response);
            }
              
        },
        selectSuggestion(suggestion) {
            this.searchQuery = suggestion.supplier_name; // Populate the input with the selected suggestion
            this.productSearch = suggestion;
            this.currentItem.supplier_id = suggestion.supplier_id;
            this.suggestions = []; // Clear suggestions list
        },

        selectSuggestion2(suggestion) {
            this.searchQuery2 = suggestion.product_name; // Populate the input with the selected suggestion
            this.productSearch2 = suggestion;
            //this.form.supplier_id = suggestion.supplier_id;
            this.suggestions2 = []; // Clear suggestions list
        },

        selectSuggestion3(suggestion) {
            this.searchQuery3 = suggestion.purchase_order_number; // Populate the input with the selected suggestion
            this.productSearch3 = suggestion;
            this.currentItem.purchase_order_id = suggestion.purchase_order_id;
            this.suggestions3 = []; // Clear suggestions list
        },

        backToList() {
            window.location = `/inventory/inventory_count_list`;
        },
      
        handlePosted() {

            const form = this.$refs.form;
            if (form.checkValidity()) {

                let data = {};
                data.items = this.items;

                // Check if items array is empty
                if (data.items.length == 0) {
                    // Trigger the notification if no items are selected
                    this.$refs.notificationRef.show("Please Select Product", 'error', '0');
                    return false;  // Prevent submission
                }

                // Show SweetAlert confirmation dialog
                Swal.fire({
                    title: 'Are you sure do you want to Post it?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Post it!',
                    cancelButtonText: 'No, cancel!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Proceed with form submission after confirmation
                        this.currentItem.is_posted = 1;
                        this.updateForm();  // Call your form submission logic here
                    } else {
                        // If the user cancels, stop everything
                        return false;
                    }
                });

            } else {
                // If the form is invalid, trigger the native validation popups
                form.reportValidity();
                return false;  // Prevent submission if form is invalid
            }

        },

        async updateForm() {
            let data = {};
            data.items = this.items;     
            this.currentItem = { ...this.currentItem, ...data };          

            if (data.items.length == 0) {
                this.$refs.notificationRef.show("Please Select Product", 'error', '0');
                return false;
            }

            try {
                const response = await apiClient.put('/inventory_count/update', this.currentItem);
                if (response.data.status == true) {
                    this.$refs.notificationRef.show('Successfully Updated', 'success', '/inventory/inventory_count_list');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error updatting form:', error.response);
            }
        },
        handleAccess({ canEdit, canDelete, canWrite }) {
            this.canEdit = canEdit;
            this.canDelete = canDelete;
            this.canWrite = canWrite;
        },


    },
    data() {
        return {
            form: {},
            currentItem: [],
            suggestions: [],
            searchQuery: "",
            productSearch: [],
            isLoading: false, // Loading state
            indication_supplier: "",
            payment_list: [],
            suggestions2: [],
            searchQuery2: "",
            indication_code: "",
            items: [],
            productSearch2: {},
            suggestions3: [],
            searchQuery3:"",
            indication_purchase: "",
            selectedOption: [],
            options: [],
            isModalVisible:false,
            canWrite: 0,
            canEdit: 0,
            canDelete: 0,
            is_posted:0

        };
    },
};
</script>

<style>
.search-select {
    position: relative;
}

.options-list {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
}

.option-item {
    padding: 10px;
    cursor: pointer;
}

.option-item:hover {
    background-color: #f0f0f0;
}

.selected-option {
    margin-top: 10px;
}
</style>
