<template>

	<div class="flex items-center justify-center grow bg-center bg-no-repeat" style="height:999px;background:#20B2FF">

		<div class="card max-w-[380px] w-full" style="border:0px;background-color:#20B2FF">
			<div class="card-body">
				<div class="grid place-items-center"><img class="max-h-[10px] w-60" src="@/assets/longlong.png" /></div>
				<form @submit.prevent="login" class="card-body flex flex-col gap-5 p-10" style="background-color:white;border-radius: 10px;" id="sign_in_form">
					<div class="flex items-center">
						<span class="w-full">
							<p v-if="error" class="text-red-500">*{{ error }}</p>
						</span>

					</div>
					<div class="flex flex-col gap-1">
						<label class="form-label text-gray-900">
							Email
						</label>
						<input class="input" v-model="email" id="email" placeholder="email@email.com" type="text"
							required />
					</div>
					<div class="flex flex-col gap-1">
						<div class="flex items-center justify-between gap-1">
							<label class="form-label text-gray-900">
								Password
							</label>
							<a class="text-2sm link shrink-0" href="forgot_password">
								Forgot Password?
							</a>
						</div>
						<label class="input" data-toggle-password="true">
							<input name="user_password" v-model="password" id="password" placeholder="Enter Password"
								type="password" value="" required />
							<button class="btn btn-icon" data-toggle-password-trigger="true">
								<i class="ki-filled ki-eye text-gray-500 toggle-password-active:hidden">
								</i>
								<i class="ki-filled ki-eye-slash text-gray-500 hidden toggle-password-active:block">
								</i>
							</button>
						</label>
					</div>
					<label class="checkbox-group">
						<input class="checkbox checkbox-sm" name="check" type="checkbox" value="1" />
						<span class="checkbox-label">
							Remember me
						</span>
					</label>
					<button class="btn btn-success flex justify-center grow">
						Sign In
					</button>
				</form>
				
			</div>
		</div>
	</div>

</template>
<script>
import api from '@/services/api';
import Loader from '@/components/utils/loader.vue';

import { onUnmounted } from 'vue';
export default {
	components: {
		Loader
	},
	data() {
		return {
			email: '',
			password: '',
			error: '',
		};
	},
	setup() {

	},
	methods: {
		async login() {

			let loader = this.$loading.show();
			const response = await api.login({
				email: this.email,
				password: this.password,
			});
			const data = response.data


			if (data.status == true) { // or check for other success criteria
				// Handle successful login

				localStorage.removeItem('menus');
				localStorage.removeItem('section');
				localStorage.setItem('token', response.data.token);
				localStorage.setItem('email', response.data.user.email);
				localStorage.setItem('firstname', response.data.user.firstname);
				localStorage.setItem('lastname', response.data.user.lastname);
				this.$store.commit('SET_AUTH', true);
				this.$router.push({ name: 'Dashboard' }); // Redirect on success
				loader.hide();
			} else {
				// Handle unexpected responses
				this.error = 'invalid email or password';
				this.loading = false;  // Hide loader when login is done
				loader.hide();
			}

		},
	},
};
</script>