import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      isAuthenticated: !!localStorage.getItem('token'),
      token: localStorage.getItem('token') || null,
    };
  },
  mutations: {
    SET_AUTH(state, status) {
      state.isAuthenticated = status;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
  },
  actions: {
    login({ commit }, token) {
      localStorage.setItem('token', token);
      commit('SET_AUTH', true);
      commit('SET_TOKEN', token);
    },
    logout({ commit }) {
      localStorage.removeItem('token'); // Clear token from localStorage
      commit('SET_AUTH', false);
      commit('SET_TOKEN', null);
    },
  },
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    token: state => state.token,
  },
});

export default store;