<template>
    <header class="header fixed top-0 z-10 left-0 right-0 flex items-stretch shrink-0 bg-[#fefefe]"
        data-sticky="true" style="background:#20B2FF" data-sticky-class="shadow-sm dark:border-b dark:border-b-coal-100" data-sticky-name="header"
        id="header">
        <!-- begin: container -->
        <div class="container-fixed flex justify-between items-stretch lg:gap-4" id="header_container">
            <div class="flex gap-1 lg:hidden items-center -ml-1">
                <a class="shrink-0" href="/dashboard">
                    <img class="max-h-[25px] w-full" src="@/assets/longlong.png" />
                </a>
                <div class="flex items-center">
                    <button class="btn btn-icon btn-light btn-clear btn-sm" data-drawer-toggle="#sidebar">
                        <i class="ki-filled ki-menu"></i>
                    </button>
                    <button class="btn btn-icon btn-light btn-clear btn-sm" data-drawer-toggle="#megamenu_wrapper">
                        <i class="ki-filled ki-burger-menu-2"></i>
                    </button>
                </div>
            </div>
            <div class="flex items-stretch" id="megamenu_container">
                <!---<div class="flex items-stretch" data-reparent="true" data-reparent-mode="prepend|lg:prepend" data-reparent-target="body|lg:#megamenu_container">
                                <div class="hidden lg:flex lg:items-stretch" data-drawer="true" data-drawer-class="drawer drawer-start fixed z-10 top-0 bottom-0 w-full mr-5 max-w-[250px] p-5 lg:p-0 overflow-auto" data-drawer-enable="true|lg:false" id="megamenu_wrapper">
                                    <div class="menu flex-col lg:flex-row gap-5 lg:gap-7.5" data-menu="true" id="megamenu">
                                        <div class="menu-item active">
                                            <a class="menu-link text-nowrap text-sm text-gray-700 font-medium menu-item-hover:text-primary menu-item-active:text-gray-900 menu-item-active:font-semibold" href="html/demo1.html">
                                                <span class="menu-title text-nowrap"> Home </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>-->
            </div>
            <div class="flex items-center gap-2 lg:gap-3.5">
                <div class="dropdown" data-dropdown="true" data-dropdown-offset="70px, 10px"
                    data-dropdown-placement="bottom-end" data-dropdown-trigger="click|lg:click">
                    <div class="dropdown-content light:border-gray-300 w-full max-w-[460px]">
                        <div class="grow" id="notifications_tab_all">
                            <div class="flex flex-col">
                                <div class="scrollable-y-auto" data-scrollable="true"
                                    data-scrollable-dependencies="#header" data-scrollable-max-height="auto"
                                    data-scrollable-offset="200px">
                                    <div class="flex flex-col gap-5 pt-3 pb-4 divider-y divider-gray-200">
                                        <div class="flex grow gap-2.5 px-5">
                                            <div class="relative shrink-0 mt-0.5">
                                                <img alt="" class="rounded-full size-8"
                                                    src="@/assets/profile.png" />
                                                <span
                                                    class="size-1.5 badge badge-circle badge-success absolute top-7 end-0.5 ring-1 ring-light transform -translate-y-1/2"></span>
                                            </div>
                                        </div>
                                        <div class="border-b border-b-gray-200"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grow hidden" id="notifications_tab_team">
                            <div class="flex flex-col">
                                <div class="scrollable-y-auto" data-scrollable="true"
                                    data-scrollable-dependencies="#header" data-scrollable-max-height="auto"
                                    data-scrollable-offset="200px">
                                    <div class="flex flex-col gap-5 pt-3 pb-4">
                                        <div class="flex grow gap-2 px-5">
                                            <div class="relative shrink-0 mt-0.5">
                                                <img alt="" class="rounded-full size-8"
                                                    src="@/assets/profile.png" />
                                                <span
                                                    class="size-1.5 badge badge-circle bg-gray-400 absolute top-7 end-0.5 ring-1 ring-light transform -translate-y-1/2"></span>
                                            </div>
                                            <div class="flex flex-col gap-3 grow" id="notification_request_10"></div>
                                        </div>
                                        <div class="border-b border-b-gray-200"></div>
                                        <div class="border-b border-b-gray-200"></div>
                                        <div class="flex grow gap-2.5 px-5">
                                            <div class="flex flex-col gap-3.5 grow"></div>
                                        </div>
                                        <div class="border-b border-b-gray-200"></div>
                                        <div class="flex grow gap-2.5 px-5">
                                            <div class="flex flex-col gap-3.5"></div>
                                        </div>
                                        <div class="border-b border-b-gray-200"></div>
                                        <div class="flex grow gap-2.5 px-5" id="notification_request_3"></div>
                                    </div>
                                </div>
                                <div class="border-b border-b-gray-200"></div>
                                <div class="grid grid-cols-2 p-5 gap-2.5" id="notifications_team_footer">
                                    <button class="btn btn-sm btn-light justify-center"> Archive all </button>
                                    <button class="btn btn-sm btn-light justify-center"> Mark all as read </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="menu" data-menu="true">
                    <div class="menu-item" data-menu-item-offset="20px, 10px" data-menu-item-placement="bottom-end"
                        data-menu-item-toggle="dropdown" data-menu-item-trigger="click|lg:click">
                        <div class="menu-toggle btn btn-icon rounded-full">
                            <img alt="" class="size-9 rounded-full border-2 border-success shrink-0"
                                src="@/assets/profile.png"></img>
                        </div>
                        <div class="menu-dropdown menu-default light:border-gray-300 w-full max-w-[250px]">
                            <div class="flex items-center justify-between px-5 py-1.5 gap-1.5">
                                <div class="flex items-center gap-2">
                                    <img alt="" class="size-9 rounded-full border-2 border-success"
                                        src="@/assets/profile.png">
                                    <div class="flex flex-col gap-1.5">
                                        <span class="text-sm text-gray-800 font-semibold leading-none">
                                            {{ session_firstname }} </span>
                                        <span class="text-xs text-gray-600 hover:text-primary font-medium leading-none"> {{session_email}} </span>
                                    </div>
                                    </img>
                                </div>
                                
                            </div>
                            <div class="menu-separator"></div>
                            <div class="flex flex-col" data-menu-dismiss="true">
                                <div class="menu-item">
                                    <a class="menu-link" href="/account/profile"><span class="menu-title"> My Profile
                                        </span>
                                    </a>
                                </div>
                                <div class="menu-item px-4 py-1.5">
                                    <a class="btn btn-sm btn-light justify-center" @click="logout"> Log out </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end: container -->
    </header>
</template>

<script>
export default {
    name: 'HeaderPage',
    data() {
        return {
            session_firstname: null,
            session_lastname: null,
            session_email: null,
        };
    },
    methods: {
        async logout() {
            try {
                // Dispatch Vuex logout action (assumes it clears tokens/session data)
                await this.$store.dispatch('logout');
                
                // Optionally clear localStorage
                localStorage.removeItem('session_firstname');
                localStorage.removeItem('session_lastname');
                localStorage.removeItem('session_email');
                
                // Redirect to login page
                this.$router.push({ name: 'Login' });
            } catch (error) {
                console.error('Logout error:', error);
            }
        }
    },
    mounted() {
        // Access localStorage when the component is mounted
        this.session_firstname = localStorage.getItem('firstname');
        this.session_lastname = localStorage.getItem('lastname');
        this.session_email = localStorage.getItem('email');
    }
}



</script>

<style scoped>
/* Your header styles go here */
</style>