<template>
    <AccessControl :menuId="7" :menuSectionId="22" nameOfPage='Color' @accessGranted="handleAccess">
    </AccessControl>
    <MainLayout>
        <template v-slot:default>
            <!-- end: container -->
            <!-- begin: container -->
            <div class="container-fixed">

                <div class="flex flex-wrap items-center lg:items-end justify-between gap-5 pb-7.5">
                    <div class="flex flex-col justify-center gap-2">
                        <h1 class="text-xl font-semibold leading-none text-gray-900">
                            Color List
                        </h1>
                    </div>
                    <div class="flex items-center gap-2.5">
                        <a v-if="canWrite" class="btn btn-sm btn-success" @click="openModal">
                            Add Color
                        </a>
                    </div>
                </div>
                <Notification ref="notificationRef" />

                <div class="card">
                    <div class="card-header   card-header-bg flex-wrap gap-2">
                        <div class="flex flex-wrap gap-2 lg:gap-5">
                            <div class="flex"><label class="input input-sm"><i class="ki-filled ki-magnifier"></i><input
                                        value="" v-model="searchTerm" type="text" placeholder="Search table"
                                        @input="fetchData"></label>
                            </div>
                        </div>
                    </div>
                    <vue-good-table mode="remote" :server-side="true" :columns="columns" :rows="rows"
                        :total-rows="totalRows" :pagination-options="paginationOptions" :isLoading.sync="isLoading"
                        :search-options="{ enabled: false }" @page-change="onPageChange" @on-page-change="onPageChange"
                        @per-page-change="onPerPageChange" @sort-change="onSortChange">
                        <template #table-row="props">
                            <span v-if="props.column.field === 'actions'">
                                <a v-if="canEdit" @click="handleEdit(props.row)" class="btn btn-sm btn-icon btn-clear btn-light"
                                    href="#">
                                    <i class="ki-filled ki-notepad-edit">
                                    </i>
                                </a>

                                <a v-if="canDelete" @click="handleDelete(props.row)" class="btn btn-sm btn-icon btn-clear btn-light"
                                    href="#">
                                    <i class="ki-filled ki-trash">
                                    </i>
                                </a>
                            </span>
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>

                    </vue-good-table>

                    <Modal :isVisible="showModal" @update:isVisible="showModal = $event">
                        <template v-slot:header>
                            <h2>Add Color</h2>
                        </template>
                        <template v-slot:body>
                            <form @submit.prevent="submitForm">

                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <div class="w-full px-3 mb-6 md:mb-0">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                            Color Name
                                        </label>
                                        <input v-model="form.color_name"
                                            class="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                            id="grid-deparment-name" type="text" required>
                                    </div>
                                </div>
                                <div class="flex justify-end mt-4">
                                    <button type="button" @click="closeModal"
                                        class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mr-1">Close</button>
                                    <button type="submit"
                                        class="btn-success text-white px-4 py-2 rounded hover:bg-green-600">Save</button>
                                </div>
                            </form>
                        </template>
                    </Modal>


                    <Modal :isVisible="showModal2" @update:isVisible="showModal2 = $event" @click.stop>
                        <template v-slot:header>
                            <h2>Edit Color</h2>
                        </template>
                        <template v-slot:body>
                            <form @submit.prevent="updateForm">

                                <div class="flex flex-wrap -mx-3 mb-6">
                                    <div class="w-full px-3 mb-6 md:mb-0">
                                        <label
                                            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                            Color Name
                                        </label>

                                        <input type="hidden" v-model="currentItem.color_id"
                                            class="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                            id="grid-deparment-name" required>
                                        <input v-model="currentItem.color_name"
                                            class="appearance-none block w-full text-gray-700 border border-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                            id="grid-deparment-name" type="text" required>
                                    </div>
                                </div>
                                <div class="flex justify-end mt-4">
                                    <button type="button" @click="closeModal2"
                                        class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mr-1">Close</button>
                                    <button type="submit"
                                        class="btn-success text-white px-4 py-2 rounded hover:bg-green-600">Update</button>
                                </div>
                            </form>
                        </template>
                    </Modal>



                </div>
            </div>
            <!-- end: container -->
        </template>
    </MainLayout>
</template>
<script>
import MainLayout from '@/components/MainLayout.vue'
import apiClient from '@/services/apigen'; // Import your configured Axios instance  
import { ref, watch, onMounted } from 'vue';
import { VueGoodTable } from 'vue-good-table-next';
import Modal from '@/components/utils/Modal.vue'
import 'vue-good-table-next/dist/vue-good-table-next.css';
import Loader from '@/components/utils/loader.vue';
import Swal from 'sweetalert2';
import Notification from '@/components/utils/Notification.vue';
import AccessControl from '@/components/utils/UserAccess.vue';

export default {
    name: 'colorPage',
    components: {
        MainLayout,
        VueGoodTable,
        Modal,
        Notification,
        AccessControl
    },

    setup() {
        const columns = ref([
            { label: '#', field: 'color_id', type: 'number', sortable: true },
            { label: 'Color', field: 'color_name', sortable: true },
            {
                sortable: false,
                label: 'Actions',
                field: 'actions',
                type: 'actions',
                actions: [
                    {
                        label: 'Edit',
                        icon: 'edit',
                        onClick: ""
                    },
                    {
                        label: 'Delete',
                        icon: 'delete',
                        onClick: ""
                    }
                ]
            }
        ]);

        const rows = ref([]);
        const totalRows = ref(0);
        const searchTerm = ref('');
        const currentPage = ref(1);
        const perPage = ref(20);
        const sortField = ref('color_name');
        const sortType = ref('asc');

        const paginationOptions = ref({
            enabled: true,
            mode: 'pages',
            perPageDropdown: [5, 10, 20],
            perPage: perPage.value,
            totalRecords: totalRows.value,
            currentPage: currentPage.value

        });

        const searchOptions = {
            enabled: true
        };

        // Function to fetch data from the server
        const fetchData = async (page) => {
            try {
                const params = {
                    page: page,
                    per_page: perPage.value,
                    sort_field: sortField.value,
                    sort_type: sortType.value,
                    search: searchTerm.value,
                };
                const { data } = await apiClient.get('/color', { params });
                rows.value = data.data || [];
                totalRows.value = data.total || 0;

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const onPageChange = ({ currentPage }) => {
            paginationOptions.currentPage = currentPage;
            fetchData(currentPage);
        };

        const onPerPageChange = ({ currentPerPage }) => {
            console.log('Per page changed to:', currentPerPage);
            perPage.value = currentPerPage;
            paginationOptions.value.perPage = currentPerPage;
            fetchData();
        };

        const onSortChange = ([sort]) => {
            console.log('Sort changed to:', sort);
            sortField.value = sort.field;
            sortType.value = sort.type;
            fetchData();
        };
        // Watch for changes to trigger fetchData
        watch([currentPage, perPage, sortField, sortType, searchTerm], fetchData, { immediate: true });
        return {
            columns,
            rows,
            totalRows,
            paginationOptions,
            searchTerm,
            onPageChange,
            onPerPageChange,
            onSortChange,
            isLoading: false
        };
    },
    methods: {
        handleEdit(data) {
            this.showInfo(data.color_id);
        },
        handleDelete(data) {

            let color_id = data.color_id;

            Swal.fire({
                title: 'Are you sure do you want to delete?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteData(color_id);
                }
            });

        },
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        closeModal2() {
            this.showModal2 = false;
        },
        async showInfo(color_id) {
            let loader = this.$loading.show();
            let id = color_id;
            const response = await apiClient.get(`/color/${id}`);
            console.log(response.data.data)
            this.currentItem = response.data.data; // Store the fetched data
            this.showModal2 = true; // Show the modal
            loader.hide();

            // Optionally, you might want to update the state or show the result in the UI


        },
        async deleteData(id) {

            try {
                const response = await apiClient.delete('/color/delete', { data: { id: id } });
                if (response.data.status == true) { // or check for other success c
                    this.$refs.notificationRef.show('Successfully Deleted', 'success', '1');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        },
        async updateForm() {

            try {
                const response = await apiClient.put('/color/update', this.currentItem);
                if (response.data.status == true) { // or check for other success 
                    this.showModal2 = false;
                    this.$refs.notificationRef.show('Successfully Updated', 'success', '1');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error updatting form:', error.response);

            }
        },
        async submitForm() {

            try {
                const response = await apiClient.post('/color/save', this.form);
                if (response.data.status == true) {
                    this.showModal = false;
                    this.$refs.notificationRef.show('Successfully Saved', 'success', '1');
                } else {
                    this.$refs.notificationRef.show(response.data.message, 'error', '0');
                }
            } catch (error) {
                console.error('Error submitting form:', error.response);

            }
        }, handleAccess({ canEdit, canDelete, canWrite }) {
            this.canEdit = canEdit;
            this.canDelete = canDelete;
            this.canWrite = canWrite;
        },

    },
    data() {
        return {
            showModal: false,
            form: {
                color_name: ''
            },
            showModal2: false,
            currentItem: '',
            canWrite: 0,
            canEdit: 0,
            canDelete: 0,
        };
    },


}
</script>


<style></style>