import axios from 'axios';
// Create an Axios instance
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // Use your environment variable or a fixed base URL
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 120000, // 10 seconds
});

// Add a request interceptor to include the Bearer token
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token'); // Retrieve the token from localStorage
  if (token) {
    config.headers.Authorization = `Bearer ${token}`; // Add the Bearer token to the headers
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


// Interceptor to handle the response
apiClient.interceptors.response.use(
  response => response, // Pass through any successful response
  error => {
      if (error.response && error.response.status === 401) {
          // Handle the "Unauthenticated" message
          if (error.response.data.message === 'Unauthenticated.') {
              // Optionally, clear the stored token
              localStorage.removeItem('token');

              // Redirect to the login page
              router.push({ name: 'login' });

              // Optionally, show a notification or alert
              alert('Session expired. Please log in again.');
          }
      }
      return Promise.reject(error);
  }
);



export default apiClient;