import axios from 'axios';

const apiClient = axios.create({
    baseURL:process.env.VUE_APP_API_BASE_URL, // Dynamic base URL
  
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 120000, // 10 seconds
});

// Add a request interceptor to include the Bearer token
apiClient.interceptors.request.use(config => {
  const token = localStorage.getItem('token'); // Get the token from storage

  if (token) {
    config.headers.Authorization = `Bearer ${token}`; // Attach the token to the Authorization header
  }

  return config;
}, error => {
  return Promise.reject(error);
});

export default {
  login(credentials) {
    return apiClient.post('/login', credentials);
  },

  /*login(credentials) {
    return apiClient.post('/login', credentials)
      .then(response => {
        console.log('Login response:', response.data);
        return response.data; // You might want to handle token storage here
      })
      .catch(error => {
        console.error('Login error:', error.response ? error.response.data : error);
        throw error; // Re-throw if you want to handle it elsewhere
      });
  },*/


  forgotpassword(credentials) {
    return apiClient.post('/forgotpassword', credentials);
  },
  updatepassword_forgot(credentials) {
    return apiClient.post('/updatepassword_forgot', credentials);
  }
  // Other API methods
};